import { combineReducers, Reducer, AnyAction } from 'redux';

// ALL INTERFACES
import { ISignupStoreState } from '../containers/signup/interface';
import { ILoginStoreState } from '../containers/login/interface';
import { IUserStoreState } from '../containers/user/interface';
import { IStaffStoreState } from '../containers/staff/interface';
import { IStudioServiceStoreState } from '../containers/studioServices/interface';

// ALL REDUCERS
import loginReducer from '../containers/login/login.reducers';
import signupReducer from '../containers/signup/signup.reducers';
import userReducer from '../containers/user/user.reducers';
import staffReducer from '../containers/staff/staff.reducers';
import studioServiceReducer from '../containers/studioServices/studioservices.reducers';

export interface IAppState {
	readonly loginState: ILoginStoreState;
	readonly signupState: ISignupStoreState;
	readonly userState: IUserStoreState;
	readonly staffState: IStaffStoreState;
	readonly studioServiceState: IStudioServiceStoreState;
}

const rootReducer: Reducer<any, AnyAction> = combineReducers<IAppState>({
	signupState: signupReducer,
	loginState: loginReducer,
	userState: userReducer,
	staffState: staffReducer,
	studioServiceState: studioServiceReducer,
});

export default rootReducer;
