import {
	isProd,
	AcceptedImageFormat,
	AcceptedFileForStripe,
} from '../configs/AppConfigs';
import Swal from 'sweetalert2';
import { ApiHelper } from './ApiHelper';
import {
	IBranchSelect,
	// IStaffSelect,
	// IServiceSelect,
	ISubServiceSelect,
} from '../../containers/StudioStaff/interface';
import { TIME_FORMAT, IReactSelect, TimeZoneSchema } from './constants';
import csc from 'country-state-city';
import moment from 'moment-timezone';
import { showErrorToast, showSuccessToast } from '../../settings/helpers/toast';
/**
 * logs the text only if the process mode is development
 */
export function logger(...args: any[]) {
	if (!isProd) {
		for (let index = 0; index < args.length; index++) {
			const data = args[index];
			console.log('LOG: -------------------------');
			console.log('LOG: ', data);
			console.log('LOG: -------------------------');
		}
	}
}

/**
 * Create unique 6 digit number
 * for RLDD unique number id
 */
export function uniqueNumberKey(): number {
	const id: number = Math.floor(100000 + Math.random() * 900000);
	return id;
}

/**
 * Create slugify string
 */
export function slugify(text: string) {
	const a =
		'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
	const b =
		'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p = new RegExp(a.split('').join('|'), 'g');

	return text
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
		.replace(/&/g, '-and-') // Replace & with 'and'
		.replace(/[^\w-]+/g, '') // Remove all non-word characters (/[^\w\-]+/g, '')
		.replace(/--+/g, '-') // Replace multiple - with single - (/\-\-+/g, '-')
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text
}

/**
 * Async Set State
 */
export const asyncSetState = (instance: any) => (newState: any) =>
	new Promise(resolve => instance.setState(newState, resolve));

/**
 * Function to generate time array
 * In 24 hour format
 * with 30 min interval
 * using moment
 */
export function timeIntervals(): any[] {
	let items: any[] = [];
	new Array(24).fill(undefined).forEach((acc: any, index: number) => {
		items = [
			...items,
			{
				label: moment({ hour: index }).format('h:mm A'),
				value: moment({ hour: index }).format('h:mm A'),
			},
		];
		items = [
			...items,
			{
				label: moment({ hour: index, minute: 30 }).format('h:mm A'),
				value: moment({ hour: index, minute: 30 }).format('h:mm A'),
			},
		];
	});

	// start time from 08:00 am
	const newArr: any[] = [...items.slice(0, 16)];
	items.splice(0, 16);
	const newItems: any[] = [
		{ label: '24 Hours', value: '24 Hours' },
		...items,
		...newArr,
	];

	return newItems;
}

/**
 * FUNCTION TO CREATE SWEET ALERT
 * yard add sweetalert2
 * USAGE: const { value } = await confirmBox({
			title: 'Are you sure?',
			text: text,
		});
		if (value) {} else {}
 */
export async function confirmBox(obj: any) {
	if (!obj) {
		obj = {};
	}
	let {
		title,
		text,
		type,
		confirmButtonColor,
		cancelButtonColor,
		confirmButtonText,
		input,
		inputOptions,
		inputValidator,
		cancelButtonText,
	} = obj;
	return await Swal.fire({
		title: title || 'Are you sure?',
		text: text || "You won't be able to revert this!",
		type: type || 'warning',
		input: input || '',
		inputOptions: inputOptions || {},
		inputValidator: inputValidator || {},
		showCancelButton: true,
		confirmButtonColor: confirmButtonColor || '#3085d6',
		cancelButtonColor: cancelButtonColor || '#d33',
		confirmButtonText: confirmButtonText || 'Yes!',
		cancelButtonText: cancelButtonText || 'Cancel',
	});
}

export const getBranchesForSelect = async (
	searchStr: string = ''
): Promise<IBranchSelect[]> => {
	try {
		const result: any = await new ApiHelper().FetchFromServer(
			'/studiomanagers',
			'/branches/getBranchesForSelect?search=' + searchStr,
			'GET',
			true,
			undefined,
			undefined
		);

		if (result.isError) {
			throw new Error(result.messages);
		}

		const { data } = result;
		let branches: IBranchSelect[] = [];
		if (data.statusCode === 200 && data.success) {
			branches = [...data.data];
		}
		return branches;
	} catch (error) {
		logger(error);
		return [];
	}
};

export const getStaffForSelect = async (): Promise<IReactSelect[]> => {
	try {
		const result: any = await new ApiHelper().FetchFromServer(
			'/studiomanagers',
			'/staffs/getStaffsForSelect',
			'GET',
			true,
			undefined,
			undefined
		);

		if (result.isError) {
			throw new Error(result.messages);
		}

		const { data } = result;
		let staffs: IReactSelect[] = [];
		if (data.statusCode === 200 && data.success) {
			let temp = [...data.data];
			temp.forEach((v: any) => {
				staffs.push({ value: v._id, label: v.name });
			});
		}
		return staffs;
	} catch (error) {
		logger(error);
		return [];
	}
};

export const getBusinessTypeForSelect = async (): Promise<IReactSelect[]> => {
	try {
		const result: any = await new ApiHelper().FetchFromServer(
			'/studiomanagers',
			'/businesstypes/getBusinessTypeForSelect',
			'GET',
			false,
			undefined,
			undefined
		);

		if (result.isError) {
			throw new Error(result.messages);
		}

		const { data } = result;
		let businessType: IReactSelect[] = [];
		if (data.statusCode === 200 && data.success) {
			let temp = [...data.data];
			temp.forEach((v: any) => {
				businessType.push({
					value: v._id,
					label: v.name,
					category: v.category,
				});
			});
		}
		return businessType;
	} catch (error) {
		logger(error);
		return [];
	}
};

export const getBusinessTypeForSelectNoRetreats = async (): Promise<IReactSelect[]> => {
	try {
		const result: any = await new ApiHelper().FetchFromServer(
			'/studiomanagers',
			'/businesstypes/getBusinessTypeForSelectNoRetreats',
			'GET',
			false,
			undefined,
			undefined
		);

		if (result.isError) {
			throw new Error(result.messages);
		}

		const { data } = result;
		const businessTypes:IReactSelect[] = data.data.map((v:any) => ({
			value: v._id,
			label: v.name,
			category: v.category,
		}));
		businessTypes.length > 0 ? logger('Categories retrieved succesfully'):logger('Error retreving categories')
		return businessTypes;
		
	} catch (error) {
		logger(error);
		return [];
	}
};


export const getServiceForSelect = async (): Promise<IReactSelect[]> => {
	try {
		const result: any = await new ApiHelper().FetchFromServer(
			'/studiomanagers',
			'/services/getServicesForSelect',
			'GET',
			true,
			undefined,
			undefined
		);

		if (result.isError) {
			throw new Error(result.messages);
		}

		const { data } = result;
		let services: any[] = [];
		if (data.statusCode === 200 && data.success) {
			let temp = [...data.data];
			temp.forEach((v: any) => {
				let serviceName = v.name;
				if (v.type) {
					serviceName = v.name + ' (' + v.type + ')';
				}
				services.push({
					value: v._id,
					label: serviceName,
					duration: v.duration,
					type: v.type,
					staffId: v.staffId,
				});
			});
		}
		return services;
	} catch (error) {
		logger(error);
		return [];
	}
};

export const getClassForSelect = async (): Promise<IReactSelect[]> => {
	try {
		const result: any = await new ApiHelper().FetchFromServer(
			'/studiomanagers',
			'/classes/getClassesForSelect',
			'GET',
			true,
			undefined,
			undefined
		);

		if (result.isError) {
			throw new Error(result.messages);
		}

		const { data } = result;
		let classes: any[] = [];
		if (data.statusCode === 200 && data.success) {
			let temp = [...data.data];
			temp.forEach((v: any) => {
				let className = v.name;
				if (v.type) {
					className = v.name + ' (' + v.type + ')';
				}
				classes.push({
					value: v._id,
					label: className,
					duration: v.duration,
					type: v.type,
					staffId: v.staffId,
					participantsLimit: v.participantsLimit,
				});
			});
		}
		return classes;
	} catch (error) {
		logger(error);
		return [];
	}
};

export const getEventsForSelect = async () => {
	try{
		const result: any = await new ApiHelper().FetchFromServer(
				'/studiomanagers',
				'/events/getAllEvents',
				'GET',
				true,
				undefined,
				undefined
			);
		if (result.isError) {
			throw new Error(result.messages);
		}
		let events: any[] = [];
		const { data } = result;
		if (data.statusCode === 200 && data.success) {
			let temp = [...data.data];
			temp.forEach((v: any) => {
				let name = v.name;
				events.push({
					value: v._id,
					label: name,
					startDate: v.startDate,
					endDate: v.endDate,
					startTime: v.startTime,
					endTime: v.endTime,
					buyingSlot: v.buyingSlot,
					staffId: v.staffId || undefined,
				})
			})
		}
		return events;
	} catch(error){
		logger(error);
		return [];
	}
}

export const getVirtualSessionsForSelect = async (): Promise<IReactSelect[]> => {
	try {
		const result: any = await new ApiHelper().FetchFromServer(
			'/studiomanagers',
			'/virtualsessions/getVirtualSessionsForSelect',
			'GET',
			true,
			undefined,
			undefined
		);

		if (result.isError) {
			throw new Error(result.messages);
		}

		const { data } = result;
		let visualSessions: IReactSelect[] = [];
		if (data.statusCode === 200 && data.success) {
			let temp = [...data.data];
			temp.forEach((v: any) => {
				visualSessions.push({ value: v._id, label: v.name });
			});
		}
		return visualSessions;
	} catch (error) {
		logger(error);
		return [];
	}
};

export const getSubServiceForSelect = async (
	serviceId: string
): Promise<ISubServiceSelect[]> => {
	try {
		const result: any = await new ApiHelper().FetchFromServer(
			'/studiomanagers',
			`/subservices/getSubServicesForSelect?serviceId=${serviceId}`,
			'GET',
			true,
			undefined,
			undefined
		);

		if (result.isError) {
			throw new Error(result.messages);
		}

		const { data } = result;
		let subServices: ISubServiceSelect[] = [];
		if (data.statusCode === 200 && data.success) {
			subServices = [...data.data];
		}
		return subServices;
	} catch (error) {
		logger(error);
		return [];
	}
};

export const getLocationsForSelect = async (): Promise<IReactSelect[]> => {
	try {
		const result: any = await new ApiHelper().FetchFromServer(
			'/users',
			'/studios/getLocationsForSelect',
			'GET',
			false,
			undefined,
			undefined
		);

		if (result.isError) {
			throw new Error(result.messages);
		}

		const { data } = result;
		let locationsList: IReactSelect[] = [];
		if (data.statusCode === 200 && data.success) {
			locationsList = [...data.data];
			// temp.map((v: any) => {
			// 	locationsList.push({ value: v.value, label: v.lable });
			// });
		}
		return locationsList;
	} catch (error) {
		logger(error);
		return [];
	}
};

export const getEventLocationsForSelect = async (): Promise<IReactSelect[]> => {
	try {
		const result: any = await new ApiHelper().FetchFromServer(
			'/studiomanagers',
			'/events/getEventLocationsForSelect',
			'GET',
			false,
			undefined,
			undefined
		);

		if (result.isError) {
			throw new Error(result.messages);
		}

		const { data } = result;
		let locationsList: IReactSelect[] = [];
		if (data.statusCode === 200 && data.success) {
			locationsList = [...data.data];
			// temp.map((v: any) => {
			// 	locationsList.push({ value: v.value, label: v.lable });
			// });
		}
		return locationsList;
	} catch (error) {
		logger(error);
		return [];
	}
};
export const getImageBlob = async (name: string): Promise<File | null> => {
	try {
		const fileRes: any = await new ApiHelper().FetchFromServer(
			'/commons',
			'/files/downloadFile',
			'POST',
			false,
			undefined,
			{ name },
			undefined,
			'blob'
		);

		if (fileRes.isError) {
			throw new Error(fileRes.messages);
		}

		const { data } = fileRes;
		const file: File = new File([data], name, {
			type: data.type,
		});
		return file;
	} catch (error) {
		logger(error);
		return null;
	}
};

/**
 * FUNCTION TO STOP RE-RENDRING OF IMAGES BLOB
 * PROTECTS MEMORY LEAKS
 * USAGE: const imageUrl = image && blobImageUrl(image);
 * @param blob
 */
export const blobImageUrl = (blob: any) => {
	if (!blob.url) {
		blob.url = URL.createObjectURL(blob);
	}
	return blob.url;
};

export const blobUrl = (blob: any) => {
	let url: any = blob;
	if (blob && blob.name) {
		console.log(blob,'blobblob')
		if (!blob.url) {
			url = URL.createObjectURL(blob);
			console.log(url,'urlurlurl')
		} else {
			url = blob.url;
		}
	}
	return url;
};

// PROCESS IMAGE TO GET IT'S DIMENSIONS
const imageProcess = (
	src: string
): Promise<{ width: number; height: number }> => {
	return new Promise((resolve, reject) => {
		let img = new Image();
		img.onload = () => resolve({ width: img.width, height: img.height });
		img.onerror = reject;
		img.src = src;
	});
};

// CHECK RESOLUTION OF IMAGES
export const checkResolution = async (
	file: File,
	width: number,
	height: number
): Promise<boolean> => {
	const url: string = URL.createObjectURL(file),
		minImageWidth: number = width,
		minImageHeight: number = height;

	const imgResolution: {
		width: number;
		height: number;
	} = await imageProcess(url);

	if (
		imgResolution.width >= minImageWidth &&
		imgResolution.height >= minImageHeight
	) {
		return true;
	} else {
		return false;
	}
};

// VALIDATION FOR IMAGE BY CHECKING FILE TYPE OF SELECTED FILE
export const imageValidate = (type: string): boolean => {
	const fileType: string = type
		.substring(type.lastIndexOf('/') + 1)
		.toLowerCase();

	let indx: number = 0;

	while (indx < AcceptedImageFormat.length) {
		const accFileType: string = AcceptedImageFormat[indx]
			.substring(type.lastIndexOf('/') + 1)
			.toLowerCase();

		if (fileType === accFileType) {
			return true;
		} else {
			indx++;
		}
	}

	return false;
};

// CHECK IMAGE RESOLUTION AND RETURN IT'S PASS VALUE
export const imageValidateAndSave = (
	file: File | null,
	setFieldValue: any,
	setFieldError: any,
	name: string,
	width?: number,
	height?: number
): void => {
	if (file && imageValidate((file || {}).type)) {
		checkResolution(file, width ? width : 100, height ? height : 100)
			.then((val: boolean) => {
				if (val) {
					setFieldValue(name, file);
				} else {
					setFieldError(
						name,
						`Please select high resolution image (${width ? width : 100} x ${
							height ? height : 100
						})`
					);
				}
			})
			.catch(err => logger(err));
	} else {
		setFieldError(name, 'Only PNG, JPG, JPEG & GIF are accepted');
	}
};

// CHECK IMAGE RESOLUTION AND RETURN IT'S PASS VALUE
// export const providerImageValidateAndSave = (
// 	file: File | null,
// 	setFieldValue: any,
// 	setFieldError: any,
// 	name: string,
// 	handleSubmit: any,
// 	getSuccessMessage: any,
// 	width?: number,
// 	height?: number
// ): void => {
// 	if (file && imageValidate((file || {}).type)) {
// 		console.log('^^^^^^^^^^^^^^^^^^^66');
// 		checkResolution(file, width ? width : 1000, height ? height : 1000)
// 			.then(async (val: boolean) => {
// 				if (val) {
// 					setFieldValue(name, file);
// 					let fileData: FormData = new FormData();
// 					console.log(file, 'file');
// 					fileData.append('profileImage', file);
// 					const result: any = await new ApiHelper().FetchFromServer(
// 						'/studiomanagers',
// 						'/studios/uploadImage',
// 						'POST',
// 						true,
// 						undefined,
// 						fileData
// 					);
// 					const { data } = result;
// 					console.log(result, 'datadatadatadatadatadatadatadatadatadata');
// 					if (data.statusCode === 200 && data.success) {
// 						setFieldValue(name, data.data[0].basepath);
// 						await getSuccessMessage('Profile image updated successfully');
// 						handleSubmit();
// 					}
// 				} else {
// 					setFieldError(
// 						name,
// 						`Please select high resolution image (${width ? width : 1000} x ${
// 							height ? height : 1000
// 						})`
// 					);
// 				}
// 			})
// 			.catch(err => logger(err));
// 	} else {
// 		setFieldError(name, 'Only PNG, JPG, JPEG, BMP & GIF are accepted');
// 	}
// };

export const fileValidate = (type: string): boolean => {
	const fileType: string = type
		.substring(type.lastIndexOf('/') + 1)
		.toLowerCase();

	let indx: number = 0;

	while (indx < AcceptedFileForStripe.length) {
		const accFileType: string = AcceptedFileForStripe[indx]
			.substring(type.lastIndexOf('/') + 1)
			.toLowerCase();

		if (fileType === accFileType) {
			return true;
		} else {
			indx++;
		}
	}

	return false;
};

// CHECK IMAGE RESOLUTION AND RETURN IT'S PASS VALUE
export const stripDocumentValidateAndSave = (
	file: File | null,
	setFieldValue: any,
	setFieldError: any,
	name: string
): void => {
	if (file && fileValidate((file || {}).type)) {
		setFieldValue(name, file);
	} else {
		setFieldError(name, 'Only PNG, JPG, JPEG & PDF are accepted');
	}
};

export function bookingTimingsArray(): string[] {
	let items: string[] = [];
	new Array(24).fill(undefined).forEach((acc: any, index: number) => {
		items = [...items, moment({ hour: index }).format('h:mm A')];
		items = [...items, moment({ hour: index, minute: 30 }).format('h:mm A')];
	});

	// start time from 08:00 am
	items.splice(0, 16);
	items.pop();
	items.pop();
	items.pop(); // end time till 10:00 PM
	const newItems: string[] = [...items];

	return newItems;
}

export const getStatesOfCountry = async (country_id: string) => {
	let st = await csc.getStatesOfCountry(country_id);
	let states: IReactSelect[] = [];
	if (st.length > 0) {
		let temp = [...st];
		temp.forEach((v: any) => {
			states.push({ value: v.name, label: v.name });
		});
	}
	return states;
};

export const getTimeSeconds = (seconds: any) => {
	if (!seconds) {
		seconds = 0;
	}
	seconds = Math.trunc(seconds);
	let newDuration: any = '';
	let hours: any = Math.floor(seconds / 60 / 60);
	let minutes: any = Math.floor(seconds / 60) - hours * 60;
	let newSeconds: any = seconds % 60;

	if (hours < 10) {
		hours = '0' + hours;
	}
	if (minutes < 10) {
		minutes = '0' + minutes;
	}
	if (newSeconds < 10) {
		newSeconds = '0' + newSeconds;
	}
	if (hours > 0) {
		newDuration = hours + ':' + minutes + ':' + newSeconds;
	} else {
		newDuration = minutes + ':' + newSeconds;
	}
	return newDuration;
};

export const NotificationSettingModule = [
	{
		name: 'Appointments booked with all staff',
		value: 'booked-appoinment',
		subModule: [
			{
				name: 'New Appointments',
				value: 'new-appoinment',
			},
			{
				name: 'Reschedules',
				value: 'reschedules',
			},
			{
				name: 'Check in',
				value: 'check-in',
			},
			{
				name: 'Cancellations',
				value: 'cancellations',
			},
		],
	},
	{
		name: 'Retreat Event',
		value: 'event',
		subModule: [
			{
				name: 'New Appointments',
				value: 'new-appoinment',
			},
			{
				name: 'Cancellations',
				value: 'cancellations',
			},
		],
	},
];

export const RetreatCategory = [
	{
		label: 'Anti- Stress',
		value: 'antiStress',
	},

	{
		label: 'Athletic Training Retreats ',
		value: 'athleticTrainingRetreats',
	},
	{
		label: 'Ayuverda Retreats',
		value: 'ayuverdaRetreats',
	},
	{
		label: 'Boxing Retreats',
		value: 'boxingRetreats',
	},

	{
		label: 'Couples Retreats',
		value: 'couplesRetreats',
	},
	{
		label: 'Detox Retreats',
		value: 'detoxRetreats',
	},
	{
		label: 'Digital Retreats',
		value: 'digitalRetreats',
	},
	{
		label: 'Fitness Retreats',
		value: 'fitnessRetreats',
	},
	{
		label: 'Meditation Retreats',
		value: 'meditationRetreats',
	},
	{
		label: 'Mens Retreat',
		value: 'mensRetreat',
	},
	{
		label: 'Spa Retreat',
		value: 'spaRetreat',
	},
	{
		label: 'Solo Retreat,',
		value: 'soloRetreat',
	},
	{
		label: 'Surfing Retreats',
		value: 'surfingRetreats',
	},
	{
		label: 'Traditional Chineese Medicine Retreats',
		value: 'traditionalChineeseMedicineRetreats',
	},
	{
		label: 'Urban Retreat',
		value: 'urbanRetreat',
	},
	{
		label: 'Weekend Retreat',
		value: 'weekendRetreat',
	},
	{
		label: 'Weight Loss Retreat',
		value: 'weightLossRetreat',
	},
	{
		label: 'Women’s Retreat',
		value: 'womensRetreat',
	},
	{
		label: 'Yoga Retreat',
		value: 'yogaretreat',
	},
];

export const getHoursAndMinut = (timeString: any) => {
	let result = { hour: '0', min: '0' };
	if (timeString) {
		let hour = '0';
		let min = '0';
		if (timeString.indexOf('h') > -1 && timeString.indexOf('min') > -1) {
			let chunkHour = timeString.split('h');
			let chunkMin = '0';
			if (timeString.indexOf('min') > -1) {
				chunkMin = chunkHour[1].split('min')[0].trim();
			}
			hour = chunkHour[0];
			min = chunkMin;
		} else {
			if (timeString.indexOf('h') > -1) {
				let chunkHour = timeString.split('h');
				hour = chunkHour[0];
			} else if (timeString.indexOf('min') > -1) {
				let chunkMin = timeString.split('min');
				min = chunkMin[0];
			}
		}
		result = { hour: hour, min: min };
	}
	return result;
};

export const getTimeZone = (country: any) => {
	let timeZone: any = TimeZoneSchema.filter(
		(item: any) => item.country_code === country.value
	);
	let timeZoneArray = timeZone && timeZone.length ? timeZone[0].timezones : '';
	let tz: any = [];
	if (timeZoneArray && timeZoneArray.length) {
		timeZoneArray.forEach((timeZone: any) =>
			tz.push({ value: timeZone, label: timeZone })
		);
	}
	return tz;
};

export const convertTimeSpecificTimeZone = (t: any, timeZone: any) => {
	let time: any = '';
	if (timeZone) {
		time = moment.tz(t, timeZone).format(TIME_FORMAT);
	} else {
		time = moment(t).format(TIME_FORMAT);
	}
	return time;
};

export const convertDateSpecificTimeZone = (
	t: any,
	timeZone: any,
	dateFormat: any
) => {
	let date: any = '';
	if (timeZone) {
		date = moment.tz(t, timeZone).format(dateFormat);
	} else {
		date = moment(t).format(dateFormat);
	}
	return date;
};

export const slugValidation = (value: any) => {
	console.log(value, 'ffffffffffffffffffffffff');
	let regex = /^[a-zA-Z0-9][a-zA-Z0-9-_.]*$/;
	let isSlugValid = regex.test(value);
	console.log(isSlugValid, '############33333');
	return isSlugValid;
};
export const numFormatter =(num:number)=> {
    if(num > 999 && num < 1000000){
        return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million
    }else if(num > 1000000){
        return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million
    }else if(num < 900){
        return num; // if value < 1000, nothing to do
    }
}