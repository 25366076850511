import * as actions from '../../types';
import { IUserInfo, IProviderSetup, IStripeAccountId } from './interface';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { logger } from '../../../settings/helpers/Common';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
import * as constants from '../../../settings/helpers/constants';
import History from '../../../settings/helpers/History';
import { homeUrl } from '../../../settings/helpers/constants';

export const validateToken = (): ThunkAction<
	Promise<void>,
	{},
	{},
	AnyAction
> => {
	return async (
		dispatch: ThunkDispatch<any, any, AnyAction>
	): Promise<void> => {
		let roleId = localStorage.getItem('roleId');

		try {
			const result: any = await new ApiHelper().FetchFromServer(
				'/studiomanagers',
				'/validate',
				'POST',
				true,
				undefined,
				{ roleId }
			);
			if (result.isError) {
				const pathname = History.location.pathname;
				if (
					pathname === '/customer/profile' ||
					pathname === '/customer/bookings'
				) {
					await History.push(homeUrl);
				}
				throw new Error(result.messages);
			}

			const { data } = result;
			if (data.statusCode === 200 && data.success) {
				dispatch(
					onAuthSuccess({
						isAuthenticated: true,
						role: data.data.roleName,
						roleId: data.data.roleId,
						userInfo: {
							...data.data,
							// avatar: await getImageBlob(data.data.profileImage),
							avatar: data.data.profileImageUrl,
							token: localStorage.getItem(constants.id),
						},
					})
				);
			}
		} catch (error) {
			// localStorage.clear();
			localStorage.removeItem('id');
			localStorage.removeItem('roleId');
			sessionStorage.clear();
			dispatch(onAuthFailure(error));
			logger(error);
		}
	};
};

export const getProviderSetup = (): ThunkAction<
	Promise<void>,
	{},
	{},
	AnyAction
> => {
	return async (
		dispatch: ThunkDispatch<any, any, AnyAction>
	): Promise<void> => {
		try {
			const result = await new ApiHelper().FetchFromServer(
				'/studiomanagers',
				'/providerSetups/getProviderSetup',
				'GET',
				true,
				undefined,
				undefined
			);

			const { data } = result;
			if (data && data.statusCode === 200 && data.success) {
				dispatch(storeProviderSetupState(data.data[0]));
			}
		} catch (error) {
			dispatch(getProviderSetupFailure());
			logger(error);
		}
	};
};

const onAuthSuccess = (payload: {
	isAuthenticated: boolean;
	role: string;
	roleId: string;
	userInfo: IUserInfo;
}) => {
	return {
		type: actions.TOKEN_CHECK_SUCCESS,
		payload,
	};
};

const onAuthFailure = (error: Error) => {
	return {
		type: actions.TOKEN_CHECK_FAILURE,
		errMessage: error.message,
	};
};

export const saveUserInfo = (payload: {
	isAuthenticated: boolean;
	role: string;
	roleId: string;
	userInfo: IUserInfo;
}) => {
	return {
		type: actions.SAVE_USER_INFO,
		payload,
	};
};

export const storeProviderSetupState = (payload: IProviderSetup) => ({
	type: actions.SAVE_PROVIDER_SETUP_STATE,
	payload,
});

export const getProviderSetupFailure = () => ({
	type: actions.GET_PROVIDER_SETUP_FAILURE
});

export const storeStripeAccount = (payload: IStripeAccountId) => ({
	type: actions.SAVE_STRIPE_ACCOUNT_ID,
	payload,
});

export const onLogout = () => {
	return {
		type: actions.USER_LOGOUT,
	};
};
