import * as actions from '../../types';
import { IStaffStoreState, IStaffActionTypes } from './interface';

const initialState: IStaffStoreState = {
	staffLoading: false,
	staffSuccess: false,
	staffSuccessMsg: '',
	staffErrMessage: '',
	staffs: [],
	totalStaffs: 0,
	branchStaffLoading: false,
	branchStaffSuccess: false,
	branchStaffSuccessMsg: '',
	branchStaffErrMessage: '',
	branchStaffs: [],
	totalBranchStaffs: 0,
};

const staffReducer = (
	state: IStaffStoreState = initialState,
	action: IStaffActionTypes
) => {
	switch (action.type) {
		case actions.STAFF_REQUEST:
			return {
				...state,
				staffLoading: true,
			};

		case actions.STAFF_SUCCESS:
			return {
				...state,
				staffLoading: false,
				staffSuccess: true,
				staffErrMessage: '',
			};

		case actions.STAFF_FAILURE:
			return {
				...state,
				staffLoading: false,
				staffSuccess: false,
				staffs: [],
				totalStaffs: 0,
				staffSuccessMsg: '',
			};

		default:
			return state;
	}
};

export default staffReducer;
