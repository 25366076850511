
export enum AppEnvironment {
	DEVELOPMENT = "dev",
	STAGING = "stage",
	PROD = "live"
}

export interface AppConfiguration {
	SITE_ENDPOINT: string;
	API_ENDPOINT: string;
	SOCKET_ENDPOINT: string;
	API_VERSION: string;
	IMAGE_URL: string;
	itemsPerPage?: string | number;
	defaultDateFormat?: string;
	defaultActivityDateFormat?: string;
}