import * as actions from '../../types';
import { IStudioServiceStoreState, IServiceActionTypes } from './interface';

const initialState: IStudioServiceStoreState = {
	serviceLoading: false,
	serviceSuccess: false,
	serviceSuccessMsg: '',
	serviceErrMessage: '',
	services: [],
	totalServices: 0,
	branchServiceLoading: false,
	branchServiceSuccess: false,
	branchServiceSuccessMsg: '',
	branchServiceErrMessage: '',
	branchServices: [],
	totalBranchServices: 0,
};

const studioServiceReducer = (
	state: IStudioServiceStoreState = initialState,
	action: IServiceActionTypes
) => {
	switch (action.type) {
		case actions.SERVICE_REQUEST:
			return {
				...state,
				serviceLoading: true,
			};

		case actions.SERVICE_SUCCESS:
			return {
				...state,
				serviceLoading: false,
				serviceSuccess: true,
				serviceErrMessage: '',
			};

		case actions.SERVICE_FAILURE:
			return {
				...state,
				serviceLoading: false,
				serviceSuccess: false,
				services: [],
				totalServices: 0,
				serviceSuccessMsg: '',
			};

		default:
			return state;
	}
};

export default studioServiceReducer;
