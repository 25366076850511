// INTERFACE
import { lazy } from 'react';
import { IUserRoutes } from '../interfaces/routes.interface';
import uuidv4 from 'uuid/v4';

const Layout = lazy(() => import('../../containers/Layout'));
const EventDetails = lazy(() =>
	import('../../containers/Users/New/EventDetails')
);
const UserHome = lazy(() => import('../../containers/Users/UserHome'));
const AboutUs = lazy(() => import('../../containers/Users/New/AboutUs'));
const ThankYou = lazy(() => import('../../containers/Users/New/Thankyou'));
const ContactUs = lazy(() => import('../../containers/Users/New/ContactUs'));
const SignUp = lazy(() => import('../../containers/Users/New/Signup'));
const CustomerSignup = lazy(() =>
	import('../../containers/Users/New/CustomerSignup')
);
const Login = lazy(() => import('../../containers/Users/New/Login'));
const CustomerLogin = lazy(() =>
	import('../../containers/Users/New/CustomerLogin')
);
const ForgotPassword = lazy(() =>
	import('../../containers/Users/New/ForgotPassword')
);
const ResetPassword = lazy(() =>
	import('../../containers/Users/New/ResetPassword')
);
const Feedback = lazy(() => import('../../containers/Users/New/Feedback'));
const PrivacyPolicy = lazy(() =>
	import('../../containers/Users/New/PrivacyPolicy')
);
const TermsOfService = lazy(() =>
	import('../../containers/Users/New/TermsOfService')
);
const SearchList = lazy(() => import('../../containers/Users/New/SearchList'));
const StudioListDetail = lazy(() =>
	import('../../containers/Users/New/StudioListDetail')
);
const Profile = lazy(() =>
	import('../../containers/CustomerDashboard/Profile')
);
const BookingList = lazy(() =>
	import('../../containers/CustomerDashboard/BookingList')
);
const Partner = lazy(() => import('../../containers/Users/New/Partner'));
const Blog = lazy(() => import('../../containers/Users/New/Blog'));
const BlogDetail = lazy(() => import('../../containers/Users/New/BlogDetail'));
const EventList = lazy(() => import('../../containers/Events'));
const RetreatDetails = lazy(() =>
	import('../../containers/Events/EventDetails')
);
const CustomerProfile = lazy(() =>
	import('../../containers/Customer/CustomerProfile')
);
const StudioDetails = lazy(() => import('../../containers/StudioDetails'));
const CustomerNotificationList = lazy(() =>
	import('../../containers/Users/UserNavbar/NotificationList')
);
const studioDetails = lazy(() => import('../../containers/StudioDetails'));
const LiveSessions = lazy(()=> import('../../containers/VisualSessions'));

const NewHome = lazy(() => import('../../containers/Components/NewHome'));

const userRoutes: IUserRoutes[] = [
	{
		path: '/home',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,	
		component: NewHome,
		name: 'User Home',
		id: uuidv4(),
	},
	{
		path: '/newhome',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: NewHome,
		name: 'NewHome',
		id: uuidv4(),
	},
	{
		path: '/',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: NewHome,
		name: 'User Home',
		id: uuidv4(),
	},
	{
		path: '/signup',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: SignUp,
		name: 'SignUp',
		id: uuidv4(),
	},
	{
		path: '/customer-signup',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: CustomerSignup,
		name: 'Customer SignUp',
		id: uuidv4(),
	},
	{
		path: '/login',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: Login,
		name: 'Login',
		id: uuidv4(),
	},
	{
		path: '/customer-login',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: CustomerLogin,
		name: 'Customer Login',
		id: uuidv4(),
	},
	{
		path: '/search',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: SearchList,
		name: 'Studio List',
		id: uuidv4(),
	},
	{
		path: '/search/provider-details',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: StudioListDetail,
		name: 'Studio List Detail',
		id: uuidv4(),
	},
	{
		path: '/provider/**',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: StudioListDetail,
		name: 'Studio List Detail',
		id: uuidv4(),
	},
	{
		path: '/search/event-details',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: EventDetails,
		name: 'Event Details',
		id: uuidv4(),
	},
	{
		path: '/customer/bookings',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: BookingList,
		name: 'Bookings',
		id: uuidv4(),
	},
	{
		path: '/customer/edit-profile',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: Profile,
		name: 'Profile',
		id: uuidv4(),
	},
	{
		path: '/aboutus',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: AboutUs,
		name: 'About Us',
		id: uuidv4(),
	},
	{
		path: '/contactus',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: ContactUs,
		name: 'Contact Us',
		id: uuidv4(),
	},
	{
		path: '/partner',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: Partner,
		name: 'For Partners',
		id: uuidv4(),
	},
	{
		path: '/blog',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: Blog,
		name: ' Blog',
		id: uuidv4(),
	},
	{
		path: '/blog-detail/:id?',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: BlogDetail,
		name: ' Blog Detail',
		id: uuidv4(),
	},
	{
		path: '/thankyou',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: ThankYou,
		name: 'Thank You',
		id: uuidv4(),
	},
	{
		path: '/privacy-policy',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: PrivacyPolicy,
		name: 'Privacy Policy',
		id: uuidv4(),
	},
	{
		path: '/terms-of-service',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: TermsOfService,
		name: 'Terms Of Service',
		id: uuidv4(),
	},
	{
		path: '/feedback',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: Feedback,
		name: 'Feedback',
		id: uuidv4(),
	},
	{
		path: '/forgot-password',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: ForgotPassword,
		name: 'Forgot Password',
		id: uuidv4(),
	},
	{
		path: '/reset-password/:token?',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: ResetPassword,
		name: 'Reset Password',
		id: uuidv4(),
	},
	{
		path: '/events',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: EventList,
		name: 'Event List',
		id: uuidv4(),
	},
	{
		path: '/event-detail/:id?',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: RetreatDetails,
		name: 'Retreat Details',
		id: uuidv4(),
	},
	{
		path: '/customer/profile',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: CustomerProfile,
		name: 'Customer Profile',
		id: uuidv4(),
	},
	{
		path: '/studiodetails',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: StudioDetails,
		name: 'Studio Details',
		id: uuidv4(),
	},
	{
		path: '/customer-notificationlist',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: CustomerNotificationList,
		name: 'CustomerNotification List ',
		id: uuidv4(),
	},
	{
		path: '/detail',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: studioDetails,
		name: 'studioDetails',
		id: uuidv4(),
	},

	{
		path: '/visual-session/live-session',
		exact: true,
		isActive: true,
		show: false,
		main: Layout,
		component: LiveSessions,
		name: 'LiveSessions',
		id: uuidv4(),
	},
];

export default userRoutes;
