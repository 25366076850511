import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import History from '../../settings/helpers/History';
import { ApiHelper } from '../../settings/helpers/ApiHelper';
import * as qs from 'query-string';
import ManualSessionComponent from './sessionComponent';
import { withRouter } from 'react-router';
import { IManualSessionState } from './interface';
import { asyncSetState, getTimeSeconds } from '../../settings/helpers/Common';
import {
	customerRoleId,
	providerRoleId,
} from '../../settings/helpers/constants';
import moment from 'moment';
import { IAppState } from '../../store/reducers';
import { AppConfig } from '../../settings/configs/AppConfigs';
import socketIOClient from 'socket.io-client';
import './styles.scss';
let Id = '';
class ManualSession extends PureComponent<any, IManualSessionState> {
	intervalId?: any;
	state = {
		sessionDetail: '',
		participantList: [],
		sessionJoinData: {},
		isProviderJoin: false,
		seconds: 0,
		customerJoinData: [],
		customerId: '',
		sessionId: '',
	};
	componentDidMount = async () => {
		let socket: any = '';
		if (!socket) {
			socket = socketIOClient(AppConfig.API_ENDPOINT, {
				transports: ['websocket'],
				upgrade: false,
			});
		}
		let search: any = {};
		search = { ...qs.parse(History.location.search) };
		if (search.id) {
			this.setState({ sessionId: search.id });
			await this.getSessionDetail(search.id);
			await this.getInpersonSessionCustomers(search.id);
			await this.getInpersonJoinList(search.id);

			//for update customer online status
			socket.on('customerJoin', async (data: any) => {
				if (data.sessionId === search.id) {
					let tempData: any = [...this.state.participantList];
					// let id =data.customerId
					Id = data.customerId;
					this.setState({ customerId: data.customerId });
					let index = tempData.findIndex(
						(item: any) => item.customerId === data.customerId
					);
					if (index >= 0) {
						tempData[index] = {
							...tempData[index],
							isJoin: true,
							loginTime: data.customerLoginTime,
						};
						await asyncSetState(this)({
							...this.state,
							participantList: tempData,
						});
					}
					if (data.providerLoginTime) {
						this.setState({ isProviderJoin: true });
					}
				}
			});
			//for update customer offline status
			socket.on('customerLeave', (data: any) => {
				if (data.sessionId === search.id) {
					let tempData: any = this.state.participantList;
					// let id =data.customerId
					Id = data.customerId;
					this.setState({ customerId: data.customerId });
					let index = tempData.findIndex(
						(item: any) => item.customerId === data.customerId
					);
					if (index >= 0) {
						tempData[index] = {
							...tempData[index],
							isJoin: true,
							logoutTime: data.customerLogoutTime,
						};
						this.setState({ participantList: tempData });
					}
				}
			});
			const { userProps } = this.props;
			const { userInfo } = userProps;
			if (this.state.isProviderJoin && userInfo._id) {
				// this.setSessionTime()
				let sessionDetail: any = this.state;
				// const result: any = await new ApiHelper().FetchFromServer(
				// 	'/studiomanagers',
				// 	`/manualSession/getInPersonJoinInfo?sessionId=${slotId}&userId=${customerId}`,
				// 	'GET',
				// 	true,
				// 	undefined,
				// 	undefined
				// );
				// if(result && result.data &&!(result.data.data[0].customerLoginTime)){
				if (
					userInfo &&
					(userInfo.roleName === 'Customer' || userInfo.roleName === 'End User')
				) {
					const data = {
						customerId: userInfo._id,
						sessionId: search.id,
						customerLoginTime: moment.utc(),
						userRole: 'customer',
						providerId: sessionDetail.studioManagerId,
						classId: sessionDetail.serviceName,
						sessionDetail: this.state.sessionDetail,
						k: '31432121',
					};
					await new ApiHelper().FetchFromServer(
						'/studiomanagers',
						'/manualSession/join',
						'POST',
						true,
						undefined,
						data
					);
				}
				// }
			}
			//for add the customer login time in db after provider login
			socket.on('providerJoin', async (result: any) => {
				this.setState({ isProviderJoin: true });
				const { userProps } = this.props;
				const { userInfo } = userProps;
				let sessionDetail: any = this.state.sessionDetail;
				await this.getInpersonJoinList(search.id);
				if (userInfo && userInfo.roleName === 'Customer') {
					const data = {
						customerId: userInfo._id,
						sessionId: search.id,
						customerLoginTime: moment.utc(),
						userRole: 'customer',
						providerId: sessionDetail.studioManagerId,
						classId: sessionDetail.serviceName,
						sessionDetail: this.state.sessionDetail,
						k: '31432121',
					};
					await new ApiHelper().FetchFromServer(
						'/studiomanagers',
						'/manualSession/join',
						'POST',
						true,
						undefined,
						data
					);
				}
			});
			socket.on('providerLeave', async (result: any) => {
				this.setState({ isProviderJoin: true });
				const { userProps } = this.props;
				const { userInfo } = userProps;
				await this.getInpersonJoinList(search.id);

				if (userInfo) {
					const data = {
						customerId: userInfo._id,
						sessionId: search.id,
						customerLogoutTime: moment.utc(),
					};
					await new ApiHelper().FetchFromServer(
						'/studiomanagers',
						`/manualSession/leaveCustomerSession`,
						'POST',
						true,
						undefined,
						data
					);
					History.push('/home');
				}
			});
		}
	};
	getSessionDetail = async (id: string) => {
		try {
			// await asyncSetState(this)({ ...this.state, eventLoading: true, upcomingEventLoading:true, eventId:slug});
			const result: any = await new ApiHelper().FetchFromServer(
				'/studiomanagers',
				`/slots/getInPersonSessionDetail?_id=${id}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (result.isError) {
				throw new Error(result.messages);
			}

			const { data } = result;
			if (data.statusCode === 200 && data.success) {
				if (data.data.length) {
					await asyncSetState(this)({
						...this.state,
						sessionDetail: data.data[0],
					});
				}
			}
		} catch (error) {
			// logger(error);
			// await asyncSetState(this)({ ...this.state, eventLoading: false });
		}
	};
	//get customer list
	getInpersonSessionCustomers = async (id: string) => {
		try {
			// await asyncSetState(this)({ ...this.state, eventLoading: true, upcomingEventLoading:true, eventId:slug});
			const result: any = await new ApiHelper().FetchFromServer(
				'/studiomanagers',
				`/manualSession/getInPersonSessionCustomerList?slotId=${id}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (result.isError) {
				throw new Error(result.messages);
			}

			const { data } = result;
			if (data.statusCode === 200 && data.success) {
				if (data.data.length) {
					await asyncSetState(this)({
						...this.state,
						participantList: data.data,
					});
				}
			}
		} catch (error) {
			History.push('/home');
			// logger(error);
			// await asyncSetState(this)({ ...this.state, eventLoading: false });
		}
	};

	// //to check provider join the session or not
	getInpersonJoinList = async (id: string) => {
		try {
			// await asyncSetState(this)({ ...this.state, eventLoading: true, upcomingEventLoading:true, eventId:slug});
			const result: any = await new ApiHelper().FetchFromServer(
				'/studiomanagers',
				`/manualSession/getInpersonSessionJoinList?sessionId=${id}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (result.isError) {
				throw new Error(result.messages);
			}

			const { data } = result;
			if (data.statusCode === 200 && data.success) {
				if (data.data.length) {
					await asyncSetState(this)({
						...this.state,
						sessionJoinData: data.data[0],
					});

					if (data.data[0].providerLoginTime) {
						this.setSessionTime();
						this.setState({ isProviderJoin: true });
					}
				}
			}
		} catch (error) {
			// logger(error);
			// await asyncSetState(this)({ ...this.state, eventLoading: false });
		}
	};
	setSessionTime = async () => {
		let sessionDetail: any = this.state.sessionDetail;
		let sessionJoinData: any = this.state.sessionJoinData;
		if (sessionJoinData && sessionJoinData.providerLoginTime) {
			let curremtTime: any = moment().format('hh:mm:ss a');
			let seconds: any = '';
			let providerLoginTime: any = moment(
				sessionJoinData.providerLoginTime
			).format('hh:mm:ss a');
			var startTime = moment(providerLoginTime, 'hh:mm:ss a');
			var endTime = moment(curremtTime, 'hh:mm:ss a');
			var duration: any = moment.duration(endTime.diff(startTime));
			// let diff =endTime.diff(startTime, 'minutes');

			var minutes = parseInt(duration.asMinutes()) % 60;
			seconds = minutes * 60;
			this.intervalId = setInterval(() => {
				if (sessionDetail) {
					if (sessionDetail && sessionDetail.slots.length) {
						let currentDate = moment.utc().format();
						const sessionEnd = moment(currentDate).isAfter(
							moment(sessionDetail.slots[0].endTime)
						);
						if (sessionEnd) {
							this.onSessionClosed();
							History.push('/home');
						}
					}
				}
				seconds = seconds + 1;
				let newTime = getTimeSeconds(seconds);
				this.setState({ seconds: newTime });
			}, 1000);
		}
	};
	onSessionClosed = async () => {
		const { sessionId } = this.state;
		let apiUrl = '';
		const { userProps } = this.props;
		const { userInfo } = userProps;
		let roleId = userProps.roleId;
		const data: any = {
			// sessionId:sessionDetail._id
		};
		if (roleId === customerRoleId) {
			data.customerId = userInfo._id;
			data.customerLogoutTime = moment.utc();
			data.slotId = sessionId;
			apiUrl = 'leaveCustomerSession';
		} else if (roleId === providerRoleId) {
			data.studioManagerId = userInfo._id;
			data.providerLogoutTime = moment.utc();
			data.slotId = sessionId;
			apiUrl = 'leaveProviderSession';
		}
		await new ApiHelper().FetchFromServer(
			'/studiomanagers',
			`/manualSession/${apiUrl}`,
			'POST',
			true,
			undefined,
			data
		);
		History.push('/home');
	};

	componentWillUnmount() {
		clearInterval(this.intervalId);
	}
	render() {
		return (
			<div className='main-detail-page visual-page'>
				<div className='container'>
					<ManualSessionComponent
						{...this.state}
						onSessionClosed={this.onSessionClosed}
					/>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state: IAppState) => {
	return {
		userProps: state.userState,
	};
};

export default withRouter(connect(mapStateToProps, null)(ManualSession));
