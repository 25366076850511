// IMPORTS
import icon_empty from '../../assets/img/Icon_Empty.svg';
import { maskArray } from 'react-text-mask';
import { IMaskResponse } from '../interfaces/constants.interface';
// import moment from 'moment';

export { icon_empty };

export interface IReactSelect {
	label: string;
	value: string;
	category?: string;
}

export const homeUrl = '/home';
export const DATE_FORMAT = 'DD-MM-YY';
export const TIME_FORMAT = 'hh:mm a';
export const SLOT_DATE_FORMAT = 'MM-DD-YY';
export const BOOKING_DATE_FORMAT = 'MMM DD, YYYY';
export const CLASS_DATE_FORMAT = 'dddd MMM DD YYYY';
export const CLASS_DATE_MODAL = 'MMM dd yyyy';
export const PAYMENT_MODAL_DATE_FORMAT = 'DD MMM YYYY';
// LOCAL STORAGE KEYS
export const id = 'id';
export const roleId = 'roleId';
export const userId = 'userId';
export const rememberMe = 'rememberMe';
export const email = 'email';
export const password = 'password';

export const providerRoleId = '5d6e39fd4ae81b11e4a5f102';
export const customerRoleId = '5d6e39fd4ae81b11e4a5f103';

export const addBranchStaffPageLimit = 5;
export const addBranchServicePageLimit = 5;
export const windowWidth = window.innerWidth
export const durationMask: maskArray = [
	/[0-2]/,
	/[0-9]/,
	':',
	/[0-5]/,
	/[0-9]/,
];

export const phoneMask: maskArray = [
	'(',
	/[0-9]/,
	/[0-9]/,
	/[0-9]/,
	')',
	' ',
	/[0-9]/,
	/[0-9]/,
	/[0-9]/,
	'-',
	/[0-9]/,
	/[0-9]/,
	/[0-9]/,
	/[0-9]/,
];

export const cardNumberMask: maskArray = [
	/[0-9]/,
	/\d/,
	/\d/,
	/\d/,
	'\u2000',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	'\u2000',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	'\u2000',
	/\d/,
	/\d/,
	/\d/,
	/\d/
];

export const expiryDateMask: maskArray = [
	/[0-1]/,
	/[0-9]/,
	'/',
	/[2-9]/,
	/[0-9]/,
];

export const cardNumberValidator = (cardNumberString: any): IMaskResponse => {
	if (
		cardNumberString === '' ||
		cardNumberString === undefined ||
		cardNumberString.length < 14 ||
		cardNumberString.length > 19
	) {
		return {
			isValid: false,
			message: 'Please enter a valid card number',
		};
	}
	// if (!/^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$/.test(cardNumberString)) {
	// 	return {
	// 		isValid: false,
	// 		message: 'Please enter a valid card number',
	// 	};
	// }
	return {
		isValid: true,
		message: 'Card number is valid',
	};
};

export const expiryDateValidator = (
	expiryDateString: string
): IMaskResponse => {
	if (!/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/.test(expiryDateString)) {
		return {
			isValid: false,
			message: 'Please enter a valid expiry date',
		};
	}
	return {
		isValid: true,
		message: 'Expiry date is valid',
	};
};

// export const timeValidator = (timeString: string): ITimeResponse => {
// 	if (!/^\d{0,2}?\:?\d{0,2}$/.test(timeString)) {
// 		return {
// 			isValid: false,
// 			message: 'Please enter a valid time'
// 		};
// 	}
// 	return {
// 		isValid: true,
// 		message: 'Time is valid'
// 	};
// };

export const contactValidator = (phoneString: string): IMaskResponse => {
	if (!/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(phoneString)) {
		return {
			isValid: false,
			message: 'Please enter a valid number',
		};
	}
	return {
		isValid: true,
		message: 'Number is valid',
	};
};

export const websiteValidator = (websiteString: string): IMaskResponse => {
	if (
		!/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
			websiteString
		)
	) {
		return {
			isValid: false,
			message: 'Enter proper url with http:// or www',
		};
	} else {
		var splitStr = websiteString.split('.');
		if (splitStr.pop() === 'com') {
			return {
				isValid: true,
				message: 'Number is valid',
			};
		} else {
			return {
				isValid: false,
				message: 'Only .com domain name is acceptable',
			};
		}
	}
};

// export const webRegExp: RegExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

export const webRegExp: RegExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const getHoursAndMinut = (timeString: string) => {
	let result = { hour: '0', min: '0' };
	if (timeString) {
		let hour = '0';
		let min = '0';
		if (timeString.indexOf('h') > -1 && timeString.indexOf('min') > -1) {
			let chunkHour = timeString.split('h');
			let chunkMin = '0';
			if (timeString.indexOf('min') > -1) {
				chunkMin = chunkHour[1].split('min')[0].trim();
			}
			hour = chunkHour[0];
			min = chunkMin;
		} else {
			if (timeString.indexOf('h') > -1) {
				let chunkHour = timeString.split('h');
				hour = chunkHour[0];
			} else if (timeString.indexOf('min') > -1) {
				let chunkMin = timeString.split('min');
				min = chunkMin[0];
			}
		}
		result = { hour: hour, min: min };
	}
	return result;
};

export const getFullAddress = (item: any) => {
	let address = '';
	if (item) {
		address =
			(item.street ? item.street : '') +
			'' +
			(item.address ? item.address : '') +
			'' +
			(item.addressLine1 ? item.addressLine1 : '') +
			'' +
			(item.addressLine2 ? ', ' + item.addressLine2 : '') +
			'' +
			(item.city ? ', ' + item.city : '') +
			'' +
			(item.state ? ', ' + item.state : '') +
			'' +
			(item.zip ? ', ' + item.zip : '') +
			'' +
			(item.country ? ', ' + item.country : '');
	}
	return address;
};

export const getCustomerAddress = (item: any) => {
	let address = '';
	if (item) {
		address =
			(item.city ? item.city : '') +
			'' +
			(item.state ? ', ' + item.state : '') +
			'' +
			(item.zip ? ', ' + item.zip : '') +
			'' +
			(item.country ? ', ' + item.country : '');
	}
	return address;
};

// AMENITIES SCHEMA
export const amenitiesSchema: { name: string; value: boolean }[] = [
	{ name: 'Shower', value: false },
	{ name: 'Keeper', value: false },
	{ name: 'Parking', value: false },
	{ name: 'Drink', value: false },
	{ name: 'Order', value: false },
];

// TIMINGS SCHEMA
export const timingsSchema: {
	day: string;
	opened: boolean;
	timings: { from: string; to: string }[];
}[] = [
	{
		day: 'Monday',
		opened: false,
		timings: [{ from: '', to: '' }],
	},
	{
		day: 'Tuesday',
		opened: false,
		timings: [{ from: '', to: '' }],
	},
	{
		day: 'Wednesday',
		opened: false,
		timings: [{ from: '', to: '' }],
	},
	{
		day: 'Thursday',
		opened: false,
		timings: [{ from: '', to: '' }],
	},
	{
		day: 'Friday',
		opened: false,
		timings: [{ from: '', to: '' }],
	},
	{
		day: 'Saturday',
		opened: false,
		timings: [{ from: '', to: '' }],
	},
	{
		day: 'Sunday',
		opened: false,
		timings: [{ from: '', to: '' }],
	},
];

// SHIFT TIMINGS SCHEMA
export const shiftSchema: IReactSelect[] = [
	{
		label: 'Morning (09:00 - 17:00)',
		value: 'Morning (09:00 - 17:00)',
	},
	{
		label: 'Evening (17:00 - 01:00)',
		value: 'Evening (17:00 - 01:00)',
	},
	{
		label: 'Night (01:00 - 09:00)',
		value: 'Night (01:00 - 09:00)',
	},
];

// JOB POSITION
export const deptSchema: IReactSelect[] = [
	{
		label: 'HR',
		value: 'HR',
	},
	{
		label: 'Sale',
		value: 'Sale',
	},
	{
		label: 'Security',
		value: 'Security',
	},
];

export const hrPositionSchema: IReactSelect[] = [
	{ label: 'Senior', value: 'Senior' },
	{ label: 'Junior', value: 'Junior' },
	{ label: 'Supervisor', value: 'Supervisor' },
	{ label: 'Intern', value: 'Intern' },
	{ label: 'Assistant', value: 'Assistant' },
];

export const salePositionSchema: IReactSelect[] = [
	{
		label: 'Marketing',
		value: 'Marketing',
	},
	{
		label: 'Sale',
		value: 'Sale',
	},
	{
		label: 'Cashier',
		value: 'Cashier',
	},
	{
		label: 'Manager',
		value: 'Manager',
	},
	{
		label: 'Admin',
		value: 'Admin',
	},
];

export const securityPositionSchema: IReactSelect[] = [
	{ label: 'Head Security', value: 'Head Security' },
	{ label: 'Assistant Security', value: 'Assistant Security' },
	{ label: 'Content Security', value: 'Content Security' },
	{ label: 'Workshop Security', value: 'Workshop Security' },
];

export const juridictionSale: { name: string; value: boolean }[] = [
	{ name: 'Customer care', value: false },
	{ name: 'Manage staff', value: true },
	{ name: 'Give marketing strategy', value: true },
	{ name: 'Order', value: false },
	{ name: 'Manage Money', value: false },
];

export const juridictionMarketing: { name: string; value: boolean }[] = [
	{ name: 'Customer care', value: false },
	{ name: 'Manage staff', value: true },
	{ name: 'Give marketing strategy', value: true },
	{ name: 'Order', value: false },
	{ name: 'Manage Money', value: false },
];

export const eventTypeSchema: { name: string; _id: string }[] = [
	{ name: 'General', _id: 'General' },
	{ name: 'VIP', _id: 'VIP' },
	{ name: 'VVIP', _id: 'VVIP' },
];
export const eventStatusSchema: { name: string; _id: string }[] = [
	{ name: 'Active', _id: 'Active' },
	{ name: 'Inactive', _id: 'Inactive' },
];

export const orderEventTypes: { name: string; _id: string }[] = [
	{
		name: 'Class',
		_id: 'class',
	},
	{
		name: 'Service',
		_id: 'service',
	},
	{
		name: 'Event',
		_id: 'event',
	},
];

export const durationSchema: IReactSelect[] = [
	{ value: '5min', label: '5min' },
	{ value: '10min', label: '10min' },
	{ value: '15min', label: '15min' },
	{ value: '20min', label: '20min' },
	{ value: '25min', label: '25min' },
	{ value: '30min', label: '30min' },
	{ value: '35min', label: '35min' },
	{ value: '40min', label: '40min' },
	{ value: '45min', label: '45min' },
	{ value: '50min', label: '50min' },
	{ value: '55min', label: '55min' },
	{ value: '1h', label: '1h' },
	{ value: '1h 5min', label: '1h 5min' },
	{ value: '1h 10min', label: '1h 10min' },
	{ value: '1h 15min', label: '1h 15min' },
	{ value: '1h 20min', label: '1h 20min' },
	{ value: '1h 25min', label: '1h 25min' },
	{ value: '1h 30min', label: '1h 30min' },
	{ value: '1h 35min', label: '1h 35min' },
	{ value: '1h 40min', label: '1h 40min' },
	{ value: '1h 45min', label: '1h 45min' },
	{ value: '1h 50min', label: '1h 50min' },
	{ value: '1h 55min', label: '1h 55min' },
	{ value: '2h', label: '2h' },
	{ value: '2h 15min', label: '2h 15min' },
	{ value: '2h 30min', label: '2h 30min' },
	{ value: '2h 45min', label: '2h 45min' },
	{ value: '3h', label: '3h' },
	{ value: '3h 15min', label: '3h 15min' },
	{ value: '3h 30min', label: '3h 30min' },
	{ value: '3h 45min', label: '3h 45min' },
	{ value: '4h', label: '4h' },
	{ value: '4h 30min', label: '4h 30min' },
	{ value: '5h', label: '5h' },
	{ value: '5h 30min', label: '5h 30min' },
	{ value: '6h', label: '6h' },
	{ value: '6h 30min', label: '6h 30min' },
	{ value: '7h', label: '7h' },
	{ value: '7h 30min', label: '7h 30min' },
	{ value: '8h', label: '8h' },
	{ value: '9h', label: '9h' },
	{ value: '10h', label: '10h' },
	{ value: '11h', label: '11h' },
	{ value: '12h', label: '12h' },
];

export const typeSchema: IReactSelect[] = [
	{ value: 'In Person', label: 'In Person' },
	{ value: 'Virtual Session', label: 'Virtual Session' },
];

export const timeSchema: IReactSelect[] = [
	{ value: '12:00 am', label: '12:00 am' },
	{ value: '12:05 am', label: '12:05 am' },
	{ value: '12:10 am', label: '12:10 am' },
	{ value: '12:15 am', label: '12:15 am' },
	{ value: '12:20 am', label: '12:20 am' },
	{ value: '12:25 am', label: '12:25 am' },
	{ value: '12:30 am', label: '12:30 am' },
	{ value: '12:35 am', label: '12:35 am' },
	{ value: '12:40 am', label: '12:40 am' },
	{ value: '12:45 am', label: '12:45 am' },
	{ value: '12:50 am', label: '12:50 am' },
	{ value: '12:55 am', label: '12:55 am' },
	{ value: '1:00 am', label: '1:00 am' },
	{ value: '1:05 am', label: '1:05 am' },
	{ value: '1:10 am', label: '1:10 am' },
	{ value: '1:15 am', label: '1:15 am' },
	{ value: '1:20 am', label: '1:20 am' },
	{ value: '1:25 am', label: '1:25 am' },
	{ value: '1:30 am', label: '1:30 am' },
	{ value: '1:35 am', label: '1:35 am' },
	{ value: '1:40 am', label: '1:40 am' },
	{ value: '1:45 am', label: '1:45 am' },
	{ value: '1:50 am', label: '1:50 am' },
	{ value: '1:55 am', label: '1:55 am' },
	{ value: '2:00 am', label: '2:00 am' },
	{ value: '2:05 am', label: '2:05 am' },
	{ value: '2:10 am', label: '2:10 am' },
	{ value: '2:15 am', label: '2:15 am' },
	{ value: '2:20 am', label: '2:20 am' },
	{ value: '2:25 am', label: '2:25 am' },
	{ value: '2:30 am', label: '2:30 am' },
	{ value: '2:35 am', label: '2:35 am' },
	{ value: '2:40 am', label: '2:40 am' },
	{ value: '2:45 am', label: '2:45 am' },
	{ value: '2:50 am', label: '2:50 am' },
	{ value: '2:55 am', label: '2:55 am' },
	{ value: '3:00 am', label: '3:00 am' },
	{ value: '3:05 am', label: '3:05 am' },
	{ value: '3:10 am', label: '3:10 am' },
	{ value: '3:15 am', label: '3:15 am' },
	{ value: '3:20 am', label: '3:20 am' },
	{ value: '3:25 am', label: '3:25 am' },
	{ value: '3:30 am', label: '3:30 am' },
	{ value: '3:35 am', label: '3:35 am' },
	{ value: '3:40 am', label: '3:40 am' },
	{ value: '3:45 am', label: '3:45 am' },
	{ value: '3:50 am', label: '3:50 am' },
	{ value: '3:55 am', label: '3:55 am' },
	{ value: '4:00 am', label: '4:00 am' },
	{ value: '4:05 am', label: '4:05 am' },
	{ value: '4:10 am', label: '4:10 am' },
	{ value: '4:15 am', label: '4:15 am' },
	{ value: '4:20 am', label: '4:20 am' },
	{ value: '4:25 am', label: '4:25 am' },
	{ value: '4:30 am', label: '4:30 am' },
	{ value: '4:35 am', label: '4:35 am' },
	{ value: '4:40 am', label: '4:40 am' },
	{ value: '4:45 am', label: '4:45 am' },
	{ value: '4:50 am', label: '4:50 am' },
	{ value: '4:55 am', label: '4:55 am' },
	{ value: '5:00 am', label: '5:00 am' },
	{ value: '5:05 am', label: '5:05 am' },
	{ value: '5:10 am', label: '5:10 am' },
	{ value: '5:15 am', label: '5:15 am' },
	{ value: '5:20 am', label: '5:20 am' },
	{ value: '5:25 am', label: '5:25 am' },
	{ value: '5:30 am', label: '5:30 am' },
	{ value: '5:35 am', label: '5:35 am' },
	{ value: '5:40 am', label: '5:40 am' },
	{ value: '5:45 am', label: '5:45 am' },
	{ value: '5:50 am', label: '5:50 am' },
	{ value: '5:55 am', label: '5:55 am' },
	{ value: '6:00 am', label: '6:00 am' },
	{ value: '6:05 am', label: '6:05 am' },
	{ value: '6:10 am', label: '6:10 am' },
	{ value: '6:15 am', label: '6:15 am' },
	{ value: '6:20 am', label: '6:20 am' },
	{ value: '6:25 am', label: '6:25 am' },
	{ value: '6:30 am', label: '6:30 am' },
	{ value: '6:35 am', label: '6:35 am' },
	{ value: '6:40 am', label: '6:40 am' },
	{ value: '6:45 am', label: '6:45 am' },
	{ value: '6:50 am', label: '6:50 am' },
	{ value: '6:55 am', label: '6:55 am' },
	{ value: '7:00 am', label: '7:00 am' },
	{ value: '7:05 am', label: '7:05 am' },
	{ value: '7:10 am', label: '7:10 am' },
	{ value: '7:15 am', label: '7:15 am' },
	{ value: '7:20 am', label: '7:20 am' },
	{ value: '7:25 am', label: '7:25 am' },
	{ value: '7:30 am', label: '7:30 am' },
	{ value: '7:35 am', label: '7:35 am' },
	{ value: '7:40 am', label: '7:40 am' },
	{ value: '7:45 am', label: '7:45 am' },
	{ value: '7:50 am', label: '7:50 am' },
	{ value: '7:55 am', label: '7:55 am' },
	{ value: '8:00 am', label: '8:00 am' },
	{ value: '8:05 am', label: '8:05 am' },
	{ value: '8:10 am', label: '8:10 am' },
	{ value: '8:15 am', label: '8:15 am' },
	{ value: '8:20 am', label: '8:20 am' },
	{ value: '8:25 am', label: '8:25 am' },
	{ value: '8:30 am', label: '8:30 am' },
	{ value: '8:35 am', label: '8:35 am' },
	{ value: '8:40 am', label: '8:40 am' },
	{ value: '8:45 am', label: '8:45 am' },
	{ value: '8:50 am', label: '8:50 am' },
	{ value: '8:55 am', label: '8:55 am' },
	{ value: '9:00 am', label: '9:00 am' },
	{ value: '9:05 am', label: '9:05 am' },
	{ value: '9:10 am', label: '9:10 am' },
	{ value: '9:15 am', label: '9:15 am' },
	{ value: '9:20 am', label: '9:20 am' },
	{ value: '9:25 am', label: '9:25 am' },
	{ value: '9:30 am', label: '9:30 am' },
	{ value: '9:35 am', label: '9:35 am' },
	{ value: '9:40 am', label: '9:40 am' },
	{ value: '9:45 am', label: '9:45 am' },
	{ value: '9:50 am', label: '9:50 am' },
	{ value: '9:55 am', label: '9:55 am' },
	{ value: '10:00 am', label: '10:00 am' },
	{ value: '10:05 am', label: '10:05 am' },
	{ value: '10:10 am', label: '10:10 am' },
	{ value: '10:15 am', label: '10:15 am' },
	{ value: '10:20 am', label: '10:20 am' },
	{ value: '10:25 am', label: '10:25 am' },
	{ value: '10:30 am', label: '10:30 am' },
	{ value: '10:35 am', label: '10:35 am' },
	{ value: '10:40 am', label: '10:40 am' },
	{ value: '10:45 am', label: '10:45 am' },
	{ value: '10:50 am', label: '10:50 am' },
	{ value: '10:55 am', label: '10:55 am' },
	{ value: '11:00 am', label: '11:00 am' },
	{ value: '11:05 am', label: '11:05 am' },
	{ value: '11:10 am', label: '11:10 am' },
	{ value: '11:15 am', label: '11:15 am' },
	{ value: '11:20 am', label: '11:20 am' },
	{ value: '11:25 am', label: '11:25 am' },
	{ value: '11:30 am', label: '11:30 am' },
	{ value: '11:35 am', label: '11:35 am' },
	{ value: '11:40 am', label: '11:40 am' },
	{ value: '11:45 am', label: '11:45 am' },
	{ value: '11:50 am', label: '11:50 am' },
	{ value: '11:55 am', label: '11:55 am' },
	{ value: '12:00 pm', label: '12:00 pm' },
	{ value: '12:05 pm', label: '12:05 pm' },
	{ value: '12:10 pm', label: '12:10 pm' },
	{ value: '12:15 pm', label: '12:15 pm' },
	{ value: '12:20 pm', label: '12:20 pm' },
	{ value: '12:25 pm', label: '12:25 pm' },
	{ value: '12:30 pm', label: '12:30 pm' },
	{ value: '12:35 pm', label: '12:35 pm' },
	{ value: '12:40 pm', label: '12:40 pm' },
	{ value: '12:45 pm', label: '12:45 pm' },
	{ value: '12:50 pm', label: '12:50 pm' },
	{ value: '12:55 pm', label: '12:55 pm' },
	{ value: '1:00 pm', label: '1:00 pm' },
	{ value: '1:05 pm', label: '1:05 pm' },
	{ value: '1:10 pm', label: '1:10 pm' },
	{ value: '1:15 pm', label: '1:15 pm' },
	{ value: '1:20 pm', label: '1:20 pm' },
	{ value: '1:25 pm', label: '1:25 pm' },
	{ value: '1:30 pm', label: '1:30 pm' },
	{ value: '1:35 pm', label: '1:35 pm' },
	{ value: '1:40 pm', label: '1:40 pm' },
	{ value: '1:45 pm', label: '1:45 pm' },
	{ value: '1:50 pm', label: '1:50 pm' },
	{ value: '1:55 pm', label: '1:55 pm' },
	{ value: '2:00 pm', label: '2:00 pm' },
	{ value: '2:05 pm', label: '2:05 pm' },
	{ value: '2:10 pm', label: '2:10 pm' },
	{ value: '2:15 pm', label: '2:15 pm' },
	{ value: '2:20 pm', label: '2:20 pm' },
	{ value: '2:25 pm', label: '2:25 pm' },
	{ value: '2:30 pm', label: '2:30 pm' },
	{ value: '2:35 pm', label: '2:35 pm' },
	{ value: '2:40 pm', label: '2:40 pm' },
	{ value: '2:45 pm', label: '2:45 pm' },
	{ value: '2:50 pm', label: '2:50 pm' },
	{ value: '2:55 pm', label: '2:55 pm' },
	{ value: '3:00 pm', label: '3:00 pm' },
	{ value: '3:05 pm', label: '3:05 pm' },
	{ value: '3:10 pm', label: '3:10 pm' },
	{ value: '3:15 pm', label: '3:15 pm' },
	{ value: '3:20 pm', label: '3:20 pm' },
	{ value: '3:25 pm', label: '3:25 pm' },
	{ value: '3:30 pm', label: '3:30 pm' },
	{ value: '3:35 pm', label: '3:35 pm' },
	{ value: '3:40 pm', label: '3:40 pm' },
	{ value: '3:45 pm', label: '3:45 pm' },
	{ value: '3:50 pm', label: '3:50 pm' },
	{ value: '3:55 pm', label: '3:55 pm' },
	{ value: '4:00 pm', label: '4:00 pm' },
	{ value: '4:05 pm', label: '4:05 pm' },
	{ value: '4:10 pm', label: '4:10 pm' },
	{ value: '4:15 pm', label: '4:15 pm' },
	{ value: '4:20 pm', label: '4:20 pm' },
	{ value: '4:25 pm', label: '4:25 pm' },
	{ value: '4:30 pm', label: '4:30 pm' },
	{ value: '4:35 pm', label: '4:35 pm' },
	{ value: '4:40 pm', label: '4:40 pm' },
	{ value: '4:45 pm', label: '4:45 pm' },
	{ value: '4:50 pm', label: '4:50 pm' },
	{ value: '4:55 pm', label: '4:55 pm' },
	{ value: '5:00 pm', label: '5:00 pm' },
	{ value: '5:05 pm', label: '5:05 pm' },
	{ value: '5:10 pm', label: '5:10 pm' },
	{ value: '5:15 pm', label: '5:15 pm' },
	{ value: '5:20 pm', label: '5:20 pm' },
	{ value: '5:25 pm', label: '5:25 pm' },
	{ value: '5:30 pm', label: '5:30 pm' },
	{ value: '5:35 pm', label: '5:35 pm' },
	{ value: '5:40 pm', label: '5:40 pm' },
	{ value: '5:45 pm', label: '5:45 pm' },
	{ value: '5:50 pm', label: '5:50 pm' },
	{ value: '5:55 pm', label: '5:55 pm' },
	{ value: '6:00 pm', label: '6:00 pm' },
	{ value: '6:05 pm', label: '6:05 pm' },
	{ value: '6:10 pm', label: '6:10 pm' },
	{ value: '6:15 pm', label: '6:15 pm' },
	{ value: '6:20 pm', label: '6:20 pm' },
	{ value: '6:25 pm', label: '6:25 pm' },
	{ value: '6:30 pm', label: '6:30 pm' },
	{ value: '6:35 pm', label: '6:35 pm' },
	{ value: '6:40 pm', label: '6:40 pm' },
	{ value: '6:45 pm', label: '6:45 pm' },
	{ value: '6:50 pm', label: '6:50 pm' },
	{ value: '6:55 pm', label: '6:55 pm' },
	{ value: '7:00 pm', label: '7:00 pm' },
	{ value: '7:05 pm', label: '7:05 pm' },
	{ value: '7:10 pm', label: '7:10 pm' },
	{ value: '7:15 pm', label: '7:15 pm' },
	{ value: '7:20 pm', label: '7:20 pm' },
	{ value: '7:25 pm', label: '7:25 pm' },
	{ value: '7:30 pm', label: '7:30 pm' },
	{ value: '7:35 pm', label: '7:35 pm' },
	{ value: '7:40 pm', label: '7:40 pm' },
	{ value: '7:45 pm', label: '7:45 pm' },
	{ value: '7:50 pm', label: '7:50 pm' },
	{ value: '7:55 pm', label: '7:55 pm' },
	{ value: '8:00 pm', label: '8:00 pm' },
	{ value: '8:05 pm', label: '8:05 pm' },
	{ value: '8:10 pm', label: '8:10 pm' },
	{ value: '8:15 pm', label: '8:15 pm' },
	{ value: '8:20 pm', label: '8:20 pm' },
	{ value: '8:25 pm', label: '8:25 pm' },
	{ value: '8:30 pm', label: '8:30 pm' },
	{ value: '8:35 pm', label: '8:35 pm' },
	{ value: '8:40 pm', label: '8:40 pm' },
	{ value: '8:45 pm', label: '8:45 pm' },
	{ value: '8:50 pm', label: '8:50 pm' },
	{ value: '8:55 pm', label: '8:55 pm' },
	{ value: '9:00 pm', label: '9:00 pm' },
	{ value: '9:05 pm', label: '9:05 pm' },
	{ value: '9:10 pm', label: '9:10 pm' },
	{ value: '9:15 pm', label: '9:15 pm' },
	{ value: '9:20 pm', label: '9:20 pm' },
	{ value: '9:25 pm', label: '9:25 pm' },
	{ value: '9:30 pm', label: '9:30 pm' },
	{ value: '9:35 pm', label: '9:35 pm' },
	{ value: '9:40 pm', label: '9:40 pm' },
	{ value: '9:45 pm', label: '9:45 pm' },
	{ value: '9:50 pm', label: '9:50 pm' },
	{ value: '9:55 pm', label: '9:55 pm' },
	{ value: '10:00 pm', label: '10:00 pm' },
	{ value: '10:05 pm', label: '10:05 pm' },
	{ value: '10:10 pm', label: '10:10 pm' },
	{ value: '10:15 pm', label: '10:15 pm' },
	{ value: '10:20 pm', label: '10:20 pm' },
	{ value: '10:25 pm', label: '10:25 pm' },
	{ value: '10:30 pm', label: '10:30 pm' },
	{ value: '10:35 pm', label: '10:35 pm' },
	{ value: '10:40 pm', label: '10:40 pm' },
	{ value: '10:45 pm', label: '10:45 pm' },
	{ value: '10:50 pm', label: '10:50 pm' },
	{ value: '10:55 pm', label: '10:55 pm' },
	{ value: '11:00 pm', label: '11:00 pm' },
	{ value: '11:05 pm', label: '11:05 pm' },
	{ value: '11:10 pm', label: '11:10 pm' },
	{ value: '11:15 pm', label: '11:15 pm' },
	{ value: '11:20 pm', label: '11:20 pm' },
	{ value: '11:25 pm', label: '11:25 pm' },
	{ value: '11:30 pm', label: '11:30 pm' },
	{ value: '11:35 pm', label: '11:35 pm' },
	{ value: '11:40 pm', label: '11:40 pm' },
	{ value: '11:45 pm', label: '11:45 pm' },
	{ value: '11:50 pm', label: '11:50 pm' },
	{ value: '11:55 pm', label: '11:55 pm' },
];

export const businessTypeSchema: IReactSelect[] = [
	{ value: 'Wellness Provider', label: 'Wellness Provider' },
	{ value: 'Management', label: 'Management' },
	{ value: 'Nutritionist', label: 'Nutritionist' },
];

// export const calendarFilterSchema: IReactSelect[] = [
// 	{ value: "service", label: "Service" },
// 	{ value: "class", label: "Class" },
// 	{ value: "event", label: "Event" },
// ]

export const calendarFilterSchema: IReactSelect[] = [
	{ value: 'service', label: 'Private Sessions' },
	{ value: 'class', label: 'Group Classes' },
	{ value: 'event', label: 'Retreat Experience' },
];

export const virtualBookingFilterSchema: IReactSelect[] = [
	{ value: 'service', label: 'Private Sessions' },
	{ value: 'class', label: 'Group Classes' },
];

export const seatSchema: IReactSelect[] = [
	{ value: '1', label: '1' },
	{ value: '2', label: '2' },
	{ value: '3', label: '3' },
	{ value: '4', label: '4' },
	{ value: '5', label: '5' },
	{ value: '6', label: '6' },
	{ value: '7', label: '7' },
	{ value: '8', label: '8' },
	{ value: '9', label: '9' },
	{ value: '10', label: '10' },
];

export const slotValidation = (obj: any, name: string, value: any) => {
	if (name === 'startTime') {
		if (value) {
			obj = { ...obj, startTimeError: false };
		} else {
			obj = { ...obj, startTimeError: true };
		}
	}
	if (name === 'serviceId') {
		if (value) {
			obj = { ...obj, serviceIdError: false };
		} else {
			obj = { ...obj, serviceIdError: true };
		}
	}
	if (name === 'classId') {
		if (value) {
			obj = { ...obj, classIdError: false };
		} else {
			obj = { ...obj, classIdError: true };
		}
	}
	if (name === 'duration') {
		if (value) {
			obj = { ...obj, durationError: false };
		} else {
			obj = { ...obj, durationError: true };
		}
	}
	if (name === 'staffId') {
		if (value) {
			obj = { ...obj, staffIdError: false };
		} else {
			obj = { ...obj, staffIdError: true };
		}
	}
	return obj;
};

export const countrySchema: any[] = [
	{ value: 'AU', label: 'Australia', id: '13' },
	{ value: 'AT', label: 'Austria', id: '14' },
	{ value: 'BE', label: 'Belgium', id: '21' },
	{ value: 'CA', label: 'Canada', id: '38' },
	{ value: 'CZ', label: 'Czech Republic', id: '57' },
	{ value: 'DK', label: 'Denmark', id: '58' },
	{ value: 'EE', label: 'Estonia', id: '68' },
	{ value: 'FI', label: 'Finland', id: '74' },
	{ value: 'FR', label: 'France (Metropolitan France only)', id: '75' },
	{ value: 'DE', label: 'Germany', id: '82' },
	{ value: 'GR', label: 'Greece', id: '85' },
	{ value: 'HK', label: 'Hong Kong', id: '98' },
	{ value: 'IN', label: 'India', id: '101' },
	{ value: 'IE', label: 'Ireland', id: '105' },
	{ value: 'IT', label: 'Italy', id: '107' },
	{ value: 'JP', label: 'Japan', id: '109' },
	{ value: 'LV', label: 'Latvia', id: '120' },
	{ value: 'LT', label: 'Lithuania', id: '126' },
	{ value: 'LU', label: 'Luxembourg', id: '127' },
	{
		value: 'MX',
		label: 'Mexico (only supported for marketplaces within Mexico or the USA)',
		id: '142',
	},
	{ value: 'NL', label: 'Netherlands', id: '155' },
	{ value: 'NZ', label: 'New Zealand', id: '157' },
	{ value: 'NO', label: 'Norway', id: '164' },
	{ value: 'PL', label: 'Poland', id: '175' },
	{ value: 'PT', label: 'Portugal', id: '176' },
	{ value: 'RO', label: 'Romania', id: '180' },
	{ value: 'SG', label: 'Singapore', id: '196' },
	{ value: 'SK', label: 'Slovakia', id: '197' },
	{ value: 'SI', label: 'Slovenia', id: '198' },
	{ value: 'ES', label: 'Spain', id: '205' },
	{ value: 'SE', label: 'Sweden', id: '211' },
	{ value: 'CH', label: 'Switzerland', id: '212' },
	{ value: 'GB', label: 'United Kingdom', id: '230' },
	{ value: 'US', label: 'United States', id: '231' },
];

export const WellnessMenu: any = [
	{ name: 'Massage', value: '5f3b1049324e2a2b8bb04d59' },
	{ name: 'Cryotherapy', value: '5f3b1050324e2a2b8bb04d5a' },
	{ name: 'Sauna', value: '5f3b1052324e2a2b8bb04d5b' },
	{ name: 'Acupuncture', value: '5f3b1056324e2a2b8bb04d5c' },
	{ name: 'Beauty', value: '5f3b1058324e2a2b8bb04d5d' },
	{ name: 'Facial', value: '5f3b105a324e2a2b8bb04d5e' },
	{ name: 'Meditation', value: '5f3b105d324e2a2b8bb04d5f' },
	{ name: 'Cupping', value: '5f3b1063324e2a2b8bb04d60' },
	// "Massage", "Cryotherapy", "Sauna", "Acupuncture", "Beauty", "Facial", "Meditation", "Cupping"
];
export const FitnessMenu: any = [
	{ name: 'Yoga', value: '5f3b1013324e2a2b8bb04d4e' },
	{ name: 'Barre', value: '5f3b1017324e2a2b8bb04d4f' },
	{ name: 'Bootcamp', value: '5f3b101b324e2a2b8bb04d50' },
	{ name: 'Hiit', value: '5f3b1021324e2a2b8bb04d51' },
	{ name: 'Martial Arts', value: '5f3b1027324e2a2b8bb04d52' },
	{ name: 'Cycling', value: '5f3b102b324e2a2b8bb04d53' },
	{ name: 'Running', value: '5f3b102f324e2a2b8bb04d54' },
	{ name: 'Pilates', value: '5f3b1034324e2a2b8bb04d55' },
	{ name: 'Dance', value: '5f3b1037324e2a2b8bb04d56' },
	{ name: 'Boxing', value: '5f3b103c324e2a2b8bb04d57' },
	{ name: 'Outdoors', value: '5f3b1040324e2a2b8bb04d58' },
	// "Yoga", "Barre", "Bootcamp", "Hiit", "Martial Arts", "Cycling", "Running", "Pilates", "Dance", "Boxing", "Outdoors"
];

export const RetreatMenu: any = [
	{
		name: 'Anti- Stress',
		value: 'antiStress',
	},

	{
		name: 'Athletic Training Retreats ',
		value: 'athleticTrainingRetreats',
	},
	{
		name: 'Ayuverda Retreats',
		value: 'ayuverdaRetreats',
	},
	{
		name: 'Boxing Retreats',
		value: 'boxingRetreats',
	},

	{
		name: 'Couples Retreats',
		value: 'couplesRetreats',
	},
	{
		name: 'Detox Retreats',
		value: 'detoxRetreats',
	},
	{
		name: 'Digital Retreats',
		value: 'digitalRetreats',
	},
	{
		name: 'Fitness Retreats',
		value: 'fitnessRetreats',
	},
	{
		name: 'Meditation Retreats',
		value: 'meditationRetreats',
	},
	{
		name: 'Mens Retreat',
		value: 'mensRetreat',
	},
	{
		name: 'Spa Retreat',
		value: 'spaRetreat',
	},
	{
		name: 'Solo Retreat,',
		value: 'soloRetreat,',
	},
	{
		name: 'Surfing Retreats',
		value: 'surfingRetreats',
	},
	{
		name: 'Traditional Chineese Medicine Retreats',
		value: 'traditionalChineeseMedicineRetreats',
	},
	{
		name: 'Urban Retreat',
		value: 'urbanRetreat',
	},
	{
		name: 'Weekend Retreat',
		value: 'weekendRetreat',
	},
	{
		name: 'Weight Loss Retreat',
		value: 'weightLossRetreat',
	},
	{
		name: 'Women’s Retreat',
		value: 'womensRetreat',
	},
	{
		name: 'Yoga Retreat',
		value: 'yogaretreat',
	},
	// "Anti- Stress", "Athletic Training Retreats", "Ayuverda Retreats", "Boxing Retreats", "Couples Retreats", "Detox Retreats", "Digital Retreats", "Fitness Retreats", "Meditation Retreats", "Mens Retreat", "Spa Retreat", "Solo Retreat", "Surfing Retreats", "Traditional Chineese Medicine Retreats", "Urban Retreat", "Weekend Retreat", "Weight Loss Retreat", "Women’s Retreat", "Yoga Retreat"
];

export const HolisticHealthPractitionerMenu: any = [
	{ name: 'Ayurvedic Practitioner', value: '5f3b10e7324e2a2b8bb04d72' },
	{ name: 'Breathwork Teacher', value: '5f3b10ef324e2a2b8bb04d73' },
	{ name: 'Chiropractor', value: '5f3b10f4324e2a2b8bb04d74' },
	{ name: 'Dietitian', value: '5f3b10f8324e2a2b8bb04d75' },
	{ name: 'Functional Medicine', value: '5f3b10fd324e2a2b8bb04d76' },
	{ name: 'Holistic Chef', value: '5f3b110b324e2a2b8bb04d77' },
	{ name: 'Hypnotherapist', value: '5f3b1110324e2a2b8bb04d78' },
	{ name: 'Naturopathic Practitioner', value: '5f3b111a324e2a2b8bb04d79' },
	{ name: 'Sound Healer', value: '5f3b111d324e2a2b8bb04d7a' },
	{ name: 'Energy Healing', value: '5f3b1127324e2a2b8bb04d7b' },
	{ name: 'Reike', value: '5f3b112a324e2a2b8bb04d7c' },
	// "Ayurvedic Practitioner", "Breathwork Teacher", "Chiropractor", "Dietitian", "Functional Medicine", "Holistic Chef", "Holistic Health Coach", "Hypnotherapist", "Naturopathic Practitioner", 'Sound Healer', "Traditional Chinese Medicine", "Energy Healing", "Reike"
];

export const Coach: any = [
	{ name: 'Meditation Coach', value: '5f3b1132324e2a2b8bb04d7d' },
	{ name: 'Life Coach', value: '5f3b1135324e2a2b8bb04d7e' },
	{ name: 'Career Coach', value: '5f3b1139324e2a2b8bb04d7f' },
	{ name: 'Nutrition Coach', value: '5f3b113d324e2a2b8bb04d80' },
	{ name: 'Fitness Coach', value: '5f3b1141324e2a2b8bb04d81' },
	{ name: 'Relationship Coach', value: '5f3b1148324e2a2b8bb04d82' },
	{ name: 'Spiritual Coach', value: '5f3b114c324e2a2b8bb04d83' },
	{ name: 'Yoga Instructure', value: '5f3b1151324e2a2b8bb04d84' },
	// "Meditation Coach", "Life Coach", "Career Coach", "Nutrition Coach", "Fitness Coach", "Relationship Coach", "Spiritual Coach", "Yoga Instructure"
];

export const Therapist: any = [
	{ name: 'Holistic Therapist', value: '5f3b1158324e2a2b8bb04d85' },
	{ name: 'Holistic Therapist', value: '5f3b115f324e2a2b8bb04d86' },
	{ name: 'Psychotherapy', value: '5f3b1175324e2a2b8bb04d87' },
	// "Holistic Therapist", "Massage Therapist", "Myofascial Therapist", "Psychotherapy"
];

export const TimeZoneSchema = [
	{
		timezones: ['America/Aruba'],
		latlng: [12.5, -69.96666666],
		name: 'Aruba',
		country_code: 'AW',
		capital: 'Oranjestad',
	},
	{
		timezones: ['Asia/Kabul'],
		latlng: [33, 65],
		name: 'Afghanistan',
		country_code: 'AF',
		capital: 'Kabul',
	},
	{
		timezones: ['Africa/Luanda'],
		latlng: [-12.5, 18.5],
		name: 'Angola',
		country_code: 'AO',
		capital: 'Luanda',
	},
	{
		timezones: ['America/Anguilla'],
		latlng: [18.25, -63.16666666],
		name: 'Anguilla',
		country_code: 'AI',
		capital: 'The Valley',
	},
	{
		timezones: ['Europe/Mariehamn'],
		latlng: [60.116667, 19.9],
		name: '\u00c5land Islands',
		country_code: 'AX',
		capital: 'Mariehamn',
	},
	{
		timezones: ['Europe/Tirane'],
		latlng: [41, 20],
		name: 'Albania',
		country_code: 'AL',
		capital: 'Tirana',
	},
	{
		timezones: ['Europe/Andorra'],
		latlng: [42.5, 1.5],
		name: 'Andorra',
		country_code: 'AD',
		capital: 'Andorra la Vella',
	},
	{
		timezones: ['Asia/Dubai'],
		latlng: [24, 54],
		name: 'United Arab Emirates',
		country_code: 'AE',
		capital: 'Abu Dhabi',
	},
	{
		timezones: [
			'America/Argentina/Buenos_Aires',
			'America/Argentina/Cordoba',
			'America/Argentina/Salta',
			'America/Argentina/Jujuy',
			'America/Argentina/Tucuman',
			'America/Argentina/Catamarca',
			'America/Argentina/La_Rioja',
			'America/Argentina/San_Juan',
			'America/Argentina/Mendoza',
			'America/Argentina/San_Luis',
			'America/Argentina/Rio_Gallegos',
			'America/Argentina/Ushuaia',
		],
		latlng: [-34, -64],
		name: 'Argentina',
		country_code: 'AR',
		capital: 'Buenos Aires',
	},
	{
		timezones: ['Asia/Yerevan'],
		latlng: [40, 45],
		name: 'Armenia',
		country_code: 'AM',
		capital: 'Yerevan',
	},
	{
		timezones: ['Pacific/Pago_Pago'],
		latlng: [-14.33333333, -170],
		name: 'American Samoa',
		country_code: 'AS',
		capital: 'Pago Pago',
	},
	{
		timezones: [
			'Antarctica/McMurdo',
			'Antarctica/Casey',
			'Antarctica/Davis',
			'Antarctica/DumontDUrville',
			'Antarctica/Mawson',
			'Antarctica/Palmer',
			'Antarctica/Rothera',
			'Antarctica/Syowa',
			'Antarctica/Troll',
			'Antarctica/Vostok',
		],
		latlng: [-90, 0],
		name: 'Antarctica',
		country_code: 'AQ',
		capital: null,
	},
	{
		timezones: ['Indian/Kerguelen'],
		latlng: [-49.25, 69.167],
		name: 'French Southern and Antarctic Lands',
		country_code: 'TF',
		capital: 'Port-aux-Fran\u00e7ais',
	},
	{
		timezones: ['America/Antigua'],
		latlng: [17.05, -61.8],
		name: 'Antigua and Barbuda',
		country_code: 'AG',
		capital: "Saint John's",
	},
	{
		timezones: [
			'Australia/Lord_Howe',
			'Antarctica/Macquarie',
			'Australia/Hobart',
			'Australia/Currie',
			'Australia/Melbourne',
			'Australia/Sydney',
			'Australia/Broken_Hill',
			'Australia/Brisbane',
			'Australia/Lindeman',
			'Australia/Adelaide',
			'Australia/Darwin',
			'Australia/Perth',
			'Australia/Eucla',
		],
		latlng: [-27, 133],
		name: 'Australia',
		country_code: 'AU',
		capital: 'Canberra',
	},
	{
		timezones: ['Europe/Vienna'],
		latlng: [47.33333333, 13.33333333],
		name: 'Austria',
		country_code: 'AT',
		capital: 'Vienna',
	},
	{
		timezones: ['Asia/Baku'],
		latlng: [40.5, 47.5],
		name: 'Azerbaijan',
		country_code: 'AZ',
		capital: 'Baku',
	},
	{
		timezones: ['Africa/Bujumbura'],
		latlng: [-3.5, 30],
		name: 'Burundi',
		country_code: 'BI',
		capital: 'Bujumbura',
	},
	{
		timezones: ['Europe/Brussels'],
		latlng: [50.83333333, 4],
		name: 'Belgium',
		country_code: 'BE',
		capital: 'Brussels',
	},
	{
		timezones: ['Africa/Porto-Novo'],
		latlng: [9.5, 2.25],
		name: 'Benin',
		country_code: 'BJ',
		capital: 'Porto-Novo',
	},
	{
		timezones: ['Africa/Ouagadougou'],
		latlng: [13, -2],
		name: 'Burkina Faso',
		country_code: 'BF',
		capital: 'Ouagadougou',
	},
	{
		timezones: ['Asia/Dhaka'],
		latlng: [24, 90],
		name: 'Bangladesh',
		country_code: 'BD',
		capital: 'Dhaka',
	},
	{
		timezones: ['Europe/Sofia'],
		latlng: [43, 25],
		name: 'Bulgaria',
		country_code: 'BG',
		capital: 'Sofia',
	},
	{
		timezones: ['Asia/Bahrain'],
		latlng: [26, 50.55],
		name: 'Bahrain',
		country_code: 'BH',
		capital: 'Manama',
	},
	{
		timezones: ['America/Nassau'],
		latlng: [24.25, -76],
		name: 'Bahamas',
		country_code: 'BS',
		capital: 'Nassau',
	},
	{
		timezones: ['Europe/Sarajevo'],
		latlng: [44, 18],
		name: 'Bosnia and Herzegovina',
		country_code: 'BA',
		capital: 'Sarajevo',
	},
	{
		timezones: ['America/St_Barthelemy'],
		latlng: [18.5, -63.41666666],
		name: 'Saint Barth\u00e9lemy',
		country_code: 'BL',
		capital: 'Gustavia',
	},
	{
		timezones: ['Europe/Minsk'],
		latlng: [53, 28],
		name: 'Belarus',
		country_code: 'BY',
		capital: 'Minsk',
	},
	{
		timezones: ['America/Belize'],
		latlng: [17.25, -88.75],
		name: 'Belize',
		country_code: 'BZ',
		capital: 'Belmopan',
	},
	{
		timezones: ['Atlantic/Bermuda'],
		latlng: [32.33333333, -64.75],
		name: 'Bermuda',
		country_code: 'BM',
		capital: 'Hamilton',
	},
	{
		timezones: ['America/La_Paz'],
		latlng: [-17, -65],
		name: 'Bolivia',
		country_code: 'BO',
		capital: 'Sucre',
	},
	{
		timezones: [
			'America/Noronha',
			'America/Belem',
			'America/Fortaleza',
			'America/Recife',
			'America/Araguaina',
			'America/Maceio',
			'America/Bahia',
			'America/Sao_Paulo',
			'America/Campo_Grande',
			'America/Cuiaba',
			'America/Santarem',
			'America/Porto_Velho',
			'America/Boa_Vista',
			'America/Manaus',
			'America/Eirunepe',
			'America/Rio_Branco',
		],
		latlng: [-10, -55],
		name: 'Brazil',
		country_code: 'BR',
		capital: 'Bras\u00edlia',
	},
	{
		timezones: ['America/Barbados'],
		latlng: [13.16666666, -59.53333333],
		name: 'Barbados',
		country_code: 'BB',
		capital: 'Bridgetown',
	},
	{
		timezones: ['Asia/Brunei'],
		latlng: [4.5, 114.66666666],
		name: 'Brunei',
		country_code: 'BN',
		capital: 'Bandar Seri Begawan',
	},
	{
		timezones: ['Asia/Thimphu'],
		latlng: [27.5, 90.5],
		name: 'Bhutan',
		country_code: 'BT',
		capital: 'Thimphu',
	},
	{
		timezones: ['Europe/Oslo'],
		latlng: [-54.43333333, 3.4],
		name: 'Bouvet Island',
		country_code: 'BV',
		capital: null,
	},
	{
		timezones: ['Africa/Gaborone'],
		latlng: [-22, 24],
		name: 'Botswana',
		country_code: 'BW',
		capital: 'Gaborone',
	},
	{
		timezones: ['Africa/Bangui'],
		latlng: [7, 21],
		name: 'Central African Republic',
		country_code: 'CF',
		capital: 'Bangui',
	},
	{
		timezones: [
			'America/St_Johns',
			'America/Halifax',
			'America/Glace_Bay',
			'America/Moncton',
			'America/Goose_Bay',
			'America/Blanc-Sablon',
			'America/Toronto',
			'America/Nipigon',
			'America/Thunder_Bay',
			'America/Iqaluit',
			'America/Pangnirtung',
			'America/Atikokan',
			'America/Winnipeg',
			'America/Rainy_River',
			'America/Resolute',
			'America/Rankin_Inlet',
			'America/Regina',
			'America/Swift_Current',
			'America/Edmonton',
			'America/Cambridge_Bay',
			'America/Yellowknife',
			'America/Inuvik',
			'America/Creston',
			'America/Dawson_Creek',
			'America/Fort_Nelson',
			'America/Vancouver',
			'America/Whitehorse',
			'America/Dawson',
		],
		latlng: [60, -95],
		name: 'Canada',
		country_code: 'CA',
		capital: 'Ottawa',
	},
	{
		timezones: ['Indian/Cocos'],
		latlng: [-12.5, 96.83333333],
		name: 'Cocos (Keeling) Islands',
		country_code: 'CC',
		capital: 'West Island',
	},
	{
		timezones: ['Europe/Zurich'],
		latlng: [47, 8],
		name: 'Switzerland',
		country_code: 'CH',
		capital: 'Bern',
	},
	{
		timezones: ['America/Santiago', 'Pacific/Easter'],
		latlng: [-30, -71],
		name: 'Chile',
		country_code: 'CL',
		capital: 'Santiago',
	},
	{
		timezones: ['Asia/Shanghai', 'Asia/Urumqi'],
		latlng: [35, 105],
		name: 'China',
		country_code: 'CN',
		capital: 'Beijing',
	},
	{
		timezones: ['Africa/Abidjan'],
		latlng: [8, -5],
		name: 'Ivory Coast',
		country_code: 'CI',
		capital: 'Yamoussoukro',
	},
	{
		timezones: ['Africa/Douala'],
		latlng: [6, 12],
		name: 'Cameroon',
		country_code: 'CM',
		capital: 'Yaound\u00e9',
	},
	{
		timezones: ['Africa/Kinshasa', 'Africa/Lubumbashi'],
		latlng: [0, 25],
		name: 'DR Congo',
		country_code: 'CD',
		capital: 'Kinshasa',
	},
	{
		timezones: ['Africa/Brazzaville'],
		latlng: [-1, 15],
		name: 'Republic of the Congo',
		country_code: 'CG',
		capital: 'Brazzaville',
	},
	{
		timezones: ['Pacific/Rarotonga'],
		latlng: [-21.23333333, -159.76666666],
		name: 'Cook Islands',
		country_code: 'CK',
		capital: 'Avarua',
	},
	{
		timezones: ['America/Bogota'],
		latlng: [4, -72],
		name: 'Colombia',
		country_code: 'CO',
		capital: 'Bogot\u00e1',
	},
	{
		timezones: ['Indian/Comoro'],
		latlng: [-12.16666666, 44.25],
		name: 'Comoros',
		country_code: 'KM',
		capital: 'Moroni',
	},
	{
		timezones: ['Atlantic/Cape_Verde'],
		latlng: [16, -24],
		name: 'Cape Verde',
		country_code: 'CV',
		capital: 'Praia',
	},
	{
		timezones: ['America/Costa_Rica'],
		latlng: [10, -84],
		name: 'Costa Rica',
		country_code: 'CR',
		capital: 'San Jos\u00e9',
	},
	{
		timezones: ['America/Havana'],
		latlng: [21.5, -80],
		name: 'Cuba',
		country_code: 'CU',
		capital: 'Havana',
	},
	{
		timezones: ['America/Curacao'],
		latlng: [12.116667, -68.933333],
		name: 'Cura\u00e7ao',
		country_code: 'CW',
		capital: 'Willemstad',
	},
	{
		timezones: ['Indian/Christmas'],
		latlng: [-10.5, 105.66666666],
		name: 'Christmas Island',
		country_code: 'CX',
		capital: 'Flying Fish Cove',
	},
	{
		timezones: ['America/Cayman'],
		latlng: [19.5, -80.5],
		name: 'Cayman Islands',
		country_code: 'KY',
		capital: 'George Town',
	},
	{
		timezones: ['Asia/Nicosia'],
		latlng: [35, 33],
		name: 'Cyprus',
		country_code: 'CY',
		capital: 'Nicosia',
	},
	{
		timezones: ['Europe/Prague'],
		latlng: [49.75, 15.5],
		name: 'Czech Republic',
		country_code: 'CZ',
		capital: 'Prague',
	},
	{
		timezones: ['Europe/Berlin', 'Europe/Busingen'],
		latlng: [51, 9],
		name: 'Germany',
		country_code: 'DE',
		capital: 'Berlin',
	},
	{
		timezones: ['Africa/Djibouti'],
		latlng: [11.5, 43],
		name: 'Djibouti',
		country_code: 'DJ',
		capital: 'Djibouti',
	},
	{
		timezones: ['America/Dominica'],
		latlng: [15.41666666, -61.33333333],
		name: 'Dominica',
		country_code: 'DM',
		capital: 'Roseau',
	},
	{
		timezones: ['Europe/Copenhagen'],
		latlng: [56, 10],
		name: 'Denmark',
		country_code: 'DK',
		capital: 'Copenhagen',
	},
	{
		timezones: ['America/Santo_Domingo'],
		latlng: [19, -70.66666666],
		name: 'Dominican Republic',
		country_code: 'DO',
		capital: 'Santo Domingo',
	},
	{
		timezones: ['Africa/Algiers'],
		latlng: [28, 3],
		name: 'Algeria',
		country_code: 'DZ',
		capital: 'Algiers',
	},
	{
		timezones: ['America/Guayaquil', 'Pacific/Galapagos'],
		latlng: [-2, -77.5],
		name: 'Ecuador',
		country_code: 'EC',
		capital: 'Quito',
	},
	{
		timezones: ['Africa/Cairo'],
		latlng: [27, 30],
		name: 'Egypt',
		country_code: 'EG',
		capital: 'Cairo',
	},
	{
		timezones: ['Africa/Asmara'],
		latlng: [15, 39],
		name: 'Eritrea',
		country_code: 'ER',
		capital: 'Asmara',
	},
	{
		timezones: ['Africa/El_Aaiun'],
		latlng: [24.5, -13],
		name: 'Western Sahara',
		country_code: 'EH',
		capital: 'El Aai\u00fan',
	},
	{
		timezones: ['Europe/Madrid', 'Africa/Ceuta', 'Atlantic/Canary'],
		latlng: [40, -4],
		name: 'Spain',
		country_code: 'ES',
		capital: 'Madrid',
	},
	{
		timezones: ['Europe/Tallinn'],
		latlng: [59, 26],
		name: 'Estonia',
		country_code: 'EE',
		capital: 'Tallinn',
	},
	{
		timezones: ['Africa/Addis_Ababa'],
		latlng: [8, 38],
		name: 'Ethiopia',
		country_code: 'ET',
		capital: 'Addis Ababa',
	},
	{
		timezones: ['Europe/Helsinki'],
		latlng: [64, 26],
		name: 'Finland',
		country_code: 'FI',
		capital: 'Helsinki',
	},
	{
		timezones: ['Pacific/Fiji'],
		latlng: [-18, 175],
		name: 'Fiji',
		country_code: 'FJ',
		capital: 'Suva',
	},
	{
		timezones: ['Atlantic/Stanley'],
		latlng: [-51.75, -59],
		name: 'Falkland Islands',
		country_code: 'FK',
		capital: 'Stanley',
	},
	{
		timezones: ['Europe/Paris'],
		latlng: [46, 2],
		name: 'France',
		country_code: 'FR',
		capital: 'Paris',
	},
	{
		timezones: ['Atlantic/Faroe'],
		latlng: [62, -7],
		name: 'Faroe Islands',
		country_code: 'FO',
		capital: 'T\u00f3rshavn',
	},
	{
		timezones: ['Pacific/Chuuk', 'Pacific/Pohnpei', 'Pacific/Kosrae'],
		latlng: [6.91666666, 158.25],
		name: 'Micronesia',
		country_code: 'FM',
		capital: 'Palikir',
	},
	{
		timezones: ['Africa/Libreville'],
		latlng: [-1, 11.75],
		name: 'Gabon',
		country_code: 'GA',
		capital: 'Libreville',
	},
	{
		timezones: ['Europe/London'],
		latlng: [54, -2],
		name: 'United Kingdom',
		country_code: 'GB',
		capital: 'London',
	},
	{
		timezones: ['Asia/Tbilisi'],
		latlng: [42, 43.5],
		name: 'Georgia',
		country_code: 'GE',
		capital: 'Tbilisi',
	},
	{
		timezones: ['Europe/Guernsey'],
		latlng: [49.46666666, -2.58333333],
		name: 'Guernsey',
		country_code: 'GG',
		capital: 'St. Peter Port',
	},
	{
		timezones: ['Africa/Accra'],
		latlng: [8, -2],
		name: 'Ghana',
		country_code: 'GH',
		capital: 'Accra',
	},
	{
		timezones: ['Europe/Gibraltar'],
		latlng: [36.13333333, -5.35],
		name: 'Gibraltar',
		country_code: 'GI',
		capital: 'Gibraltar',
	},
	{
		timezones: ['Africa/Conakry'],
		latlng: [11, -10],
		name: 'Guinea',
		country_code: 'GN',
		capital: 'Conakry',
	},
	{
		timezones: ['America/Guadeloupe'],
		latlng: [16.25, -61.583333],
		name: 'Guadeloupe',
		country_code: 'GP',
		capital: 'Basse-Terre',
	},
	{
		timezones: ['Africa/Banjul'],
		latlng: [13.46666666, -16.56666666],
		name: 'Gambia',
		country_code: 'GM',
		capital: 'Banjul',
	},
	{
		timezones: ['Africa/Bissau'],
		latlng: [12, -15],
		name: 'Guinea-Bissau',
		country_code: 'GW',
		capital: 'Bissau',
	},
	{
		timezones: ['Africa/Malabo'],
		latlng: [2, 10],
		name: 'Equatorial Guinea',
		country_code: 'GQ',
		capital: 'Malabo',
	},
	{
		timezones: ['Europe/Athens'],
		latlng: [39, 22],
		name: 'Greece',
		country_code: 'GR',
		capital: 'Athens',
	},
	{
		timezones: ['America/Grenada'],
		latlng: [12.11666666, -61.66666666],
		name: 'Grenada',
		country_code: 'GD',
		capital: "St. George's",
	},
	{
		timezones: [
			'America/Godthab',
			'America/Danmarkshavn',
			'America/Scoresbysund',
			'America/Thule',
		],
		latlng: [72, -40],
		name: 'Greenland',
		country_code: 'GL',
		capital: 'Nuuk',
	},
	{
		timezones: ['America/Guatemala'],
		latlng: [15.5, -90.25],
		name: 'Guatemala',
		country_code: 'GT',
		capital: 'Guatemala City',
	},
	{
		timezones: ['America/Cayenne'],
		latlng: [4, -53],
		name: 'French Guiana',
		country_code: 'GF',
		capital: 'Cayenne',
	},
	{
		timezones: ['Pacific/Guam'],
		latlng: [13.46666666, 144.78333333],
		name: 'Guam',
		country_code: 'GU',
		capital: 'Hag\u00e5t\u00f1a',
	},
	{
		timezones: ['America/Guyana'],
		latlng: [5, -59],
		name: 'Guyana',
		country_code: 'GY',
		capital: 'Georgetown',
	},
	{
		timezones: ['Asia/Hong_Kong'],
		latlng: [22.267, 114.188],
		name: 'Hong Kong',
		country_code: 'HK',
		capital: 'City of Victoria',
	},
	{
		timezones: ['America/Tegucigalpa'],
		latlng: [15, -86.5],
		name: 'Honduras',
		country_code: 'HN',
		capital: 'Tegucigalpa',
	},
	{
		timezones: ['Europe/Zagreb'],
		latlng: [45.16666666, 15.5],
		name: 'Croatia',
		country_code: 'HR',
		capital: 'Zagreb',
	},
	{
		timezones: ['America/Port-au-Prince'],
		latlng: [19, -72.41666666],
		name: 'Haiti',
		country_code: 'HT',
		capital: 'Port-au-Prince',
	},
	{
		timezones: ['Europe/Budapest'],
		latlng: [47, 20],
		name: 'Hungary',
		country_code: 'HU',
		capital: 'Budapest',
	},
	{
		timezones: [
			'Asia/Jakarta',
			'Asia/Pontianak',
			'Asia/Makassar',
			'Asia/Jayapura',
		],
		latlng: [-5, 120],
		name: 'Indonesia',
		country_code: 'ID',
		capital: 'Jakarta',
	},
	{
		timezones: ['Europe/Isle_of_Man'],
		latlng: [54.25, -4.5],
		name: 'Isle of Man',
		country_code: 'IM',
		capital: 'Douglas',
	},
	{
		timezones: ['Asia/Calcutta'],
		latlng: [20, 77],
		name: 'India',
		country_code: 'IN',
		capital: 'New Delhi',
	},
	{
		timezones: ['Indian/Chagos'],
		latlng: [-6, 71.5],
		name: 'British Indian Ocean Territory',
		country_code: 'IO',
		capital: 'Diego Garcia',
	},
	{
		timezones: ['Europe/Dublin'],
		latlng: [53, -8],
		name: 'Ireland',
		country_code: 'IE',
		capital: 'Dublin',
	},
	{
		timezones: ['Asia/Tehran'],
		latlng: [32, 53],
		name: 'Iran',
		country_code: 'IR',
		capital: 'Tehran',
	},
	{
		timezones: ['Asia/Baghdad'],
		latlng: [33, 44],
		name: 'Iraq',
		country_code: 'IQ',
		capital: 'Baghdad',
	},
	{
		timezones: ['Atlantic/Reykjavik'],
		latlng: [65, -18],
		name: 'Iceland',
		country_code: 'IS',
		capital: 'Reykjavik',
	},
	{
		timezones: ['Asia/Jerusalem'],
		latlng: [31.47, 35.13],
		name: 'Israel',
		country_code: 'IL',
		capital: 'Jerusalem',
	},
	{
		timezones: ['Europe/Rome'],
		latlng: [42.83333333, 12.83333333],
		name: 'Italy',
		country_code: 'IT',
		capital: 'Rome',
	},
	{
		timezones: ['America/Jamaica'],
		latlng: [18.25, -77.5],
		name: 'Jamaica',
		country_code: 'JM',
		capital: 'Kingston',
	},
	{
		timezones: ['Europe/Jersey'],
		latlng: [49.25, -2.16666666],
		name: 'Jersey',
		country_code: 'JE',
		capital: 'Saint Helier',
	},
	{
		timezones: ['Asia/Amman'],
		latlng: [31, 36],
		name: 'Jordan',
		country_code: 'JO',
		capital: 'Amman',
	},
	{
		timezones: ['Asia/Tokyo'],
		latlng: [36, 138],
		name: 'Japan',
		country_code: 'JP',
		capital: 'Tokyo',
	},
	{
		timezones: [
			'Asia/Almaty',
			'Asia/Qyzylorda',
			'Asia/Aqtobe',
			'Asia/Aqtau',
			'Asia/Oral',
		],
		latlng: [48, 68],
		name: 'Kazakhstan',
		country_code: 'KZ',
		capital: 'Astana',
	},
	{
		timezones: ['Africa/Nairobi'],
		latlng: [1, 38],
		name: 'Kenya',
		country_code: 'KE',
		capital: 'Nairobi',
	},
	{
		timezones: ['Asia/Bishkek'],
		latlng: [41, 75],
		name: 'Kyrgyzstan',
		country_code: 'KG',
		capital: 'Bishkek',
	},
	{
		timezones: ['Asia/Phnom_Penh'],
		latlng: [13, 105],
		name: 'Cambodia',
		country_code: 'KH',
		capital: 'Phnom Penh',
	},
	{
		timezones: ['Pacific/Tarawa', 'Pacific/Enderbury', 'Pacific/Kiritimati'],
		latlng: [1.41666666, 173],
		name: 'Kiribati',
		country_code: 'KI',
		capital: 'South Tarawa',
	},
	{
		timezones: ['America/St_Kitts'],
		latlng: [17.33333333, -62.75],
		name: 'Saint Kitts and Nevis',
		country_code: 'KN',
		capital: 'Basseterre',
	},
	{
		timezones: ['Asia/Seoul'],
		latlng: [37, 127.5],
		name: 'South Korea',
		country_code: 'KR',
		capital: 'Seoul',
	},
	{
		timezones: ['Europe/Belgrade'],
		latlng: [42.666667, 21.166667],
		name: 'Kosovo',
		country_code: 'XK',
		capital: 'Pristina',
	},
	{
		timezones: ['Asia/Kuwait'],
		latlng: [29.5, 45.75],
		name: 'Kuwait',
		country_code: 'KW',
		capital: 'Kuwait City',
	},
	{
		timezones: ['Asia/Vientiane'],
		latlng: [18, 105],
		name: 'Laos',
		country_code: 'LA',
		capital: 'Vientiane',
	},
	{
		timezones: ['Asia/Beirut'],
		latlng: [33.83333333, 35.83333333],
		name: 'Lebanon',
		country_code: 'LB',
		capital: 'Beirut',
	},
	{
		timezones: ['Africa/Monrovia'],
		latlng: [6.5, -9.5],
		name: 'Liberia',
		country_code: 'LR',
		capital: 'Monrovia',
	},
	{
		timezones: ['Africa/Tripoli'],
		latlng: [25, 17],
		name: 'Libya',
		country_code: 'LY',
		capital: 'Tripoli',
	},
	{
		timezones: ['America/St_Lucia'],
		latlng: [13.88333333, -60.96666666],
		name: 'Saint Lucia',
		country_code: 'LC',
		capital: 'Castries',
	},
	{
		timezones: ['Europe/Vaduz'],
		latlng: [47.26666666, 9.53333333],
		name: 'Liechtenstein',
		country_code: 'LI',
		capital: 'Vaduz',
	},
	{
		timezones: ['Asia/Colombo'],
		latlng: [7, 81],
		name: 'Sri Lanka',
		country_code: 'LK',
		capital: 'Colombo',
	},
	{
		timezones: ['Africa/Maseru'],
		latlng: [-29.5, 28.5],
		name: 'Lesotho',
		country_code: 'LS',
		capital: 'Maseru',
	},
	{
		timezones: ['Europe/Vilnius'],
		latlng: [56, 24],
		name: 'Lithuania',
		country_code: 'LT',
		capital: 'Vilnius',
	},
	{
		timezones: ['Europe/Luxembourg'],
		latlng: [49.75, 6.16666666],
		name: 'Luxembourg',
		country_code: 'LU',
		capital: 'Luxembourg',
	},
	{
		timezones: ['Europe/Riga'],
		latlng: [57, 25],
		name: 'Latvia',
		country_code: 'LV',
		capital: 'Riga',
	},
	{
		timezones: ['Asia/Macau'],
		latlng: [22.16666666, 113.55],
		name: 'Macau',
		country_code: 'MO',
		capital: null,
	},
	{
		timezones: ['America/Marigot'],
		latlng: [18.08333333, -63.95],
		name: 'Saint Martin',
		country_code: 'MF',
		capital: 'Marigot',
	},
	{
		timezones: ['Africa/Casablanca'],
		latlng: [32, -5],
		name: 'Morocco',
		country_code: 'MA',
		capital: 'Rabat',
	},
	{
		timezones: ['Europe/Monaco'],
		latlng: [43.73333333, 7.4],
		name: 'Monaco',
		country_code: 'MC',
		capital: 'Monaco',
	},
	{
		timezones: ['Europe/Chisinau'],
		latlng: [47, 29],
		name: 'Moldova',
		country_code: 'MD',
		capital: 'Chi\u0219in\u0103u',
	},
	{
		timezones: ['Indian/Antananarivo'],
		latlng: [-20, 47],
		name: 'Madagascar',
		country_code: 'MG',
		capital: 'Antananarivo',
	},
	{
		timezones: ['Indian/Maldives'],
		latlng: [3.25, 73],
		name: 'Maldives',
		country_code: 'MV',
		capital: 'Mal\u00e9',
	},
	{
		timezones: [
			'America/Mexico_City',
			'America/Cancun',
			'America/Merida',
			'America/Monterrey',
			'America/Matamoros',
			'America/Mazatlan',
			'America/Chihuahua',
			'America/Ojinaga',
			'America/Hermosillo',
			'America/Tijuana',
			'America/Bahia_Banderas',
		],
		latlng: [23, -102],
		name: 'Mexico',
		country_code: 'MX',
		capital: 'Mexico City',
	},
	{
		timezones: ['Pacific/Majuro', 'Pacific/Kwajalein'],
		latlng: [9, 168],
		name: 'Marshall Islands',
		country_code: 'MH',
		capital: 'Majuro',
	},
	{
		timezones: ['Europe/Skopje'],
		latlng: [41.83333333, 22],
		name: 'Macedonia',
		country_code: 'MK',
		capital: 'Skopje',
	},
	{
		timezones: ['Africa/Bamako'],
		latlng: [17, -4],
		name: 'Mali',
		country_code: 'ML',
		capital: 'Bamako',
	},
	{
		timezones: ['Europe/Malta'],
		latlng: [35.83333333, 14.58333333],
		name: 'Malta',
		country_code: 'MT',
		capital: 'Valletta',
	},
	{
		timezones: ['Asia/Rangoon'],
		latlng: [22, 98],
		name: 'Myanmar',
		country_code: 'MM',
		capital: 'Naypyidaw',
	},
	{
		timezones: ['Europe/Podgorica'],
		latlng: [42.5, 19.3],
		name: 'Montenegro',
		country_code: 'ME',
		capital: 'Podgorica',
	},
	{
		timezones: ['Asia/Ulaanbaatar', 'Asia/Hovd', 'Asia/Choibalsan'],
		latlng: [46, 105],
		name: 'Mongolia',
		country_code: 'MN',
		capital: 'Ulan Bator',
	},
	{
		timezones: ['Pacific/Saipan'],
		latlng: [15.2, 145.75],
		name: 'Northern Mariana Islands',
		country_code: 'MP',
		capital: 'Saipan',
	},
	{
		timezones: ['Africa/Maputo'],
		latlng: [-18.25, 35],
		name: 'Mozambique',
		country_code: 'MZ',
		capital: 'Maputo',
	},
	{
		timezones: ['Africa/Nouakchott'],
		latlng: [20, -12],
		name: 'Mauritania',
		country_code: 'MR',
		capital: 'Nouakchott',
	},
	{
		timezones: ['America/Montserrat'],
		latlng: [16.75, -62.2],
		name: 'Montserrat',
		country_code: 'MS',
		capital: 'Plymouth',
	},
	{
		timezones: ['America/Martinique'],
		latlng: [14.666667, -61],
		name: 'Martinique',
		country_code: 'MQ',
		capital: 'Fort-de-France',
	},
	{
		timezones: ['Indian/Mauritius'],
		latlng: [-20.28333333, 57.55],
		name: 'Mauritius',
		country_code: 'MU',
		capital: 'Port Louis',
	},
	{
		timezones: ['Africa/Blantyre'],
		latlng: [-13.5, 34],
		name: 'Malawi',
		country_code: 'MW',
		capital: 'Lilongwe',
	},
	{
		timezones: ['Asia/Kuala_Lumpur', 'Asia/Kuching'],
		latlng: [2.5, 112.5],
		name: 'Malaysia',
		country_code: 'MY',
		capital: 'Kuala Lumpur',
	},
	{
		timezones: ['Indian/Mayotte'],
		latlng: [-12.83333333, 45.16666666],
		name: 'Mayotte',
		country_code: 'YT',
		capital: 'Mamoudzou',
	},
	{
		timezones: ['Africa/Windhoek'],
		latlng: [-22, 17],
		name: 'Namibia',
		country_code: 'NA',
		capital: 'Windhoek',
	},
	{
		timezones: ['Pacific/Noumea'],
		latlng: [-21.5, 165.5],
		name: 'New Caledonia',
		country_code: 'NC',
		capital: 'Noum\u00e9a',
	},
	{
		timezones: ['Africa/Niamey'],
		latlng: [16, 8],
		name: 'Niger',
		country_code: 'NE',
		capital: 'Niamey',
	},
	{
		timezones: ['Pacific/Norfolk'],
		latlng: [-29.03333333, 167.95],
		name: 'Norfolk Island',
		country_code: 'NF',
		capital: 'Kingston',
	},
	{
		timezones: ['Africa/Lagos'],
		latlng: [10, 8],
		name: 'Nigeria',
		country_code: 'NG',
		capital: 'Abuja',
	},
	{
		timezones: ['America/Managua'],
		latlng: [13, -85],
		name: 'Nicaragua',
		country_code: 'NI',
		capital: 'Managua',
	},
	{
		timezones: ['Pacific/Niue'],
		latlng: [-19.03333333, -169.86666666],
		name: 'Niue',
		country_code: 'NU',
		capital: 'Alofi',
	},
	{
		timezones: ['Europe/Amsterdam'],
		latlng: [52.5, 5.75],
		name: 'Netherlands',
		country_code: 'NL',
		capital: 'Amsterdam',
	},
	{
		timezones: ['Europe/Oslo'],
		latlng: [62, 10],
		name: 'Norway',
		country_code: 'NO',
		capital: 'Oslo',
	},
	{
		timezones: ['Asia/Kathmandu'],
		latlng: [28, 84],
		name: 'Nepal',
		country_code: 'NP',
		capital: 'Kathmandu',
	},
	{
		timezones: ['Pacific/Nauru'],
		latlng: [-0.53333333, 166.91666666],
		name: 'Nauru',
		country_code: 'NR',
		capital: 'Yaren',
	},
	{
		timezones: ['Pacific/Auckland', 'Pacific/Chatham'],
		latlng: [-41, 174],
		name: 'New Zealand',
		country_code: 'NZ',
		capital: 'Wellington',
	},
	{
		timezones: ['Asia/Muscat'],
		latlng: [21, 57],
		name: 'Oman',
		country_code: 'OM',
		capital: 'Muscat',
	},
	{
		timezones: ['Asia/Karachi'],
		latlng: [30, 70],
		name: 'Pakistan',
		country_code: 'PK',
		capital: 'Islamabad',
	},
	{
		timezones: ['America/Panama'],
		latlng: [9, -80],
		name: 'Panama',
		country_code: 'PA',
		capital: 'Panama City',
	},
	{
		timezones: ['Pacific/Pitcairn'],
		latlng: [-25.06666666, -130.1],
		name: 'Pitcairn Islands',
		country_code: 'PN',
		capital: 'Adamstown',
	},
	{
		timezones: ['America/Lima'],
		latlng: [-10, -76],
		name: 'Peru',
		country_code: 'PE',
		capital: 'Lima',
	},
	{
		timezones: ['Asia/Manila'],
		latlng: [13, 122],
		name: 'Philippines',
		country_code: 'PH',
		capital: 'Manila',
	},
	{
		timezones: ['Pacific/Palau'],
		latlng: [7.5, 134.5],
		name: 'Palau',
		country_code: 'PW',
		capital: 'Ngerulmud',
	},
	{
		timezones: ['Pacific/Port_Moresby', 'Pacific/Bougainville'],
		latlng: [-6, 147],
		name: 'Papua New Guinea',
		country_code: 'PG',
		capital: 'Port Moresby',
	},
	{
		timezones: ['Europe/Warsaw'],
		latlng: [52, 20],
		name: 'Poland',
		country_code: 'PL',
		capital: 'Warsaw',
	},
	{
		timezones: ['America/Puerto_Rico'],
		latlng: [18.25, -66.5],
		name: 'Puerto Rico',
		country_code: 'PR',
		capital: 'San Juan',
	},
	{
		timezones: ['Asia/Pyongyang'],
		latlng: [40, 127],
		name: 'North Korea',
		country_code: 'KP',
		capital: 'Pyongyang',
	},
	{
		timezones: ['Europe/Lisbon', 'Atlantic/Madeira', 'Atlantic/Azores'],
		latlng: [39.5, -8],
		name: 'Portugal',
		country_code: 'PT',
		capital: 'Lisbon',
	},
	{
		timezones: ['America/Asuncion'],
		latlng: [-23, -58],
		name: 'Paraguay',
		country_code: 'PY',
		capital: 'Asunci\u00f3n',
	},
	{
		timezones: ['Asia/Gaza', 'Asia/Hebron'],
		latlng: [31.9, 35.2],
		name: 'Palestine',
		country_code: 'PS',
		capital: 'Ramallah',
	},
	{
		timezones: ['Pacific/Tahiti', 'Pacific/Marquesas', 'Pacific/Gambier'],
		latlng: [-15, -140],
		name: 'French Polynesia',
		country_code: 'PF',
		capital: 'Papeet\u0113',
	},
	{
		timezones: ['Asia/Qatar'],
		latlng: [25.5, 51.25],
		name: 'Qatar',
		country_code: 'QA',
		capital: 'Doha',
	},
	{
		timezones: ['Indian/Reunion'],
		latlng: [-21.15, 55.5],
		name: 'R\u00e9union',
		country_code: 'RE',
		capital: 'Saint-Denis',
	},
	{
		timezones: ['Europe/Bucharest'],
		latlng: [46, 25],
		name: 'Romania',
		country_code: 'RO',
		capital: 'Bucharest',
	},
	{
		timezones: [
			'Europe/Kaliningrad',
			'Europe/Moscow',
			'Europe/Simferopol',
			'Europe/Volgograd',
			'Europe/Kirov',
			'Europe/Astrakhan',
			'Europe/Samara',
			'Europe/Ulyanovsk',
			'Asia/Yekaterinburg',
			'Asia/Omsk',
			'Asia/Novosibirsk',
			'Asia/Barnaul',
			'Asia/Tomsk',
			'Asia/Novokuznetsk',
			'Asia/Krasnoyarsk',
			'Asia/Irkutsk',
			'Asia/Chita',
			'Asia/Yakutsk',
			'Asia/Khandyga',
			'Asia/Vladivostok',
			'Asia/Ust-Nera',
			'Asia/Magadan',
			'Asia/Sakhalin',
			'Asia/Srednekolymsk',
			'Asia/Kamchatka',
			'Asia/Anadyr',
		],
		latlng: [60, 100],
		name: 'Russia',
		country_code: 'RU',
		capital: 'Moscow',
	},
	{
		timezones: ['Africa/Kigali'],
		latlng: [-2, 30],
		name: 'Rwanda',
		country_code: 'RW',
		capital: 'Kigali',
	},
	{
		timezones: ['Asia/Riyadh'],
		latlng: [25, 45],
		name: 'Saudi Arabia',
		country_code: 'SA',
		capital: 'Riyadh',
	},
	{
		timezones: ['Africa/Khartoum'],
		latlng: [15, 30],
		name: 'Sudan',
		country_code: 'SD',
		capital: 'Khartoum',
	},
	{
		timezones: ['Africa/Dakar'],
		latlng: [14, -14],
		name: 'Senegal',
		country_code: 'SN',
		capital: 'Dakar',
	},
	{
		timezones: ['Asia/Singapore'],
		latlng: [1.36666666, 103.8],
		name: 'Singapore',
		country_code: 'SG',
		capital: 'Singapore',
	},
	{
		timezones: ['Atlantic/South_Georgia'],
		latlng: [-54.5, -37],
		name: 'South Georgia',
		country_code: 'GS',
		capital: 'King Edward Point',
	},
	{
		timezones: ['Arctic/Longyearbyen'],
		latlng: [78, 20],
		name: 'Svalbard and Jan Mayen',
		country_code: 'SJ',
		capital: 'Longyearbyen',
	},
	{
		timezones: ['Pacific/Guadalcanal'],
		latlng: [-8, 159],
		name: 'Solomon Islands',
		country_code: 'SB',
		capital: 'Honiara',
	},
	{
		timezones: ['Africa/Freetown'],
		latlng: [8.5, -11.5],
		name: 'Sierra Leone',
		country_code: 'SL',
		capital: 'Freetown',
	},
	{
		timezones: ['America/El_Salvador'],
		latlng: [13.83333333, -88.91666666],
		name: 'El Salvador',
		country_code: 'SV',
		capital: 'San Salvador',
	},
	{
		timezones: ['Europe/San_Marino'],
		latlng: [43.76666666, 12.41666666],
		name: 'San Marino',
		country_code: 'SM',
		capital: 'City of San Marino',
	},
	{
		timezones: ['Africa/Mogadishu'],
		latlng: [10, 49],
		name: 'Somalia',
		country_code: 'SO',
		capital: 'Mogadishu',
	},
	{
		timezones: ['America/Miquelon'],
		latlng: [46.83333333, -56.33333333],
		name: 'Saint Pierre and Miquelon',
		country_code: 'PM',
		capital: 'Saint-Pierre',
	},
	{
		timezones: ['Europe/Belgrade'],
		latlng: [44, 21],
		name: 'Serbia',
		country_code: 'RS',
		capital: 'Belgrade',
	},
	{
		timezones: ['Africa/Juba'],
		latlng: [7, 30],
		name: 'South Sudan',
		country_code: 'SS',
		capital: 'Juba',
	},
	{
		timezones: ['Africa/Sao_Tome'],
		latlng: [1, 7],
		name: 'S\u00e3o Tom\u00e9 and Pr\u00edncipe',
		country_code: 'ST',
		capital: 'S\u00e3o Tom\u00e9',
	},
	{
		timezones: ['America/Paramaribo'],
		latlng: [4, -56],
		name: 'Suriname',
		country_code: 'SR',
		capital: 'Paramaribo',
	},
	{
		timezones: ['Europe/Bratislava'],
		latlng: [48.66666666, 19.5],
		name: 'Slovakia',
		country_code: 'SK',
		capital: 'Bratislava',
	},
	{
		timezones: ['Europe/Ljubljana'],
		latlng: [46.11666666, 14.81666666],
		name: 'Slovenia',
		country_code: 'SI',
		capital: 'Ljubljana',
	},
	{
		timezones: ['Europe/Stockholm'],
		latlng: [62, 15],
		name: 'Sweden',
		country_code: 'SE',
		capital: 'Stockholm',
	},
	{
		timezones: ['Africa/Mbabane'],
		latlng: [-26.5, 31.5],
		name: 'Swaziland',
		country_code: 'SZ',
		capital: 'Lobamba',
	},
	{
		timezones: ['America/Lower_Princes'],
		latlng: [18.033333, -63.05],
		name: 'Sint Maarten',
		country_code: 'SX',
		capital: 'Philipsburg',
	},
	{
		timezones: ['Indian/Mahe'],
		latlng: [-4.58333333, 55.66666666],
		name: 'Seychelles',
		country_code: 'SC',
		capital: 'Victoria',
	},
	{
		timezones: ['Asia/Damascus'],
		latlng: [35, 38],
		name: 'Syria',
		country_code: 'SY',
		capital: 'Damascus',
	},
	{
		timezones: ['America/Grand_Turk'],
		latlng: [21.75, -71.58333333],
		name: 'Turks and Caicos Islands',
		country_code: 'TC',
		capital: 'Cockburn Town',
	},
	{
		timezones: ['Africa/Ndjamena'],
		latlng: [15, 19],
		name: 'Chad',
		country_code: 'TD',
		capital: "N'Djamena",
	},
	{
		timezones: ['Africa/Lome'],
		latlng: [8, 1.16666666],
		name: 'Togo',
		country_code: 'TG',
		capital: 'Lom\u00e9',
	},
	{
		timezones: ['Asia/Bangkok'],
		latlng: [15, 100],
		name: 'Thailand',
		country_code: 'TH',
		capital: 'Bangkok',
	},
	{
		timezones: ['Asia/Dushanbe'],
		latlng: [39, 71],
		name: 'Tajikistan',
		country_code: 'TJ',
		capital: 'Dushanbe',
	},
	{
		timezones: ['Pacific/Fakaofo'],
		latlng: [-9, -172],
		name: 'Tokelau',
		country_code: 'TK',
		capital: 'Fakaofo',
	},
	{
		timezones: ['Asia/Ashgabat'],
		latlng: [40, 60],
		name: 'Turkmenistan',
		country_code: 'TM',
		capital: 'Ashgabat',
	},
	{
		timezones: ['Asia/Dili'],
		latlng: [-8.83333333, 125.91666666],
		name: 'Timor-Leste',
		country_code: 'TL',
		capital: 'Dili',
	},
	{
		timezones: ['Pacific/Tongatapu'],
		latlng: [-20, -175],
		name: 'Tonga',
		country_code: 'TO',
		capital: "Nuku'alofa",
	},
	{
		timezones: ['America/Port_of_Spain'],
		latlng: [11, -61],
		name: 'Trinidad and Tobago',
		country_code: 'TT',
		capital: 'Port of Spain',
	},
	{
		timezones: ['Africa/Tunis'],
		latlng: [34, 9],
		name: 'Tunisia',
		country_code: 'TN',
		capital: 'Tunis',
	},
	{
		timezones: ['Europe/Istanbul'],
		latlng: [39, 35],
		name: 'Turkey',
		country_code: 'TR',
		capital: 'Ankara',
	},
	{
		timezones: ['Pacific/Funafuti'],
		latlng: [-8, 178],
		name: 'Tuvalu',
		country_code: 'TV',
		capital: 'Funafuti',
	},
	{
		timezones: ['Asia/Taipei'],
		latlng: [23.5, 121],
		name: 'Taiwan',
		country_code: 'TW',
		capital: 'Taipei',
	},
	{
		timezones: ['Africa/Dar_es_Salaam'],
		latlng: [-6, 35],
		name: 'Tanzania',
		country_code: 'TZ',
		capital: 'Dodoma',
	},
	{
		timezones: ['Africa/Kampala'],
		latlng: [1, 32],
		name: 'Uganda',
		country_code: 'UG',
		capital: 'Kampala',
	},
	{
		timezones: ['Europe/Kiev', 'Europe/Uzhgorod', 'Europe/Zaporozhye'],
		latlng: [49, 32],
		name: 'Ukraine',
		country_code: 'UA',
		capital: 'Kiev',
	},
	{
		timezones: ['Pacific/Johnston', 'Pacific/Midway', 'Pacific/Wake'],
		latlng: [19.2911437, 166.618332],
		name: 'United States Minor Outlying Islands',
		country_code: 'UM',
		capital: null,
	},
	{
		timezones: ['America/Montevideo'],
		latlng: [-33, -56],
		name: 'Uruguay',
		country_code: 'UY',
		capital: 'Montevideo',
	},
	{
		timezones: [
			'America/New_York',
			'America/Detroit',
			'America/Kentucky/Louisville',
			'America/Kentucky/Monticello',
			'America/Indiana/Indianapolis',
			'America/Indiana/Vincennes',
			'America/Indiana/Winamac',
			'America/Indiana/Marengo',
			'America/Indiana/Petersburg',
			'America/Indiana/Vevay',
			'America/Chicago',
			'America/Indiana/Tell_City',
			'America/Indiana/Knox',
			'America/Menominee',
			'America/North_Dakota/Center',
			'America/North_Dakota/New_Salem',
			'America/North_Dakota/Beulah',
			'America/Denver',
			'America/Boise',
			'America/Phoenix',
			'America/Los_Angeles',
			'America/Anchorage',
			'America/Juneau',
			'America/Sitka',
			'America/Metlakatla',
			'America/Yakutat',
			'America/Nome',
			'America/Adak',
			'Pacific/Honolulu',
		],
		latlng: [38, -97],
		name: 'United States',
		country_code: 'US',
		capital: 'Washington D.C.',
	},
	{
		timezones: ['Asia/Samarkand', 'Asia/Tashkent'],
		latlng: [41, 64],
		name: 'Uzbekistan',
		country_code: 'UZ',
		capital: 'Tashkent',
	},
	{
		timezones: ['Europe/Vatican'],
		latlng: [41.9, 12.45],
		name: 'Vatican City',
		country_code: 'VA',
		capital: 'Vatican City',
	},
	{
		timezones: ['America/St_Vincent'],
		latlng: [13.25, -61.2],
		name: 'Saint Vincent and the Grenadines',
		country_code: 'VC',
		capital: 'Kingstown',
	},
	{
		timezones: ['America/Caracas'],
		latlng: [8, -66],
		name: 'Venezuela',
		country_code: 'VE',
		capital: 'Caracas',
	},
	{
		timezones: ['America/Tortola'],
		latlng: [18.431383, -64.62305],
		name: 'British Virgin Islands',
		country_code: 'VG',
		capital: 'Road Town',
	},
	{
		timezones: ['America/St_Thomas'],
		latlng: [18.35, -64.933333],
		name: 'United States Virgin Islands',
		country_code: 'VI',
		capital: 'Charlotte Amalie',
	},
	{
		timezones: ['Asia/Ho_Chi_Minh'],
		latlng: [16.16666666, 107.83333333],
		name: 'Vietnam',
		country_code: 'VN',
		capital: 'Hanoi',
	},
	{
		timezones: ['Pacific/Efate'],
		latlng: [-16, 167],
		name: 'Vanuatu',
		country_code: 'VU',
		capital: 'Port Vila',
	},
	{
		timezones: ['Pacific/Wallis'],
		latlng: [-13.3, -176.2],
		name: 'Wallis and Futuna',
		country_code: 'WF',
		capital: 'Mata-Utu',
	},
	{
		timezones: ['Pacific/Apia'],
		latlng: [-13.58333333, -172.33333333],
		name: 'Samoa',
		country_code: 'WS',
		capital: 'Apia',
	},
	{
		timezones: ['Asia/Aden'],
		latlng: [15, 48],
		name: 'Yemen',
		country_code: 'YE',
		capital: "Sana'a",
	},
	{
		timezones: ['Africa/Johannesburg'],
		latlng: [-29, 24],
		name: 'South Africa',
		country_code: 'ZA',
		capital: 'Pretoria',
	},
	{
		timezones: ['Africa/Lusaka'],
		latlng: [-15, 30],
		name: 'Zambia',
		country_code: 'ZM',
		capital: 'Lusaka',
	},
	{
		timezones: ['Africa/Harare'],
		latlng: [-20, 30],
		name: 'Zimbabwe',
		country_code: 'ZW',
		capital: 'Harare',
	},
];

export const classTypeSchema: IReactSelect[] = [
	{ value: 'class', label: 'Group Class' },
	{ value: 'virtual', label: 'Virtual Class' },
	{ value: 'service', label: 'Private Session' },
	{ value: 'event', label: 'Retreat' },
];

export const  isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
!window.MSStream

export const bankCountriesOptions: IReactSelect[] = [
	{ value: 'US', label: 'United States of America' },
];
