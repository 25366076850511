import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ILoginForm } from '../../../containers/Login/interface';
import * as actions from '../../types';
import { logger } from '../../../settings/helpers/Common';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
import { IUserInfo } from '../user/interface';
import * as constants from '../../../settings/helpers/constants';
import * as qs from 'query-string';
import History from '../../../settings/helpers/History';

export const userLogin = (
	payload: ILoginForm
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (
		dispatch: ThunkDispatch<any, any, AnyAction>
	): Promise<void> => {
		dispatch(onLoginRequest());

		let query: any = { ...qs.parse(History.location.search) };

		let roleId = '';

		if (query.roleId || payload.roleId) {
			roleId = query.roleId|| payload.roleId;
		}

		let obj: any = { ...payload, roleId }

		try {
			const result: any = await new ApiHelper().FetchFromServer(
				'/studiomanagers',
				'/login',
				'POST',
				false,
				undefined,
				obj
			);

			if (result.isError) {
				throw new Error(result.messages);
			}

			const { data } = result;
			if (data.statusCode === 200 && data.success) {
				// NO SESSION FOR NOW
				// localStorage.setItem(constants.rememberMe, JSON.stringify(true));
				localStorage.setItem(constants.id, data.token);
				localStorage.setItem(constants.roleId, data.data.roleId);
				
				dispatch(onLoginSuccess('Login successful'));
				dispatch(
					saveUserInfo({
						isAuthenticated: true,
						role: data.data.roleName,
						roleId: data.data.roleId,
						userInfo: { ...data.data, token: data.token },
					})
				);

				// if (payload.rememberMe) {
				// 	localStorage.setItem(constants.rememberMe, JSON.stringify(true));
				// 	localStorage.setItem(constants.id, data.token);
				// } else {
				// 	localStorage.setItem(constants.rememberMe, JSON.stringify(false));
				// 	sessionStorage.setItem(constants.id, data.token);
				// }
			}
		} catch (error) {
			dispatch(onLoginFailure(error));
			logger(error);
		}
	};
};

const onLoginRequest = () => {
	return {
		type: actions.LOGIN_REQUEST,
	};
};

const onLoginSuccess = (message: string) => {
	return {
		type: actions.LOGIN_SUCCESS,
		message,
	};
};

const saveUserInfo = (data: {
	isAuthenticated: boolean;
	role: string;
	roleId: string;
	userInfo: IUserInfo;
}) => {
	return {
		type: actions.SAVE_USER_INFO,
		payload: data,
	};
};

const onLoginFailure = (error: Error) => {
	return {
		type: actions.LOGIN_FAILURE,
		errMessage: error.message,
	};
};
