import React, { FunctionComponent } from 'react';
import { IManualSessionComponentProps } from './interface';
import { Row, Col, Nav } from 'react-bootstrap';
import ParticipantListComponent from './CustomerList';
import customerImg from '../../assets/img/customer1.svg';
import moment from 'moment';
const ManualSessionComponent: FunctionComponent<IManualSessionComponentProps> = (
	props: IManualSessionComponentProps
) => {
	const {
		sessionDetail,
		participantList,
		seconds,
		onSessionClosed,
		isProviderJoin,
	} = props;

	return (
		<React.Fragment>
			<Row className='wrapper-manual'>
				<Col lg={12}>
					<div className='visual-left-section'>
						{sessionDetail ? (
							<div className='visual-header'>
								<div className='visual-header-content d-flex'>
									{/* <ReactAvatar name={sessionDetail.providerName} round size={'80'} /> */}
									<div className='host-img'>
										<img src={sessionDetail.coverThumbUrl} alt='User' />
									</div>
									<div className='user-text ml-4'>
										<h5 className='m-0'>{sessionDetail.serviceName}</h5>
										<h6 className='m-0'>
											{sessionDetail.staffName}, {sessionDetail.providerName}
										</h6>
										<div className='live-wrapper'>
											<div className='live-outline'>
												<span></span>
											</div>{' '}
											{isProviderJoin ? 'Live' : 'Offline'}
										</div>
									</div>
								</div>
							</div>
						) : null}
						<div className='end-session'>
							<h6 className='m-0 video-timer'>{seconds} MINS</h6>
							{/* {isTimerStart ?
									<h6 className="m-0 video-timer">Session in Progress</h6>
									:
									<h6>&nbsp;</h6>
								} */}
							<Nav.Link
								onClick={() => onSessionClosed()}
								className='common-another-btn'
							>
								End Session
							</Nav.Link>
						</div>
					</div>
				</Col>
				<Col lg={9} md={8}>
					{/* <div className={"screen-present-section mt-4" }>
						<div className={'text-center text-white listener-text video-text-center'}>
							{!isProviderJoin ?	<h2>Please wait for the host to start this session</h2>
							:<h2>Session has Been start</h2>}
						</div>
					</div> */}
					<div className='visual-right-section mt-4'>
						<div className='audience-list-head'>
							<h5 className='m-0'>Attendees List</h5>
						</div>
						<div className='attendee-inner'>
							{isProviderJoin ? (
								<table className='table'>
									<thead>
										<tr>
											<th scope='col'>S.No.</th>
											<th scope='col'>Customer Name</th>
											<th scope='col' className='w-log'>
												Login
											</th>
											<th scope='col' className='w-log'>
												Logout
											</th>
										</tr>
									</thead>
									<tbody>
										{participantList && participantList.length && isProviderJoin
											? participantList.map((value: any, index: number) => (
													<tr key={index}>
														<td>{index + 1}</td>
														<td>
															<div className='audience-card'>
																<div className='audience-name d-flex align-items-center'>
																	<div className='audience-icon'>
																		{value.profileImage &&
																		value.profileImage !== 'null' ? (
																			<img
																				src={value.profileThumbUrl}
																				alt='User '
																			/>
																		) : (
																			<img src={customerImg} alt='User ' />
																		)}
																	</div>
																	<h5 className='m-0'>{value.customerName}</h5>
																</div>
															</div>
														</td>
														<td className='w-log'>
															{value.loginTime
																? moment(value.loginTime).format('hh:mm  a')
																: '-'}
														</td>
														<td className='w-log'>
															{value.logoutTime
																? moment(value.logoutTime).format('hh:mm a')
																: '-'}
														</td>
													</tr>
											  ))
											: ''}
									</tbody>
								</table>
							) : (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									Session is not started yet
								</div>
							)}
						</div>
					</div>
				</Col>
				<Col lg={3} md={4} className='attendies-list'>
					<ParticipantListComponent
						participantList={participantList}
						isProviderJoin={isProviderJoin}
					/>
				</Col>
			</Row>
			{/* 
			<div className='visual-footer-wrap'>
				<div className='container'>
					<div className='visual-footer-content d-flex align-items-center justify-content-center'>
						<span className="link-text" style={{ zIndex: 2 }} onClick={() => {
							onSessionClosed();
						}}>
							<div className='action-icon d-flex align-items-center justify-content-center'>
							<Nav.Link onClick={() => onSessionClosed()} className="common-another-btn">Leave Session</Nav.Link>
								<img src={endCallIcon} alt='End Call Icon' width='25' /> 
							</div>
						</span>
					</div>
				</div>
			</div> */}
		</React.Fragment>
	);
};
export default ManualSessionComponent;
