import * as actions from '../../types';
import { IUserStoreState, IUserActionTypes } from './interface';
import * as constants from '../../../settings/helpers/constants';

const initialState: IUserStoreState = {
	loading: false,
	success: false,
	isAuthenticated:
		localStorage.getItem(constants.id) || sessionStorage.getItem(constants.id)
			? true
			: false,
	// role: 'Provider',
	role: '',
	roleId: localStorage.getItem('roleId') || '',
	userInfo: {
		_id: '',
		contact: '',
		email: '',
		// licenseCopy: '',
		profileImage: '',
		profileImageUrl: '',
		name: '',
		roleId: '',
		roleName: '',
		createdAt: '',
		updatedAt: '',
		slug: '',
		studioSlug: '',
		token: '',
		avatar: null,
		setup: undefined,
	},
};

const userReducer = (
	state: IUserStoreState = initialState,
	action: IUserActionTypes
) => {
	switch (action.type) {
		case actions.TOKEN_CHECK_SUCCESS:
			return {
				...state,
				loading: false,
				success: true,
				isAuthenticated: action.payload.isAuthenticated,
				role: action.payload.role,
				roleId: action.payload.roleId,
				userInfo: { ...action.payload.userInfo },
			};

		case actions.TOKEN_CHECK_FAILURE:
			return {
				...state,
				loading: false,
				success: false,
				isAuthenticated: false,
				role: '',
				roleId: '',
				userInfo: {
					_id: '',
					contact: '',
					email: '',
					// licenseCopy: '',
					profileImage: '',
					profileImageUrl: '',
					name: '',
					roleId: '',
					roleName: '',
					createdAt: '',
					updatedAt: '',
					slug: '',
					studioSlug: '',
					token: '',
					avatar: null,
				},
			};

		case actions.SAVE_USER_INFO:
			return {
				...state,
				loading: false,
				success: true,
				isAuthenticated: action.payload.isAuthenticated,
				role: action.payload.role,
				roleId: action.payload.roleId,
				userInfo: { ...action.payload.userInfo },
			};
		
		case actions.SAVE_STRIPE_ACCOUNT_ID:
			return {
				...state,
				userInfo: { ...state.userInfo, accountId: action.payload.accountId },
			}
		
		case actions.SAVE_PROVIDER_SETUP_STATE:
			return {
				...state,
				userInfo: { ...state.userInfo, setup: action.payload },
			};
		
		case actions.GET_PROVIDER_SETUP_FAILURE:
			return {
				...state,
				userInfo: { ...state.userInfo, setup: undefined },
			}

		case actions.USER_LOGOUT:
			return {
				...state,
				loading: false,
				success: false,
				isAuthenticated: false,
				role: '',
				roleId: '',
				userInfo: {
					_id: '',
					contact: '',
					email: '',
					// licenseCopy: '',
					profileImage: '',
					profileImageUrl: '',
					name: '',
					roleId: '',
					roleName: '',
					createdAt: '',
					updatedAt: '',
					slug: '',
					studioSlug: '',
					token: '',
					avatar: null,
				},
			};

		default:
			return state;
	}
};

export default userReducer;
