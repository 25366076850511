import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { ISignupForm } from '../../../containers/Signup/interface';
import * as actions from '../../types';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
import { logger } from '../../../settings/helpers/Common';

export const userSignup = (
	payload: ISignupForm
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (
		dispatch: ThunkDispatch<any, any, AnyAction>
	): Promise<void> => {
		dispatch(onSignupRequest());

		const fd: FormData = new FormData();
		Object.entries(payload).forEach(([key, value]) => {
			switch (key) {
				// case 'businessType':
				// 	fd.append('businessTypeId', value.value);
				// 	break;
				case 'businessTypes':
					fd.append('businessTypes', JSON.stringify(value));
					break;
				case 'country':
					fd.append('country', value.value);
					break;
				case 'state':
					fd.append('state', value.value);
					break;
				default:
					fd.append(key, value);
					break;
			}
		});

		try {
			const result: any = await new ApiHelper().FetchFromServer(
				'/studiomanagers',
				'/signup',
				'POST',
				false,
				undefined,
				fd
			);

			if (result.isError) {
				throw new Error(result.messages);
			}

			const { data } = result;
			if (data.statusCode === 200 && data.success) {
				dispatch(
					onSignupSuccess(((data || {}).data || [])[0].message || data.message)
				);
			}
		} catch (error) {
			dispatch(onSignupFailure(error));
			logger(error);
		}
	};
};

const onSignupRequest = () => {
	return {
		type: actions.SIGNUP_REQUEST,
	};
};

const onSignupSuccess = (message: string) => {
	return {
		type: actions.SIGNUP_SUCCESS,
		message,
	};
};

const onSignupFailure = (error: Error) => {
	return {
		type: actions.SIGNUP_FAILURE,
		errMessage: error.message,
	};
};
