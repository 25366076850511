import React, { FunctionComponent } from 'react';
import { IParticipantListProps } from './interface';
import customerImg from '../../assets/img/customer1.svg';

const ParticipantListComponent: FunctionComponent<IParticipantListProps> = (
	props: IParticipantListProps
) => {
	const { participantList, isProviderJoin } = props;
	return (
		<div className='visual-right-section mt-4'>
			<div className='audience-list-head'>
				<h5 className='m-0'>List of Customers</h5>
			</div>
			<div className='custom-scrollbar'>
				{participantList && isProviderJoin ? (
					participantList.map((user: any, index: number) => (
						// index < 10 ?
						<div
							key={user._id}
							className='audience-card d-flex align-items-center justify-content-between'
						>
							<div className='audience-name d-flex align-items-center'>
								<div className='audience-icon'>
									{user.profileImage && user.profileImage !== 'null' ? (
										<img src={user.profileThumbUrl} alt='User' />
									) : (
										<img src={customerImg} alt='User' />
									)}
								</div>
								&nbsp;&nbsp;
								<h5 className='m-0'>{user.customerName}</h5>
							</div>
							{/* <Badge variant="secondary">{user.isJoin ?"Online":"Offline"}</Badge> */}
						</div>
					))
				) : (
					<div className='audience-card '>
						<p className='yet-session'>Session is not started yet</p>
					</div>
				)}

				<div>
					{/* <div className='audience-card'>
						<div className='audience-name d-flex align-items-center'>
							<div className="audience-icon">
								<img src={UserPhoto} alt='User Photo' />
							</div>
							<h5 className='m-0'>Customer Name</h5>
						</div>
					</div>
					<div className='audience-card'>
						<div className='audience-name d-flex align-items-center'>
							<div className="audience-icon">
								<img src={UserPhoto} alt='User Photo' />
							</div>
							<h5 className='m-0'>Customer Name</h5>
						</div>
					</div>
					<div className='audience-card'>
						<div className='audience-name d-flex align-items-center'>
							<div className="audience-icon">
								<img src={UserPhoto} alt='User Photo' />
							</div>
							<h5 className='m-0'>Customer Name</h5>
						</div>
					</div>
					<div className='audience-card'>
						<div className='audience-name d-flex align-items-center'>
							<div className="audience-icon">
								<img src={UserPhoto} alt='User Photo' />
							</div>
							<h5 className='m-0'>Customer Name</h5>
						</div>
					</div>
					<div className='audience-card'>
						<div className='audience-name d-flex align-items-center'>
							<div className="audience-icon">
								<img src={UserPhoto} alt='User Photo' />
							</div>
							<h5 className='m-0'>Customer Name</h5>
						</div>
					</div>
					<div className='audience-card'>
						<div className='audience-name d-flex align-items-center'>
							<div className="audience-icon">
								<img src={UserPhoto} alt='User Photo' />
							</div>
							<h5 className='m-0'>Customer Name</h5>
						</div>
					</div>
					<div className='audience-card'>
						<div className='audience-name d-flex align-items-center'>
							<div className="audience-icon">
								<img src={UserPhoto} alt='User Photo' />
							</div>
							<h5 className='m-0'>Customer Name</h5>
						</div>
					</div>
					<div className='audience-card'>
						<div className='audience-name d-flex align-items-center'>
							<div className="audience-icon">
								<img src={UserPhoto} alt='User Photo' />
							</div>
							<h5 className='m-0'>Customer Name</h5>
						</div>
					</div>
					<div className='audience-card'> */}
					{/* <div className='audience-name d-flex align-items-center'>
							<div className="audience-icon">
								<img src={UserPhoto} alt='User Photo' />
							</div>
							<h5 className='m-0'>Customer Name</h5>
						</div> */}
				</div>
				{/* <div className='audience-card'>
						<p className="yet-session">Yet session is not start</p>
					</div> */}
			</div>
		</div>
		// </div>
	);
};

export default ParticipantListComponent;
