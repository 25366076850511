import React from 'react';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import socketIOClient from 'socket.io-client';
import { ILiveSessionState } from '../../settings/interfaces';
import moment from 'moment';
import { asyncSetState, getTimeSeconds } from '../../settings/helpers/Common';
import History from '../../settings/helpers/History';
import { ApiHelper } from '../../settings/helpers/ApiHelper';
import {
	Row,
	Col,
	Container,
	Button,
	Form,
	ProgressBar,
} from 'react-bootstrap';
import VideoOff from './../../assets/img/icon_video_off.png';
import VideoOn from './../../assets/img/icon_video_on.png';
import Audio from './../../assets/img/icon_sound.png';
import AudioOff from './../../assets/img/icon_sound_off.png';
import Enlarge from '.././../assets/img/icon_screen_enlarge.png';
import Minimize from '.././../assets/img/minimize.svg';
import Viwers from './../../assets/img/icon_photo.png';
import PageLoader from '../views/PageLoader';
import ParticipantsList from './LiveSessions/ParticipantsList';
import { AppConfig } from '../../settings/configs/AppConfigs';
import Spinner from 'react-bootstrap/Spinner';
import FigureCaption from 'react-bootstrap/FigureCaption';
import { getHoursAndMinut } from '../../settings/helpers/constants';
import { showErrorToast } from '../../settings/helpers/toast';

let partId = '';

class LiveSession extends React.Component<any, ILiveSessionState> {
	socket: any;
	constructor(props: any) {
		super(props);
		this.socket = '';
		this.state = {
			isErrored: false,
			isLoading: true,
			isHostLoading: true,
			isMuted: false,
			isVideo: true,
			seconds: '00:00',
			isTimerStart: false,
			isRecordingStart: false,
			stream: '',
			hasHostVideoAdded: false,
			users: [],
			token: '',
			isScreenShare: false,
			conferenceId: '',
			isHostJoin: false,
			hostDetail: '',
			participantId: '',
			participantData: [],
			isEnlarge: false,
			isSettingPopup: false,
			isParticipantMuted: false,
			isDisconnected: false,
			isSelfCustomerMuted: false,
			isMuteLoading: false,
			mutedBy: '',
			isParticipantJoin: false,
			isSelfParticipantVideo: true,
		};
	}

	componentDidMount = async () => {
		let query = {
			visualSessionId: this.props.conferenceId,
		};
		// To get whether host joiened session or not
		await this.getHostedSession(query);
		this.voxeetSDKEvents();

		await this.initializeVoxeet();

		const { isHost } = this.props;
		this.socket = socketIOClient(AppConfig.API_ENDPOINT);
		console.log(this.socket, 'this.socket');
		this.socket.on(
			`session-hosted-${this.props.conferenceId}`,
			async (sessionData: any) => {
				console.log(
					sessionData,
					'sessionDatasessionDatasessionDatasessionData'
				);
				if (sessionData && sessionData.length > 0) {
					this.setState({ isHostJoin: true });
					asyncSetState(this)({
						isHostJoin: true,
					});
					if (
						!this.props.isHost &&
						!this.state.isParticipantJoin &&
						this.state.isHostJoin
					) {
						await this.getHostedSession(query);
						this.customerConferenceCreate();
					}
				} else {
					// this.setState({
					// 	isLoading: false,
					// });
				}
			}
		);
		console.log('berforesocektttttttttttttttttttt');
		this.socket.on('muteAllParticipant', (id: string) => {
			console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhhh');
			const { participantId, isSelfCustomerMuted, mutedBy } = this.state;
			asyncSetState(this)({
				...this.state,
				isSelfCustomerMuted: !isSelfCustomerMuted,
				isMuteLoading: false,
			});
			console.log(isSelfCustomerMuted, 'insidesocket');

			if ((mutedBy !== 'customer' && id !== participantId) || !isHost) {
				VoxeetSDK.conference.stopAudio(participantId).then((resp: any) => {});
			}
		});
		this.socket.on('unMuteAllParticipant', () => {
			console.log('ummuteeeeeeeehhhhhhhhhhhhhhh');

			const { participantId, isSelfCustomerMuted, mutedBy } = this.state;
			asyncSetState(this)({
				...this.state,
				isSelfCustomerMuted: !isSelfCustomerMuted,
				isMuteLoading: false,
			});
			console.log(isSelfCustomerMuted, 'forunmuteinsidesocket');
			this.setState({ isSelfCustomerMuted: !isSelfCustomerMuted });
			// if(mutedBy !=="customer" && id !==participantId || !isHost){
			VoxeetSDK.conference.startAudio(participantId).then((resp: any) => {});
			// }
		});

		this.socket.on('stopSelfParticipantVideo', (id: any) => {
			const { participantId } = this.state;
			console.log(id, 'stoppppppppid', this.state.participantId);
			let videoNode: any = document.getElementById('video-' + id);
			console.log(videoNode, 'stopSelfParticipantVideovideoNode');
			this.setState({ isSelfParticipantVideo: false });
			if (videoNode && id !== participantId) {
				console.log('insdiestopSelfParticipantVideo');
				let x = document.getElementById(id);
				videoNode.setAttribute('poster', 'remove-video-participent');
				console.log(x, '#################');

				var iDiv = document.createElement('div');
				iDiv.id = `stopVideo-${id}`;
				iDiv.className = 'stop-participant-video';
				console.log(iDiv, 'iDiviDiviDiv');
				if (x) {
					console.log('jjjjjjjjjjjjjjj');
					x.appendChild(iDiv);
					console.log(x, 'afteraddd%%%%%%%%%%%%%%%%%%%%%');
				}
				console.log(x, '%%%%%%%%%%%%%%%%%%%%%');

				// navigator.attachMediaStream(videoNode, null);
			}
		});

		this.socket.on('startSelfParticipantVideo', (id: any) => {
			// this.socket.on('stopSelfParticipantVideo', (id: any) => {
			const { participantId } = this.state;
			console.log(id, 'stoppppppppid', this.state.participantId);
			let videoNode: any = document.getElementById('video-' + id);
			console.log(videoNode, 'stopSelfParticipantVideovideoNode');
			this.setState({ isSelfParticipantVideo: false });
			if (videoNode && id !== participantId) {
				console.log('insdiestopSelfParticipantVideo');
				let x = document.getElementById(id);
				videoNode.setAttribute('poster', 'remove-video-participent');
				console.log(x, '#################');

				var iDiv = document.createElement('div');
				iDiv.id = `stopVideo-${id}`;
				iDiv.className = 'stop-participant-video';
				console.log(iDiv, 'iDiviDiviDiv');
				console.log(id, 'startpppppppdata');
				const { participantId } = this.state;
				var myobj = document.getElementById(`stopVideo-${id}`);
				console.log(id, 'insiddddddddddddddddddd');
				if (myobj) {
					console.log('removeeeeeeinside');
					myobj.remove();
				}
			}
			// // if(id){
			// VoxeetSDK.conference.on(
			// 	'streamUpdated',
			// 	(participant: any, stream: any) => {
			// 		console.log(stream, 'streamUpdated', participant);
			// 		let videoNode: any = document.getElementById(
			// 			'video-' + participant.id
			// 		);
			// 		console.log(videoNode, 'startSelfParticipantVideo');

			// 		videoNode.removeAttribute('class', 'remove-video-participent');
			// 		console.log(id, 'idididididid', participantId);
			// 		if (videoNode && id !== participantId) {
			// 			console.log('insideconditionnnnn');
			// 			navigator.attachMediaStream(videoNode, stream);
			// 		}
			// 	}
			// );
			// // }
		});
		this.socket.on('providerLeaveVirtualSession', (id: any) => {
			const { sessionDetails } = this.props;
			console.log(id, 'iddddddddddddddddddddd', sessionDetails);
			if (sessionDetails.slotId === id) {
				this.onSessionClosed();
			}
		});

		this.socket.on(`participents-${this.props.conferenceId}`, (data: any) => {
			if (data.length > 0) {
				let isMutedAll = true;
				for (let i = 0; i < data.length; i++) {
					let p = data[i];
					if (!p.isMuted) {
						isMutedAll = false;
						break;
					}
				}
				this.setState({
					users: data,
					// isLoading: false,
					// isMutedAll: isMutedAll
				});
			} else {
				this.setState({
					users: [],
					// isLoading: false,
				});
			}
		});
	};
	initializeVoxeet = async () => {
		const { userId, hostId, isHost } = this.props;
		const { isHostJoin, conferenceId } = this.state;
		console.log(isHost, 'isHostisHostisHost');

		console.log(isHost, 'initializeVoxeet');
		if (isHost) {
			await VoxeetSDK.initialize(
				process.env.REACT_APP_CUSTOMER_KEY,
				process.env.REACT_APP_CUSTOMER_SECRET
			);
			await VoxeetSDK.session.open({
				name: this.props.conferenceId,
				userType: userId === hostId ? 'host' : 'user',
				userId: userId,
			});
			console.log('insidehostcondition', isHost);
			VoxeetSDK.conference
				.create({
					alias: this.props.conferenceId,
					params: { liveRecording: true, mode: 'push' },
				})
				.then((conference: any) => VoxeetSDK.conference.join(conference, {}))
				.then(async (conference: any) => {
					console.log(VoxeetSDK.conference, 'VoxeetSDK.conference');

					VoxeetSDK.conference
						.startVideo(VoxeetSDK.session.participant)
						.then(async () => {
							asyncSetState(this)({
								...this.state,
								isErrored: false,
								// isLoading: false,
								isHostLoading:false,
								isDisconnected: false,
								isTimerStart: true,
							});
							await this.hostJoin(VoxeetSDK.session.participant.id);
							// else {
							// 	console.log(this.props, 'this.propsssssss');
							// 	let query = {
							// 		visualSessionId: this.props.conferenceId,
							// 	};
							// 	this.getHostedSession(query);
							// }
						})
						.catch((e: any) => {
							// showErrorToast(e);
							// this.setState({ isHostLoading: false });
							console.log('Something wrong happened : ' + e);
						});
				})
				.catch((e: any) => {
					console.log('Something wrong happened : ' + e);
				});
		} else {
			console.log('elsecondition');
			//confercence create after join the session by host

			await VoxeetSDK.initialize(
				process.env.REACT_APP_CUSTOMER_KEY,
				process.env.REACT_APP_CUSTOMER_SECRET
			);
			await VoxeetSDK.session.open({
				name: this.props.conferenceId,
				userType: userId === hostId ? 'host' : 'user',
			});
			if (isHostJoin) {
				console.log('customerConferenceCreate');
				 this.customerConferenceCreate();
				// VoxeetSDK.conference
				// 	.create({
				// 		alias: this.props.conferenceId,
				// 		params: { liveRecording: true, mode: 'push' },
				// 	})
				// 	.then((conference: any) => VoxeetSDK.conference.join(conference, {}))
				// 	.then((conference: any) => {
				// 		partId = conference._id;
				// 		asyncSetState(this)({
				// 			...this.state,
				// 			participantId: conference._id,
				// 		});
				// 		console.log(this.state.participantId, 'participantIdparticipantId');

				// 		VoxeetSDK.conference
				// 			.startVideo(VoxeetSDK.session.participant)
				// 			.then(async () => {
				// 				asyncSetState(this)({
				// 					...this.state,
				// 					isErrored: false,
				// 					isLoading: false,
				// 					isDisconnected: false,
				// 					isTimerStart: true,
				// 				});
				// 				await this.ParticipantJoin();
				// 				// else {
				// 				// 	console.log(this.props, 'this.propsssssss');
				// 				// 	let query = {
				// 				// 		visualSessionId: this.props.conferenceId,
				// 				// 	};
				// 				// 	this.getHostedSession(query);
				// 				// }
				// 			});
				// 	})
				// 	.catch((e: any) => {
				// 		console.log('Something wrong happened : ' + e);
				// 	});
			} else {
				this.setState({
					isLoading: false,
				});
			}
		}
	};

	customerConferenceCreate = () => {
		const { isHost } = this.props;
		VoxeetSDK.conference
			.create({
				alias: this.props.conferenceId,
				params: { liveRecording: true, mode: 'push' },
			})
			.then((conference: any) => VoxeetSDK.conference.join(conference, {}))
			.then((conference: any) => {
				partId = conference._id;

				// asyncSetState(this)({
				// 	participantId: conference._id,
				// });
				console.log(this.state.participantId, 'participantIdparticipantId');

				VoxeetSDK.conference
					.startVideo(VoxeetSDK.session.participant)
					.then(async () => {
						asyncSetState(this)({
							...this.state,
							isErrored: false,
							isLoading: false,
							// isHostLoading:false,
							isDisconnected: false,
							isTimerStart: true,
						});
						await this.ParticipantJoin();
						// else {
						// 	console.log(this.props, 'this.propsssssss');
						// 	let query = {
						// 		visualSessionId: this.props.conferenceId,
						// 	};
						// 	this.getHostedSession(query);
						// }
					});
			})
			.catch((e: any) => {
				console.log('Something wrong happened : ' + e);
			});
	};

	voxeetSDKEvents = () => {
		VoxeetSDK.conference.on('streamAdded', (participant: any, stream: any) => {
			if (stream.getVideoTracks().length) {
				console.log('conditionnnnnnnnnnnnnnnn');
				this.addVideoNode(participant, stream);
			}
		});

		VoxeetSDK.conference.on(
			'streamRemoved',
			(participant: any, stream: any) => {
				this.removeVideoNode(participant);
			}
		);
		// When a stream is updated
		VoxeetSDK.conference.on(
			'streamUpdated',
			(participant: any, stream: any) => {
				console.log(stream, 'stream');
				if (stream.getVideoTracks().length) {
					// Only add the video node if there is a video track
					this.addVideoNode(participant, stream);
				}
			}
		);
		VoxeetSDK.conference.on(
			'participantUpdated',
			async (participant: any, stream: any) => {
				const { userId, hostId } = this.props;
				console.log(participant, 'participantUpdated');
				let data: any = this.state.participantData;
				let index: number = 0;
				console.log(data, 'data', participant.id);
				if (data && data.length > 0) {
					if (participant.info.userId !== hostId) {
						index = data.indexOf(participant.id);
						console.log(index, 'indexxxxxxxx');
						if (index <= -1) {
							console.log('conditionnnnnnnnnnnnnnnn');
							data.push(participant.id);
						}
					}
				} else {
					console.log(
						participant.info.userId,
						'participant.info.userId',
						hostId
					);
					if (participant.info.userId !== hostId) {
						console.log('insideCondition');
						data.push(participant.id);
					}
				}
				this.setState({ participantData: data });
				//   if(participant){
				//   data.push(participant.id)
				//   this.setState({participantData:data})
				//   }
				var node = document.getElementById('video-' + participant.id);
				if (stream && stream.getVideoTracks().length > 0) {
					if (!node) {
						this.addVideoNode(participant, stream);
					} else {
						this.updateVideoTag(node, stream);
					}
				}
			}
		);
	};
	leaveConference = () => {
		VoxeetSDK.conference.leave();
	};

	stopAudio = async () => {
		const { isMuted, participantId } = this.state;

		this.setState(
			{
				isMuted: !isMuted,
			},
			() => {
				VoxeetSDK.conference.stopAudio(participantId).then((resp: any) => {
					console.log(resp, '####################');
				});
			}
		);
	};
	startAudio = async () => {
		const { isMuted, participantId } = this.state;

		this.setState(
			{
				isMuted: !isMuted,
			},
			() => {
				VoxeetSDK.conference.startAudio(participantId).then((resp: any) => {
					console.log(resp, '####################');
				});
			}
		);
	};
	selfParticipantStartAudio = async () => {
		const { isSelfCustomerMuted, participantId } = this.state;

		this.setState(
			{
				isSelfCustomerMuted: !isSelfCustomerMuted,
			},
			() => {
				VoxeetSDK.conference.startAudio(participantId).then((resp: any) => {
					console.log(resp, '####################');
				});
			}
		);
	};
	selfParticipantStopAudio = async () => {
		const { isSelfCustomerMuted, participantId } = this.state;

		this.setState(
			{
				isSelfCustomerMuted: !isSelfCustomerMuted,
			},
			() => {
				VoxeetSDK.conference.stopAudio(participantId).then((resp: any) => {
					console.log(resp, '####################');
				});
			}
		);
	};
	updateVideoTag = (node: any, stream: any) => {
		navigator.attachMediaStream(node, stream);
		console.log(node, 'nodenodenodenodenode');
		node.muted = true;
	};

	// this for check host is join the session or not
	getHostedSession = async (query: any) => {
		const res = await new ApiHelper().FetchFromServer(
			'/studiomanagers/virtualsessions',
			'/getHostedSession',
			'GET',
			true,
			query
		);

		if (res.data && res.data.statusCode === 200) {
			// await this.autoStartSession(res.data);
			console.log(res.data.data, 'res.data.data');
			asyncSetState(this)({
				...this.state,
				isHostJoin: true,
				hostDetail: res.data.data,
				// isLoading: false,
			});
			if (!this.props.isHost) {
				console.log('conditionnnnnnnnnngetHostedSession');
				// await this.customerConferenceCreate();
			}
		} else {
			this.setState({
				// isLoading: false,
				isHostJoin: false,
			});
		}
	};
	hostJoin = async (id: string) => {
		console.log('hostttttttttttt');
		const res = await new ApiHelper().FetchFromServer(
			'/studiomanagers/virtualsessions',
			'/host',
			'POST',
			true,
			undefined,
			{
				visualSessionId: this.props.conferenceId,
				voxeetParticipantId: id,
			}
		);
		if (res.data && res.data.statusCode === 200) {
			this.setState({
				// isHostLoading: false,
				isDisconnected: false,
			});
			let query = {
				visualSessionId: this.props.conferenceId,
			};
			await this.getHostedSession(query);
			this.setSessionTime();
		}
	};

	ParticipantJoin = async () => {
		let res = await new ApiHelper().FetchFromServer(
			'/studiomanagers/virtualsessions',
			'/join',
			'POST',
			true,
			undefined,
			{
				visualSessionId: this.props.conferenceId,
			}
		);
		if (res.data && res.data.statusCode) {
			this.setSessionTime();
			// setTimeout(() => this.setState({ isLoading: false }), 2000);
			this.setState({
				isParticipantJoin: true,
			});
		}
	};
	setSessionTime = async () => {
		const { hostDetail } = this.state;
		const { sessionDetails, isHost } = this.props;
		let sec: any = 0;

		console.log('afterminus', sec);
		let seconds: any = 0;
		let currentDate = moment.utc().format();

		const progress: any = document.getElementById('progress');
		console.log(this.props.userProps, 'userPropsssssssssss');
		let getEndDuration = getHoursAndMinut(this.props.sessionDetails.duration);
		let getHour: any = getEndDuration.hour ? getEndDuration.hour : 0;
		let getMinute: any = getEndDuration.min ? getEndDuration.min : 0;
		console.log(getHour, 'getHour', getMinute);
		let hourIntoMinute: any = getHour * 60;
		let totalMinute = parseInt(hourIntoMinute) + parseInt(getMinute);
		console.log(hourIntoMinute, 'hourIntoMinute', totalMinute);
		let totalSecond = (totalMinute * 60) / 100;
		let milisecond = totalSecond * 1000;
		console.log(totalSecond, 'totalSecond', hourIntoMinute, milisecond);

		let progressBarValue = 0;

		setInterval(() => {
			let curremtTime: any = moment()
				.utc()
				.format();
			const isSessionStart = moment(curremtTime).isSame(
				moment(sessionDetails.startTime)
			);
			const isAfterSessionStart = moment(curremtTime).isAfter(
				moment(sessionDetails.startTime)
			);
			const sessionEnd = moment(curremtTime).isAfter(
				moment(sessionDetails.endTime)
			);
			console.log(sessionEnd, 'sessionEnd');
			console.log(
				isSessionStart,
				'isSessionStart',
				sessionDetails.startTime,
				curremtTime
			);
			if (isSessionStart || isAfterSessionStart) {
				if (!sessionEnd) {
					progressBarValue = progressBarValue + 1;
					progress.setAttribute('style', `width :${progressBarValue}%`);
					console.log(progress, 'progressafter');
				}
			}
		}, milisecond);
		setInterval(() => {
			if (!isHost && !this.state.isTimerStart) {
				let curremtTime: any = moment().format('hh:mm:ss a');
				let formatCurrentTime = moment(curremtTime, 'hh:mm:ss a');

				let providerLoginTime: any = moment(hostDetail.joinedAt).format(
					'hh:mm:ss a'
				);
				let startTime = moment(providerLoginTime, 'hh:mm:ss a');
				console.log(providerLoginTime, 'providerLoginTime', startTime);
				let sessionTime = moment(sessionDetails.startTime).format('hh:mm:ss a');
				let sessionStartTime = moment(sessionTime, 'hh:mm:ss a');
				let providerStartTime = moment(providerLoginTime, 'hh:mm:ss a');
				let sessionStartTimes = moment(sessionDetails.startTime).format(
					'hh:mm:ss a'
				);
				let x = moment(providerStartTime, 'hh:mm:ss a');
				let y = moment(sessionStartTimes, 'hh:mm:ss a');

				let diff: any = moment.duration(x.diff(y));
				let minutejjj: any = parseInt(diff.asMinutes()) % 60;
				let secn = minutejjj * 60;
				console.log(secn, 'sssssssssssssssssssssssssssss');
				if (secn <= 0) {
					let duration: any = moment.duration(
						formatCurrentTime.diff(sessionStartTime)
					);
					let minute = parseInt(duration.asMinutes()) % 60;
					sec = minute * 60;
				} else {
					console.log(providerStartTime, 'providerStartTime', sessionStartTime);
					let duration: any = moment.duration(
						formatCurrentTime.diff(providerStartTime)
					);
					let minute = parseInt(duration.asMinutes()) % 60;
					console.log(duration, 'duration', minute, 'minute');
					sec = minute * 60;
				}
			}
			let curremtTime: any = moment()
				.utc()
				.format();
			const isSessionStart = moment(curremtTime).isSame(
				moment(sessionDetails.startTime)
			);
			const isAfterSessionStart = moment(curremtTime).isAfter(
				moment(sessionDetails.startTime)
			);
			console.log(
				isSessionStart,
				'isSessionStart',
				sessionDetails.startTime,
				curremtTime
			);
			console.log(isSessionStart, 'insideintervale');
			const sessionEnd = moment(curremtTime).isAfter(
				moment(sessionDetails.endTime)
			);
			console.log(sessionEnd, 'sessionEnd');
			//this for restriction timer is start when session time is same or after current time
			if ((isSessionStart || isAfterSessionStart) && !sessionEnd) {
				if (this.state.isTimerStart && !sessionEnd) {
					sec = sec + 1;
					let newTime = getTimeSeconds(sec);
					console.log(newTime, seconds, 'newTimenewTime');
					this.setState({ seconds: newTime });
				}
			}
		}, 1000);
	};

	onSessionClosed = async () => {
		const { isHost } = this.props;
		VoxeetSDK.conference.leave();
		this.setState({
			isDisconnected: true,
			isTimerStart: false,
		});
		let apiName = isHost ? 'sessionClosed' : 'leave';
		await new ApiHelper().FetchFromServer(
			'/studiomanagers/virtualsessions/',
			`${apiName}`,
			'POST',
			true,
			undefined,
			{
				visualSessionId: this.props.conferenceId,
			}
		);
		// await axios
		// 		.post('https://api.voxeet.com/v1/auth/token', data, {
		// 			headers: {
		// 				'Content-Type': 'application/json',
		// 				Authorization:
		// 					'Basic' +
		// 					'NTNyNmo3LVJJcUdIUGQ3UGJxaWdsQT09OkNOdGJTQ2puZmsxU0YyaUQ0Mk93SmVCLW9rWEJuUUFoR2ZTY2NHbUlLSE09',
		// 			},
		// 		})
		// 		.then((resp: any) => {
		// 			let voxeetToken = resp.data.access_token;
		// 			console.log(conferenceId,'conferenceIdconferenceId')
		// 			const result1 =  axios
		//             .get(
		//                 `https://api.voxeet.com/v1/monitor/conferences/efd2fd47-df70-4d02-a8c8-1b73d3769151/recordings/mp4`,
		//                 {
		//                     headers: {
		//                         'Content-Type': 'application/json',
		//                         Authorization: `Bearer ${voxeetToken}`,
		//                     },
		//                     responseType: 'blob',
		//                 }
		//             )
		//             .then((resp: any) => {
		//                 console.log(resp);
		//                 let blob = new Blob([resp.data], { type: 'video/mp4' });
		//                 console.log(blob, 'blobbbbbbbbb');
		//                 FileSaver.saveAs(
		//                     blob,
		//                     moment().format('YYYY_MM_DD') + '_recording.mp4'
		//                 );
		//   // await axios
		// 		.post('https://api.voxeet.com/v1/auth/token', data, {
		// 			headers: {
		// 				'Content-Type': 'application/json',
		// 				Authorization:
		// 					'Basic' +
		// 					'NTNyNmo3LVJJcUdIUGQ3UGJxaWdsQT09OkNOdGJTQ2puZmsxU0YyaUQ0Mk93SmVCLW9rWEJuUUFoR2ZTY2NHbUlLSE09',
		// 			},
		// 		})
		// 		.then((resp: any) => {
		// 			let voxeetToken = resp.data.access_token;
		// 			console.log(conferenceId,'conferenceIdconferenceId')
		// 			const result1 =  axios
		//             .get(
		//                 `https://api.voxeet.com/v1/monitor/conferences/efd2fd47-df70-4d02-a8c8-1b73d3769151/recordings/mp4`,
		//                 {
		//                     headers: {
		//   		const result =  axios
		//             .get(
		//                 `https://api.voxeet.com/v1/monitor/conferences/${conferenceId}/recordings/mp4`,
		//                 {
		//                     headers: {
		//                         'Content-Type': 'application/json',
		//                         Authorization: `Bearer ${voxeetToken}`,
		//                     },
		//                     responseType: 'blob',
		//                 }
		//             )
		//             .then((resp: any) => {
		//                 console.log(resp);
		//                 let blob = new Blob([resp.data], { type: 'video/mp4' });
		//                 console.log(blob, 'blobbbbbbbbb');
		//                 FileSaver.saveAs(
		//                     blob,
		//                     moment().format('YYYY_MM_DD') + '_recording.mp4'
		//                 );
		//             })
		// 		.catch((e: any) => console.log(e))
		// 	})
		History.push('/drawer/virtual-booking');
	};

	addVideoNode = (participant: any, stream: any) => {
		const { sessionDetails, isListner, hostId } = this.props;
		const { hostDetail, participantId } = this.state;
		console.log(partId, 'partIdpartIdpartId');

		console.log(hostDetail.userId, '@@@@@@@@@@@@@participant', hostId);
		console.log(
			hostDetail.voxeetParticipantId,
			'participant.idtype',
			participant.id
		);
		console.log(
			(hostDetail ? hostDetail.voxeetParticipantId.length : '',
			'participant.id',
			participant.id.length)
		);
		console.log(
			hostDetail.voxeetParticipantId,
			'participantIdparticipantId',
			participant.id
		);
		console.log(
			hostDetail.userId === hostId,
			'###########',
			hostDetail.voxeetParticipantId === participant.id
		);

		let userType: string =
			participant && participant.info ? participant.info.userType : '';
		console.log(userType, 'userIduserId');
		if (
			userType === 'host' ||
			(hostDetail.userId === hostId &&
				hostDetail.voxeetParticipantId === participant.id)
		) {
			const videoContainer: any = document.getElementById(
				'screen-present-section'
			);

			console.log(
				videoContainer,
				stream,
				'videoContainervideoContainervideoContainer'
			);

			asyncSetState(this)({
				participantId: participant.id,
			});
			console.log(participantId, 'participantIdhostttttt');
			let videoNode: any = document.getElementById('video-' + participant.id);

			console.log(!videoNode, 'videoNode', videoNode);
			if (!videoNode) {
				videoNode = document.createElement('video');

				console.log(videoNode, 'videoNodevideoNodevideoNode');
				videoNode.setAttribute('id', 'video-' + participant.id);
				videoNode.setAttribute('class', 'video-mirror');
				// videoNode.setAttribute('height', '500');
				// videoNode.setAttribute('width', '500');
				videoNode.setAttribute('PlaysInline', 'PlaysInline');

				videoContainer.appendChild(videoNode);
				videoNode.autoplay = 'autoplay';
				videoNode.muted = true;
			}
			navigator.attachMediaStream(videoNode, stream);
		} else {
			console.log(userType, 'elseuserType');
			if (userType === 'user') {
				console.log('elseConditionnnnnnnnnnnnnnn');
				const videoContainer: any = document.getElementById('selfCustomer');

				asyncSetState(this)({
					participantId: participant.id,
				});
				let videoNode: any = document.getElementById('video-' + participant.id);

				console.log(videoNode, 'videoNodevideoNodevideoNode');
				if (!videoNode) {
					videoNode = document.createElement('video');

					videoNode.setAttribute('id', 'video-' + participant.id);
					videoNode.setAttribute('class', 'video-mirror');
					videoContainer.appendChild(videoNode);
					videoNode.autoplay = 'autoplay';
					videoNode.muted = true;
				}
				navigator.attachMediaStream(videoNode, stream);
			} else {
				console.log(participant, 'participantparticipantlistner');
				const videoContainer: any = document.getElementById(
					'screen-present-listner'
				);
				var iDiv = document.createElement('div');
				let videoNode: any = document.getElementById('video-' + participant.id);
				if (!videoNode) {
					videoNode = document.createElement('video');
					console.log(videoNode, 'videoNodevideoNodevideoNode');
					videoNode.setAttribute('id', 'video-' + participant.id);
					// videoNode.setAttribute('height', '100');
					// videoNode.setAttribute('width', '100');
					videoNode.setAttribute('class', 'video-participent video-mirror');
					iDiv.appendChild(videoNode);
					iDiv.id = participant.id;
					videoContainer.appendChild(iDiv);
					videoNode.autoplay = 'autoplay';
					videoNode.muted = true;
				}
				navigator.attachMediaStream(videoNode, stream);
			}
		}
	};

	removeVideoNode = (participant: any) => {
		let videoNode: any = document.getElementById('video-' + participant.id);
		if (videoNode) {
			videoNode.parentNode.removeChild(videoNode);
		}
	};
	getParticipantList = () => {};
	stopVideo = () => {
		const { participantId } = this.state;
		console.log(VoxeetSDK.session.participant, 'participantIdstopVideo');

		// Stop sharing the video with the other participants
		VoxeetSDK.conference
			.stopVideo(VoxeetSDK.session.participant)
			.then(() => {
				this.setState({ isVideo: false });
				// this.socket.emit('stopSelfParticipantVideo', participantId);

				// stopVideoBtn.disabled = true;
				// startVideoBtn.disabled = false;
			})
			.catch((e: any) => console.log(e));
	};
	startVideo = () => {
		const { participantId } = this.state;
		console.log(participantId, 'participantIdvideo');

		// Start sharing the video with the other participants
		VoxeetSDK.conference
			.startVideo(VoxeetSDK.session.participant)
			.then(() => {
				this.setState({ isVideo: true });
				// this.socket.emit('startSelfParticipantVideo', participantId);

				// startVideoBtn.disabled = true;
				// stopVideoBtn.disabled = false;
			})
			.catch((e: any) => console.log(e));
	};
	selfParticipantStartVideo = () => {
		const { participantId } = this.state;
		console.log(participantId, 'participantIdvideo');

		// Start sharing the video with the other participants
		VoxeetSDK.conference
			.startVideo(VoxeetSDK.session.participant)
			.then(() => {
				asyncSetState(this)({
					...this.state,
					isVideo: true,
				});
				this.socket.emit('startSelfParticipantVideo', participantId);
			})
			.catch((e: any) => console.log(e));
	};
	selfParticipantStopVideo = () => {
		const { participantId } = this.state;
		console.log(VoxeetSDK.session.participant, 'participantIdstopVideo');

		// Stop sharing the video with the other participants
		VoxeetSDK.conference
			.stopVideo(VoxeetSDK.session.participant)
			.then(() => {
				this.setState({ isVideo: false, isSelfParticipantVideo: false });
				this.socket.emit('stopSelfParticipantVideo', participantId);

				// stopVideoBtn.disabled = true;
				// startVideoBtn.disabled = false;
			})
			.catch((e: any) => console.log(e));
	};
	startRecording = async () => {
		const { userId, conferenceId } = this.props;
		this.setState({ isRecordingStart: false });

		let res = await new ApiHelper().FetchFromServer(
			'/studiomanagers/virtualsessions',
			'/updateRecordingStatus',
			'POST',
			true,
			undefined,
			{
				userId,
				visualSessionId: conferenceId,
			}
		);

		VoxeetSDK.recording
			.start()
			.then((res: any) => {
				// recordStatus.innerText = "Recording"
				console.log(res, 'ressssssssssss');
				if (res.data) {
					this.setState({ conferenceId: res.data.conferenceId });
					// conferenceId = res.data.conferenceId;
					console.log(res.data, 'resDataaaaa');
				}
			})
			.catch((err: any) => {
				alert('Cannnot record the conference');
				console.log(err, 'errrrrrrrrr');
			});
	};
	stopRecording = async () => {
		const { token, conferenceId } = this.state;
		this.setState({ isRecordingStart: true });
		console.log(token, 'token');
		await VoxeetSDK.recording
			.stop()
			.then((res: any) => {
				console.log(VoxeetSDK, '###########3');
				// startRecordingBtn.disabled = false
				// stopRecordingBtn.disabled = true
				// recordStatus.innerText = ''
			})
			.catch((e: any) => console.log(e));
		// 	let data = {
		// 		grant_type: 'client_credentials',
		// 		expires_in: '100000',
		// 	};
		// 	await axios
		// 		.post('https://api.voxeet.com/v1/auth/token', data, {
		// 			headers: {
		// 				'Content-Type': 'application/json',
		// 				Authorization:
		// 					'Basic' +
		// 					'NTNyNmo3LVJJcUdIUGQ3UGJxaWdsQT09OkNOdGJTQ2puZmsxU0YyaUQ0Mk93SmVCLW9rWEJuUUFoR2ZTY2NHbUlLSE09',
		// 			},
		// 		})
		// 		.then((resp: any) => {
		// 			let voxeetToken = resp.data.access_token;
		// 			console.log(conferenceId,'conferenceIdconferenceId')
		// 			const result1 =  axios
		//         .get(
		//             `https://api.voxeet.com/v1/monitor/conferences/5a664fed-9b4e-433e-8274-d2ebf56efa3a/recordings/mp4`,
		//             {
		//                 headers: {
		//                     'Content-Type': 'application/json',
		//                     Authorization: `Bearer ${voxeetToken}`,
		//                 },
		//                 responseType: 'blob',
		//             }
		//         )
		//         .then((resp: any) => {
		//             console.log(resp);
		//             let blob = new Blob([resp.data], { type: 'video/mp4' });
		//             console.log(blob, 'blobbbbbbbbb');
		//             FileSaver.saveAs(
		//                 blob,
		//                 moment().format('YYYY_MM_DD') + '_recording.mp4'
		//             );
		//         })
		// 			// this.setState({ token: voxeetToken });
		// 			const result =  axios
		//         .get(
		//             `https://api.voxeet.com/v1/monitor/conferences/${conferenceId}/recordings/mp4`,
		//             {
		//                 headers: {
		//                     'Content-Type': 'application/json',
		//                     Authorization: `Bearer ${voxeetToken}`,
		//                 },
		//                 responseType: 'blob',
		//             }
		//         )
		//         .then((resp: any) => {
		//             console.log(resp);
		//             let blob = new Blob([resp.data], { type: 'video/mp4' });
		//             console.log(blob, 'blobbbbbbbbb');
		//             FileSaver.saveAs(
		//                 blob,
		//                 moment().format('YYYY_MM_DD') + '_recording.mp4'
		//             );
		//         })
		//         .catch((e: any) => console.log(e));
		// 			console.log(resp, 'respppppppppppToken', voxeetToken);
		// 		});
		// setTimeout(()=>{
		//     const result =  axios
		//     .get(
		//         `https://api.voxeet.com/v1/monitor/conferences/${conferenceId}/recordings/mp4`,
		//         {
		//             headers: {
		//                 'Content-Type': 'application/json',
		//                 Authorization: `Bearer ${token}`,
		//             },
		//             responseType: 'blob',
		//         }
		//     )
		//     .then((resp: any) => {
		//         console.log(resp);
		//         let blob = new Blob([resp.data], { type: 'video/mp4' });
		//         console.log(blob, 'blobbbbbbbbb');
		//         FileSaver.saveAs(
		//             blob,
		//             moment().format('YYYY_MM_DD') + '_recording.mp4'
		//         );
		//     })
		//     .catch((e: any) => console.log(e));
		// },5000)
	};

	startScreenShare = () => {
		// Start the Screen Sharing with the other participants
		VoxeetSDK.conference
			.startScreenShare()
			.then(() => {
				this.setState({ isScreenShare: true });
			})
			.catch((e: any) => console.log(e));
	};

	stopScreenShare = () => {
		// Stop the Screen Sharing
		this.setState({ isScreenShare: false });
		VoxeetSDK.conference.stopScreenShare().catch((e: any) => console.log(e));
	};
	//Full screen mode
	openFullscreen = () => {
		const { hostDetail } = this.state;
		this.setState({ isEnlarge: true });
		let elem: any = document.getElementById('screen-present-section');
		console.log(hostDetail.voxeetParticipantId, 'this.state.participantId');
		let videoNode: any = document.getElementById(
			'video-' + hostDetail.voxeetParticipantId
		);
		console.log(videoNode, 'openFullscreenModeeee');

		if (videoNode) {
			videoNode.setAttribute('class', 'h-100');
			console.log(videoNode, '################@@@@22');
		}
		console.log(elem.webkitRequestFullscreen, 'elemelemelem');

		if (elem) {
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.webkitRequestFullscreen) {
				/* Safari */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) {
				/* IEsss11 */
				elem.msRequestFullscreen();
			}
		}
	};
	closeFullscreen = () => {
		this.setState({ isEnlarge: false });
		const document1: any = window.document;
		console.log(
			document1.exitFullscreen,
			'document.exitFullscreen',
			document1.webkitExitFullscreen,
			document1.mozCancelFullScreen
		);

		var elem: any = document.getElementById('screen-present-section');

		console.log(
			'closeFullscreen',
			elem.exitFullscreen,
			elem.webkitExitFullscreen
		);
		if (document1.exitFullscreen) {
			document1.exitFullscreen();
		} else if (document1.webkitExitFullscreen) {
			document1.webkitExitFullscreen();
		} else if (document1.mozCancelFullScreen) {
			document1.mozCancelFullScreen();
		} else if (document1.msExitFullscreen) {
			document1.msExitFullscreen();
		}
	};
	openSetting = () => {
		this.setState({ isSettingPopup: !this.state.isSettingPopup });
	};
	muteSettingChange = (e: any) => {
		const { participantId } = this.state;
		const { isHost } = this.props;
		asyncSetState(this)({
			...this.state,
			isParticipantMuted: e.target.checked,
			isMuteLoading: true,
		});
		if (!isHost) {
			this.setState({ mutedBy: 'customer' });
		}
		if (e.target.checked) {
			try {
				this.socket.emit('muteAllParticipant', participantId);
			} catch (error) {
				console.log(error, 'socekterrorrrrrrr');
			}
		} else {
			console.log('elseconditionforumnute');
			try {
				this.socket.emit('unMuteAllParticipant');
			} catch (error) {
				console.log(error, 'socekterrorrrrrrr');
			}
		}
	};
	participantMuteVideo = async () => {
		const { isParticipantMuted } = this.state;
		const participantsList = document.getElementById('screen-present-listner');
		console.log(
			participantsList,
			'participantsListparticipantsListparticipantsList'
		);

		console.log('participantMuteVideo');
		const { participantData } = this.state;
		console.log(
			participantData,
			'participantDataparticipantDataparticipantData'
		);
		asyncSetState(this)({
			...this.state,
			isParticipantMuted: !isParticipantMuted,
		});

		this.setState({ isParticipantMuted: !isParticipantMuted });

		if (participantData && participantData.length) {
			VoxeetSDK.conference
				.stopAudio(participantData[0])
				.then((participant: any) => {
					console.log(participant, 'participantMuteAudiiiiiiii');
				});
			// participantData.map((id: any) => {
			// 	console.log(
			// 		id,
			// 		'idddddddddddddddddddddddddd',
			// 		VoxeetSDK.session.participant
			// 	);
			// 	VoxeetSDK.conference.stopAudio(id).then((participant: any) => {
			// 		console.log(participant, 'participantMuteAudiiiiiiii');
			// 	});
			// 	// VoxeetSDK.conference.mute(VoxeetSDK.session.participant, VoxeetSDK.session.participant.isMuted)
			// 	// VoxeetSDK.conference.muteUser(id,!isParticipantMuted)
			// });
		}
	};
	recordingSettingChange = (e: any) => {
		if (e.target.checked) {
			this.startRecording();
		} else {
			this.stopRecording();
		}
		this.setState({ isRecordingStart: e.target.checked });
	};
	componentWillUnmount() {
		this.onSessionClosed();
	}
	render() {
		const {
			isLoading,
			isDisconnected,
			isMuted,
			isErrored,
			errorMessage,
			seconds,
			isTimerStart,
			isRecordingStart,
			users,
			isVideo,
			isScreenShare,
			isHostJoin,
			participantData,
			isEnlarge,
			isSettingPopup,
			isParticipantMuted,
			isSelfCustomerMuted,
			isMuteLoading,
			isHostLoading,
			hostDetail,
		} = this.state;
		console.log(
			this.state.hostDetail,
			'hostDetail',
			this.state.participantData,
			isLoading,
			'isDisconnected',
			isDisconnected,
			isHostJoin
		);

		console.log(participantData, 'renderrendddddddddddddd');

		console.log(isMuted, 'credentialscredentialscredentials');
		console.log(isMuteLoading, 'isMuteLoading');

		window.onbeforeunload = async () => {
			await this.onSessionClosed();
			return 'Do you want to leave this session!';
		};
		const { sessionDetails, scheduleDate, isHost } = this.props;
		let endDuration:any = getHoursAndMinut(sessionDetails.duration);
		console.log(isHostJoin, isHost, 'isHostJoin', sessionDetails);
		console.log(endDuration, 'isLoadinggggggggggggggggg', users);
		return (
			<>
				<React.Fragment>
					<div className='main-block-wrapper'>
						<div className='virtual-wrapper'>
							<Container>
								{sessionDetails ? (
									<Row className='align-items-end video-host-wrapper'>
										<Col md={8} sm={6}>
											<div className='virtual-header'>
												<h3 className='titleBig'>{sessionDetails.title}</h3>
												<div className='align-items-center total-review-wrapper asf'>
													<p className='common-small-text mb-0'>
														with
														<span className='clr-active nav-link'>
															{sessionDetails.providerName}
														</span>{' '}
													</p>
													{isHostJoin ? (
														<div className='virtual-action-tabs '>
															{isRecordingStart ? (
																<div className='record-btn'>
																	<button className='recording-start'></button>
																	Recording
																</div>
															) : null}
															<Button
																className='common-another-btn  btn-responsive btn btn-primary'
																onClick={this.openSetting}
															>
																Settings
															</Button>
															{isSettingPopup ? (
																<div className='virtual-dropdown'>
																	<div
																		className='close-setting'
																		onClick={this.openSetting}
																	></div>
																	<ul className='virtual-dropdown-menu list-unstyled mb-0'>
																		{isHost ? (
																			<li className='virtual-dropdown-item'>
																				<div className='virtual-dropdown-heading'>
																					Disable “Attendees” Microphone
																				</div>
																				<div
																					className='virtual-dropdown-action postion-relative'
																					key='isParticipantMuted'
																				>
																					{isMuteLoading ? (
																						<div className='mute-loading'>
																							<Spinner animation='border' />
																						</div>
																					) : null}
																					<Form.Check
																						type='switch'
																						// id='disable-microphone'
																						className={'custom-toggle'}
																						id='isParticipantMuted'
																						checked={isParticipantMuted}
																						label=''
																						onChange={this.muteSettingChange}
																					/>
																				</div>
																			</li>
																		) : null}
																		<li className='virtual-dropdown-item'>
																			<div className='virtual-dropdown-heading'>
																				Record Class
																			</div>
																			<div className='virtual-dropdown-action'>
																				<Form.Check
																					type='switch'
																					// id='record-class'
																					className={'custom-toggle'}
																					label=''
																					id='isRecordingStart'
																					checked={isRecordingStart}
																					onChange={this.recordingSettingChange}
																				/>
																			</div>
																		</li>

																		<li className='virtual-dropdown-item'>
																			<div className='virtual-dropdown-heading'>
																				Class Duration
																			</div>
																			<div className='virtual-dropdown-blue-text'>
																				{sessionDetails.duration}
																			</div>
																		</li>
																	</ul>
																</div>
															) : (
																''
															)}
															<Button
																className='common-another-btn  btn-responsive btn btn-primary fill-btn'
																onClick={() => this.onSessionClosed()}
															>
																Stop Class
															</Button>
															{/* {isRecordingStart ? (
															<Button onClick={this.startRecording}>
																StartRecording
															</Button>
														) : (
															<Button onClick={this.stopRecording}>
																StopRecording
															</Button>
														)}
														{!isScreenShare ? (
															<Button onClick={this.startScreenShare}>
																Start Sh
															</Button>
														) : (
															<Button onClick={this.stopScreenShare}>
																Stop Sh
															</Button>
														)} */}
														</div>
													) : null}
												</div>
											</div>
										</Col>
										{/* For desktop */}
										{!isHost ? (
											<Col md={4} sm={6} className='self-part-ds1'>
												<div
													className='host-vid-wrapper text-right'
													id='selfCustomer'
												>
													<ul className='video-control-wrapper'>
														{!isVideo ? (
															<li>
																<img
																	src={VideoOff}
																	onClick={this.selfParticipantStartVideo}
																	alt='Video Off'
																/>
															</li>
														) : (
															<li>
																<img
																	src={VideoOn}
																	onClick={this.selfParticipantStopVideo}
																	alt='Video Off'
																/>
															</li>
														)}
														{isSelfCustomerMuted ? (
															<li>
																{' '}
																<img
																	onClick={this.selfParticipantStartAudio}
																	src={AudioOff}
																	alt='autoOff'
																/>
															</li>
														) : (
															<li>
																<img
																	onClick={this.selfParticipantStopAudio}
																	src={Audio}
																	alt='Audio'
																/>
															</li>
														)}
													</ul>
													{isHostJoin ? (
														<div className='overlay-wrapper'></div>
													) : null}
												</div>
											</Col>
										) : null}
									</Row>
								) : (
									''
								)}
								<Row className={`${isHostJoin ? 'vid-list-wrapper' : 'mt-2'}`}>
									<Col md={9}>
										<div id='host-video'>
											<div
												className={`big-vid-wrapper ${
													isHost
														? moment(
																moment(new Date()).format('YYYY-MM-DD')
														  ).isBefore(
																moment(scheduleDate).format('YYYY-MM-DD')
														  )
															? ''
															: ''
														: !isHostJoin
														? 'visual-overlay1'
														: ''
												}`}
												id={'screen-present-section'}
											>
												{isHost ? (
													isHostLoading ? (
														<>
															<div className={'listner-loader'}>
																<div>
																	<PageLoader />
																	<h4 className='session-text'>
																		Connecting Session...
																	</h4>
																</div>
															</div>
														</>
													) : // : moment(
													// 		moment(new Date()).format('YYYY-MM-DD')
													//   ).isBefore(
													// 		moment(scheduleDate).format('YYYY-MM-DD')
													//   ) ? (
													// 	<div className={'listner-loader'}>
													// 		<h4 className='text-white'>{'errorMessage'}</h4>
													// 	</div>
													// )
													isDisconnected ? (
														<div className={'listner-loader'}>
															<h4 className='text-white'>
																Session Disconnected.
															</h4>
														</div>
													) : isErrored ? (
														<div className={'listner-loader'}>
															<h4 className='text-white'>{errorMessage}</h4>
														</div>
													) : null
												) : isLoading ? (
													<div className='listner-loader'>
														<PageLoader />
														<div
															className={
																'text-center text-white video-text-center'
															}
														>
															<h4 className='session-text'>
																Your Session is about to begin.
															</h4>
														</div>
													</div>
												) : isDisconnected ? (
													<div className={'listner-loader'}>
														<h4 className='text-white'>
															Session Disconnected.
														</h4>
													</div>
												) : !isHostJoin ? (
													<div className={' listner-loader'}>
														<h4 className='text-white '>
															Please wait for the host to start this session
														</h4>
													</div>
												) : (
													''
												)}
												{/* <video autoPlay src={'https://vod-progressive.akamaized.net/exp=1611150674~acl=%2Fvimeo-prod-skyfire-std-us%2F01%2F43%2F16%2F400217204%2F1707718608.mp4~hmac=7f65629d5ff0b357ea95dccc99b4d42e22b625e30a330f44885fd265739d7b65/vimeo-prod-skyfire-std-us/01/43/16/400217204/1707718608.mp4?filename=Nature+-+33905.mp4'}  /> */}
												{isHostJoin ? (
													<div className='live-box-wrapper'>
														<span></span>
														Live
													</div>
												) : (
													''
												)}
												<ul className='video-control-wrapper'>
													{isHost && isHostJoin ? (
														<>
															<li>
																{!isVideo ? (
																	<img
																		src={VideoOff}
																		onClick={this.startVideo}
																		alt='Video Off'
																	/>
																) : (
																	<img
																		src={VideoOn}
																		onClick={this.stopVideo}
																		alt='Video Off'
																	/>
																)}
															</li>
															<li>
																{isMuted ? (
																	<img
																		src={AudioOff}
																		onClick={this.startAudio}
																		alt='autoOff'
																	/>
																) : (
																	<img
																		onClick={this.stopAudio}
																		src={Audio}
																		alt='Audio'
																	/>
																)}
															</li>
														</>
													) : (
														''
													)}
													{isHostJoin ?
													!isEnlarge ? (
														<li>
															<img
																src={Enlarge}
																onClick={this.openFullscreen}
																alt='Enlarge'
															/>
														</li>
													) : (
														<li>
															<img
																src={Minimize}
																onClick={this.closeFullscreen}
																alt='Minimize'
															/>
														</li>
													)
													:null}
												</ul>
												{isHostJoin ? (
													<div className='overlay-wrapper'></div>
												) : (
													''
												)}
											</div>
											{isHostJoin ? (
												<Row className='align-items-center pb-56'>
													<Col sm={6}>
														<div className='viewers-wrapper'>
															<ul>
																{users && users.length
																	? users.map((value: any, index: any) => {
																			return value.profileThumbUrl !== null &&
																				value.profileThumbUrl !== 'undefined' &&
																				value.profileThumbUrl !== '' ? (
																				<li>
																					<img
																						src={`https://dev.helloatma.com${value.profileThumbUrl}`}
																						alt='User Photo'
																					/>
																				</li>
																			) : (
																				<li>
																					<img src={Viwers} alt='User Photo' />
																				</li>
																			);
																	  })
																	: null}
															</ul>
															<span>{`${
																users && users.length ? users.length : 0
															}
                                                Viewers Now`}</span>
														</div>
													</Col>
													<Col sm={6}>
														<div className='scheduled-time'>
															<ul>
																<li>{seconds}</li>
																<li>
																	<div className='bar-wrapper'>
																		<div
																			className='bar-inner'
																			id='progress'
																		></div>
																	</div>
																</li>
																<li className='text-right'>
																	{endDuration
																		? `${endDuration.hour <10 ?`0${endDuration.hour}`:endDuration.hour}:${endDuration.min <10 ?`0${endDuration.min}`:endDuration.min}`
																		: ''}
																</li>
															</ul>
														</div>
													</Col>
												</Row>
											) : null}
										</div>
									</Col>
									<Col md={3}>
										<ParticipantsList
											conferenceId={this.props.conferenceId}
											scheduleDate={scheduleDate}
											getParticipantList={this.getParticipantList}
											participantData={participantData}
											isParticipantMuted={isParticipantMuted}
										/>
									</Col>
								</Row>
							</Container>
						</div>
					</div>
					{/* </div> */}
				</React.Fragment>
			</>
		);
	}
}
export default LiveSession;
