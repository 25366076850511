import React from 'react';
// import ReactAvatar from 'react-avatar';
import {
	IParticipantsListProps,
	IParticipantsListState,
} from '../../../settings/interfaces';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import moment from 'moment';
import micIcon from '../../../assets/img/mic.svg';
import micMuteIcon from '../../../assets/img/mic-mute.svg';

import VideoOff from '../../../assets/img/icon_video_off.png';
import VideoOn from '../../../assets/img/icon_video_on.png';

import Audio from '../../../assets/img/icon_sound.png';
import AudioOff from '../../../assets/img/icon_sound_off.png';
import Enlarge from '../../../assets/img/icon_screen_enlarge.png';
// import micGrayIcon from '../../../assets/img/mic-gray.svg';
import socketIOClient from 'socket.io-client';
import { AppConfig } from '../../../settings/configs/AppConfigs';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import { asyncSetState } from '../../../settings/helpers/Common';
let id: any = [];

class ParticipantsList extends React.Component<
	IParticipantsListProps,
	IParticipantsListState
> {
	private timer: any;
	constructor(props: IParticipantsListProps) {
		super(props);
		this.state = {
			isLoading: false,
			users: [],
			isMutedAll: false,
			participantIdsArray: [],
			stream: '',
			isVideo: false,
			isMuted: false,
		};
		this.timer = null;
	}
	stopVideo = (id: any) => {
		// Stop sharing the video with the other participants
		console.log(
			VoxeetSDK.session.participant.id,
			'VoxeetSDK.session.participant.id'
		);

		VoxeetSDK.conference
			.stopVideo(id)
			.then(() => {
				this.setState({ isVideo: false });
				// stopVideoBtn.disabled = true;
				// startVideoBtn.disabled = false;
			})
			.catch((e: any) => console.log(e));
	};
	startVideo = (id: any) => {
		// Start sharing the video with the other participants
		VoxeetSDK.conference
			.startVideo(id)
			.then(() => {
				this.setState({ isVideo: true });

				// startVideoBtn.disabled = true;
				// stopVideoBtn.disabled = false;
			})
			.catch((e: any) => console.log(e));
	};
	muteVideo = async () => {
		const { isMuted } = this.state;
		const { participantData } = this.props;
		this.setState(
			{
				isMuted: !isMuted,
			},
			() => {
				participantData.map((id: any) =>
					VoxeetSDK.conference.mute(id, !isMuted)
				);
			}
		);
	};
	render() {
		const {
			isLoading,
			users,
			isMutedAll,
			participantIdsArray,
			isVideo,
			isMuted,
		} = this.state;
		console.log(
			this.state.participantIdsArray,
			'participantIdsArrayparticipantIdsArray'
		);
		console.log(this.state.stream, 'renderrrr');
		const { participantData, isParticipantMuted } = this.props;
		return (
			<>
				{/* {participantIdsArray && participantIdsArray.length ? */}
				<ul className='vid-list-inner'>
					<li className='vis-list-sp'>
						<div
							className={'screen-present-wrapper'}
							id={`screen-present-listner`}
						></div>
						{/* {participantData && participantData.length ?
								participantData.map((id: any) => ( */}
						{/* <ul className='video-control-wrapper'>
								{!isVideo ? (
									<li>
										<img
											src={VideoOff}
											onClick={() => this.startVideo(id)}
											alt='Video Off'
										/>
									</li>
								) : (
									<li>
										<img
											src={VideoOn}
											onClick={() => this.stopVideo(id)}
											alt='Video Off'
										/>
									</li>
								)}
								{isParticipantMuted ? (
									<li>
										<img
											src={AudioOff}
											alt='autoOff'
											onClick={this.muteVideo}
										/>
									</li>
								) : (
									<li>
										<img src={Audio} alt='Audio' onClick={this.muteVideo} />
									</li>
								)}{' '}
							</ul> */}

						<div className='overlay-wrapper'></div>
					</li>
					{/* <li>
							<div>
								<video autoPlay src={'https://vjs.zencdn.net/v/oceans.mp4'} />
							</div>
						</li>
						<li>
							<div>
								<video autoPlay src={'https://vjs.zencdn.net/v/oceans.mp4'} />
							</div>
						</li>
						<li>
							<div>
								<video autoPlay src={'https://vjs.zencdn.net/v/oceans.mp4'} />
							</div>
						</li> */}
				</ul>
			</>
		);
	}
}

export default ParticipantsList;
