// AUTH ACTION TYPES
export const TOKEN_CHECK_SUCCESS = 'TOKEN_CHECK_SUCCESS';
export const TOKEN_CHECK_FAILURE = 'TOKEN_CHECK_FAILURE';

export const SAVE_USER_INFO = 'SAVE_USER_INFO';
export const SAVE_PROVIDER_SETUP_STATE = 'SAVE_PROVIDER_SETUP_STATE';
export const GET_PROVIDER_SETUP_FAILURE = 'GET_PROVIDER_SETUP_FAILURE';
export const SAVE_STRIPE_ACCOUNT_ID = 'SAVE_STRIPE_ACCOUNT_ID';
export const USER_LOGOUT = 'USER_LOGOUT';

// LOGIN ACTION TYPES
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// SIGNUP ACTION TYPES
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

// STAFF ACTION TYPES
export const STAFF_REQUEST = 'STAFF_REQUEST';
export const STAFF_SUCCESS = 'STAFF_SUCCESS';
export const STAFF_FAILURE = 'STAFF_FAILURE';

// SERVICE ACTION TYPES
export const SERVICE_REQUEST = 'SERVICE_REQUEST';
export const SERVICE_SUCCESS = 'SERVICE_SUCCESS';
export const SERVICE_FAILURE = 'SERVICE_FAILURE';

// STAFF ACTION TYPES
export const STUDIO_REQUEST = 'STUDIO_REQUEST';
export const STUDIO_SUCCESS = 'STUDIO_SUCCESS';
export const STUDIO_FAILURE = 'STUDIO_FAILURE';
